export class Routes {
    public static readonly DASHBOARD = '/dashboard';
    public static readonly CALLBACK_PATH = '/login/callback';
    public static readonly LOGOUT_PATH = '/logout';
    public static readonly UNIVERSAL_LOGOUT_PATH = '/universal-logout';
    public static readonly UNIVERSAL_LOGIN_PATH = '/universal-login';
    public static readonly ACCESS_DENIED = '/access-denied';

    public static readonly PORTAL_LOGIN = '/login/amobee/marketplace';
    public static readonly PORTAL_LOGOUT = '/logout/amobee/marketplace';
}
