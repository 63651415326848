/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    BannerMessage,
    BannerMessageVariant,
    Button,
    ButtonVariant,
    Flyout,
    FormGroup,
    Input,
    InputVariant,
    Popover,
    Select,
    Option,
    Icon,
    ISelectedDateRange,
    DateRangePickerButton,
    IOptionProps,
    Spinner,
} from '@amobee/component-library';
import React, { useContext, useEffect, useState } from 'react';
import { IProduct } from '../../interfaces/IProduct';
import './ProductRequestFlyout.scss';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACCOUNT, GET_CURRENCIES, GET_MARKET, SEND_PRODUCTS_REQUEST_MUTATION } from '../../graphQL/queries';
import { DateTime } from 'luxon';
import { IAccountQuery } from '../../interfaces/queries/IAccountQuery';
import { IEnumType } from '../../interfaces/IEnumType';
import { ICurrenciesQuery } from '../../interfaces/queries/ICurrenciesQuery';
import { Label } from '../../constants/LabelEnum';
import { ICurrencies } from '../../interfaces/ICurrencies';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { RtlDealTypeEnum } from '../../constants/ProductFilterEnums';

const ProductRequestFlyout: React.FunctionComponent<{
    open?: boolean;
    hidebackButton?: boolean;
    onClose?(): void;
    product: IProduct;
    // eslint-disable-next-line
    onProductRequest?(mutation: Promise<any>): void;
    onGoBackClick?(): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);
    const marketLoader = useQuery(GET_MARKET, {
        variables: {
            marketId: marketplaceContext.marketId,
        },
    });
    const accountResponse = useQuery<IAccountQuery>(GET_ACCOUNT);
    const [sendProductRequest] = useMutation(SEND_PRODUCTS_REQUEST_MUTATION, {
        onCompleted() {
            setIsRequestingProduct(false);
        },
        onError: (error) => {
            setIsRequestingProduct(false);
            onError(error?.message);
        },
    });

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [budget, setBudget] = useState<string>('');
    const [specifications, setSpecifications] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [agency, setAgency] = useState('');
    const [errorText, setErrorText] = useState('');
    const [selectedValue, setSelectedValue] = useState<any>();
    const [selectedDealTypes, setSelectedDealTypes] = useState<IEnumType>();
    const currenciesResponse = useQuery<ICurrenciesQuery>(GET_CURRENCIES);
    const [currencies, setCurrencies] = useState<any>();
    const [selectedCurrency, setselectedCurrency] = useState<any>();
    const [convertedCurrency, setConvertedCurrency] = useState<number>();
    const [defaultCurrency, setDefaultCurrency] = useState<any>();
    const [productCurrency, setProductCurrency] = useState<any>();
    const regExpForBudget = new RegExp(/^\d*\.?\d{0,3}$/);
    const [isRequestingProduct, setIsRequestingProduct] = useState(false);

    useEffect(() => {
        let currenciesObj = currenciesResponse.data?.currencies;
        currenciesObj = currenciesObj?.map((item) => ({
            ...item,
            label: item.code + ' (' + item.symbol + ')',
        }));

        if (currenciesObj) {
            handleDefaultCurrency(currenciesObj);
            setCurrencies(currenciesObj);
            handleProductCurrency(currenciesObj);
        }
    }, [currenciesResponse.data, marketLoader.data]);

    const handleProductCurrency = (currenciesObj: ICurrencies[]) => {
        const productCurrencycode = props.product.currency.code;
        if (productCurrencycode) {
            currenciesObj.forEach((item) => {
                if (item.code == productCurrencycode) {
                    const obj = {
                        label: item.label,
                        value: item.exchangeRateUSD,
                    };
                    setProductCurrency(obj);
                }
            });
        }
    };

    const handleDefaultCurrency = (currenciesObj: ICurrencies[]) => {
        const marketCurrencyCode = marketLoader.data?.market.currencyCode;
        if (marketCurrencyCode) {
            currenciesObj.forEach((item) => {
                if (item.code === marketCurrencyCode) {
                    const obj = {
                        label: item.label,
                        value: item.exchangeRateUSD,
                    };
                    setDefaultCurrency(obj);
                }
            });
        }
    };

    const onError = (error: string) => {
        setErrorText(error);
    };
    const isPGDealType = props.product.rtlDealTypes
        ? props.product.rtlDealTypes.find((x) => x === 'PROGRAMMATIC_GUARANTEED_DEAL')
        : false;

    const sendRequest = () => {
        setIsRequestingProduct(true);
        props.onProductRequest?.(
            sendProductRequest({
                variables: {
                    marketId: marketplaceContext.marketId,
                    productId: props.product.externalId,
                    contact: {
                        email: email,
                        firstName: firstName,
                        lastName: lastName,
                    },
                    dealType: { id: 1, name: selectedDealTypes?.name },
                    name: props.product.name,
                    agency: agency,
                    budget: budget ? parseFloat(budget) : 0.0,
                    additionalSpecifications: specifications,
                    startDate: startDate,
                    endDate: endDate,
                },
            }),
        );
    };

    useEffect(() => {
        setEmail(accountResponse.data?.account.email ?? '');
        setFirstname(accountResponse.data?.account.firstName ?? '');
        setLastname(accountResponse.data?.account.lastName ?? '');
    }, [accountResponse.data]);

    useEffect(() => {
        if (selectedValue) {
            setSelectedDealTypes({ id: selectedValue.value, name: selectedValue.label });
        }
    }, [selectedValue]);

    useEffect(() => {
        if (selectedCurrency || defaultCurrency) {
            if (budget) {
                const selectedCurrencyExchangeRate = selectedCurrency ? selectedCurrency.value : defaultCurrency.value;
                const selectedCurrencyLabel = selectedCurrency ? selectedCurrency.label : defaultCurrency.label;

                if (selectedCurrencyLabel === Label.USD_LABEL) {
                    setConvertedCurrency(parseFloat(budget) / productCurrency.value);
                } else {
                    const convertToUSD = parseFloat(budget) / productCurrency.value;
                    setConvertedCurrency(convertToUSD * selectedCurrencyExchangeRate);
                }
            } else if (budget === '') {
                setConvertedCurrency(0);
            }
        }
    }, [selectedCurrency, budget]);

    const renderBody = (): JSX.Element => {
        return (
            <div className="flyout">
                <div className="description">
                    A product request will be sent to the seller with information you provide below. You can keep track
                    of the status of your requests in the ‘Deal Orders’ page.
                </div>
                <FormGroup label="Start Date & End Date (Required)">
                    <DateRangePickerButton
                        footerClearButtonText="Clear"
                        onRangeSelected={(range: ISelectedDateRange) => {
                            setStartDate(DateTime.fromSeconds(range.selectedRange.start).toFormat('yyyy-MM-dd'));
                            setEndDate(DateTime.fromSeconds(range.selectedRange.end).toFormat('yyyy-MM-dd'));
                        }}
                        minDate={new Date(props.product.deliveryStartDate)}
                        maxDate={new Date(props.product.deliveryEndDate)}
                        disabledDayMessage="Date range should be between delivery period of the product"
                    />
                </FormGroup>
                <FormGroup
                    label={isPGDealType ? 'Proposed Budget(Required)' : 'Proposed Budget(Optional)'}
                    helpText="Conversion rates fluctuate. The actual amount charged for the inventory is determined at impression time."
                >
                    <div className="marginLeft">
                        <Input
                            placeholder="Enter"
                            variant={InputVariant.UNDERLINED}
                            value={budget}
                            onChange={(e) => {
                                const isValid = regExpForBudget.test(e.target.value);
                                setBudget(isValid ? e.target.value : budget);
                            }}
                            leftElement={props.product.currency.code}
                            leftElementAsString={true}
                            style={
                                props.product.currency.code !== marketLoader.data?.market.currencyCode
                                    ? { width: '50%', display: 'inline-block' }
                                    : { width: '100%', display: 'inline-block' }
                            }
                        />
                        {props.product.currency.code !== marketLoader.data?.market.currencyCode ? (
                            <span>
                                <label>≈</label>
                                <Select
                                    irreversible
                                    onChange={(option: IOptionProps) => {
                                        setselectedCurrency(option);
                                    }}
                                    value={
                                        selectedCurrency
                                            ? {
                                                  label: selectedCurrency.label ?? '',
                                                  value: selectedCurrency.exchangeRateUSD ?? '',
                                              }
                                            : defaultCurrency
                                    }
                                    style={{ display: 'inline-block' }}
                                >
                                    {currencies?.map((x: any) => (
                                        <Option label={x.label} value={x.exchangeRateUSD} key={x.id} />
                                    ))}
                                </Select>
                                <label>{convertedCurrency ? convertedCurrency.toFixed(2) : 0}</label>
                            </span>
                        ) : (
                            ''
                        )}
                    </div>
                </FormGroup>

                <FormGroup label="Proposed Deal Type">
                    <div className="marginLeft">
                        <Select
                            irreversible
                            onChange={(option) => {
                                setSelectedValue(option);
                            }}
                            value={
                                selectedDealTypes
                                    ? {
                                          label: selectedDealTypes?.name,
                                          value: (selectedDealTypes?.id).toString(),
                                      }
                                    : {
                                          label: 'Select deal type',
                                          value: '',
                                      }
                            }
                        >
                            {props.product.rtlDealTypes?.map((x: any) => (
                                <Option
                                    label={RtlDealTypeEnum[x as keyof typeof RtlDealTypeEnum]}
                                    value={x as keyof typeof RtlDealTypeEnum}
                                    key={x as keyof typeof RtlDealTypeEnum}
                                />
                            ))}
                        </Select>
                    </div>
                </FormGroup>
                <FormGroup
                    label="Additional Specifications (optional)"
                    helpText="Include any additional targeting or frequency requirements here"
                >
                    <div className="marginLeft">
                        <Input
                            placeholder="Enter"
                            variant={InputVariant.UNDERLINED}
                            value={specifications}
                            onChange={(e) => setSpecifications(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormGroup label="Market">
                    <div className="marginLeft">
                        {marketLoader.data ? (
                            <Select
                                value={{
                                    label: marketLoader.data.market.name,
                                    value: marketLoader.data.market.id ?? '',
                                }}
                                onChange={() => 0}
                                disabled
                            >
                                <Option
                                    label={marketLoader.data.market.name}
                                    value={marketLoader.data.market.id ?? ''}
                                />
                            </Select>
                        ) : (
                            <></>
                        )}
                    </div>
                </FormGroup>
                <FormGroup label="Contact Email Address">
                    <div className="marginLeft">
                        <Input
                            placeholder="Enter"
                            variant={InputVariant.UNDERLINED}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormGroup label="Firstname">
                    <div className="marginLeft">
                        <Input
                            placeholder="Enter"
                            variant={InputVariant.UNDERLINED}
                            value={firstName}
                            onChange={(e) => setFirstname(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormGroup label="Lastname">
                    <div className="marginLeft">
                        <Input
                            placeholder="Enter"
                            variant={InputVariant.UNDERLINED}
                            value={lastName}
                            onChange={(e) => setLastname(e.target.value)}
                        />
                    </div>
                </FormGroup>
                <FormGroup label="Agency (optional)">
                    <div className="marginLeft">
                        <Input
                            placeholder="Enter"
                            variant={InputVariant.UNDERLINED}
                            value={agency}
                            onChange={(e) => setAgency(e.target.value)}
                        />
                    </div>
                </FormGroup>
            </div>
        );
    };

    const renderFooter = (): JSX.Element => {
        return (
            <>
                <div className="errorText">
                    {errorText ? (
                        <Popover
                            openOn="hover"
                            placement="top"
                            renderTrigger={() => (
                                <BannerMessage variant={BannerMessageVariant.ERROR}>{errorText}</BannerMessage>
                            )}
                        >
                            <div className="popover-body">
                                <div>
                                    <div className="dotred" />
                                </div>
                                <div>{errorText}</div>
                            </div>
                        </Popover>
                    ) : undefined}
                </div>
                <div className="buttonContainer">
                    <Button variant={ButtonVariant.NEUTRAL} onClick={() => props.onClose?.()}>
                        Cancel
                    </Button>
                    <Button variant={ButtonVariant.PRIMARY} onClick={() => sendRequest()}>
                        Send Request
                    </Button>
                </div>
            </>
        );
    };

    return (
        <div className="product-request-flyout">
            <Flyout
                dimmer
                open={props.open ?? false}
                portalRoot="body"
                onClose={props.onClose ? props.onClose : undefined}
            >
                <Flyout.Header>
                    <h1>
                        {!props.hidebackButton ? (
                            <Button variant={ButtonVariant.STATIC_BORDERLESS} onClick={() => props.onGoBackClick?.()}>
                                <Icon name="ArrowLeft"></Icon>
                            </Button>
                        ) : (
                            ''
                        )}
                        Product Request
                    </h1>
                </Flyout.Header>
                <Flyout.Body>{renderBody()}</Flyout.Body>
                <Flyout.Footer>{renderFooter()}</Flyout.Footer>
            </Flyout>
            {isRequestingProduct && (
                <div className="loading light">
                    <Spinner showText size="large" indeterminateLoadingText="Requesting Product" />
                </div>
            )}
        </div>
    );
};

ProductRequestFlyout.propTypes = {
    open: PropTypes.bool,
    hidebackButton: PropTypes.bool,
    onClose: PropTypes.func,
    product: PropTypes.any,
    onProductRequest: PropTypes.func,
    onGoBackClick: PropTypes.func,
};

export default ProductRequestFlyout;
