import { SkeletonLoader, Typeahead } from '@amobee/component-library';
import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_ADVERTISERS } from '../../../graphQL/queries';
import { IAdvertiser } from '../../../interfaces/IAdvertiser';
import { IAdvertisersQuery } from '../../../interfaces/queries/IAdvertisersQuery';
import PropTypes from 'prop-types';
import { IAdvertisePropTypes } from '../../../constants/propTypes/IAdvertiserPropTypes';

const AdvertiserSelect: React.FunctionComponent<{
    marketId: number | string;
    selectedAdvertiser?: IAdvertiser;
    onAdvertiserSelect(advertiser?: IAdvertiser): void;
}> = (props) => {
    const [result, setResult] = useState<Array<IAdvertiser>>([]);
    const [filteredData, setFilteredData] = useState<Array<IAdvertiser>>(result);
    const [item, setItem] = useState<Array<IAdvertiser>>([]);

    const { error, data, loading } = useQuery<IAdvertisersQuery>(GET_ADVERTISERS, {
        variables: {
            marketId: props.marketId,
        },
        onCompleted: () => {
            if (data) {
                setResult(data.advertisers);
                setFilteredData(data.advertisers);
            }
        },
    });

    const queryChange = (event: string) => {
        {
            if (event.length == 0) {
                setFilteredData(result);
            } else {
                const value = result?.filter((x) => x.name.toLowerCase().includes(event.toLowerCase()));
                setFilteredData(value);
                if (value.length > 0) {
                    props.onAdvertiserSelect(data?.advertisers.find((x) => x.id.toString() === value[0].id.toString()));
                } else {
                    props.onAdvertiserSelect(undefined);
                }
            }
        }
    };

    if (error) return <>Error Loading Advertisers</>;
    if (loading)
        return (
            <SkeletonLoader
                animated
                columnHeight={32}
                columnMargin={4}
                style={{ borderRadius: 0, boxShadow: '0 1px 0 0 rgb(0 0 0 / 30%)', marginTop: '8px' }}
            />
        );

    return (
        <Typeahead
            placeholder="Select"
            resultNameAccessor="name"
            uniqueIdAccessor="id"
            onQueryChange={(query: string) => queryChange(query)}
            onSelectionChange={(item: Array<IAdvertiser>) => {
                setItem(item);
                props.selectedAdvertiser ? item : undefined;
            }}
            selected={item}
            noResultsText="No match found"
            results={filteredData}
            onResetClick={() => {
                queryChange('');
            }}
        ></Typeahead>
    );
};

AdvertiserSelect.propTypes = {
    marketId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    selectedAdvertiser: PropTypes.shape(IAdvertisePropTypes),
    onAdvertiserSelect: PropTypes.func.isRequired,
};

export default AdvertiserSelect;
