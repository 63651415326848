import { IEnumAmobeeEnvironment, IEnumTag, IEnumPublishers } from '../../../interfaces/IEnumFormat';
import { IBidRequestVolumeFilter, IPriceRangeFilter } from '../../../interfaces/queries/IFiltersByStore';
import { IFiltersByStoreQuery } from '../../../interfaces/queries/IFiltersByStoreQuery';
import { IAccordionCheckListByStore } from '../interfaces/IAccordionCheckListByStore';

export class MarketplaceFiltersByStore {
    public inventorySource: Array<IAccordionCheckListByStore> = [];
    public amobeeEnvironments: Array<IAccordionCheckListByStore> = [];
    public amobeeCreativeFormats: Array<IAccordionCheckListByStore> = [];
    public amobeeDealTypes: Array<IAccordionCheckListByStore> = [];
    public priceRange: Array<IAccordionCheckListByStore> = [];
    public bidRequestVolume: Array<IAccordionCheckListByStore> = [];
    public tags: Array<IAccordionCheckListByStore> = [];
    public publishers: Array<IAccordionCheckListByStore> = [];
    public iabCategories: Array<IAccordionCheckListByStore> = [];

    constructor(filters: IFiltersByStoreQuery | undefined, dealListView: boolean | undefined) {
        if (!!filters) {
            const { filtersByStore, filtersForDealList, publishersByStore, iabCategoryByStore, tagsByStore } = filters;
            const {
                amobeeDealTypeFilters = [],
                priceRangeFilters = [],
                bidRequestVolumeFilters = [],
                inventorySourceFilters = [],
            } = filtersByStore;

            const amobeeEnvironmentFilter = dealListView
                ? filtersForDealList.amobeeEnvironmentFilters ?? []
                : filtersByStore.amobeeEnvironmentFilters ?? [];
            const amobeeCreativeFormatsFilters = dealListView
                ? filtersForDealList.amobeeCreativeFormatFilters ?? []
                : filtersByStore.amobeeCreativeFormatFilters ?? [];

            this.inventorySource = inventorySourceFilters.map((inventorySource) => ({
                display: inventorySource.label,
            }));

            this.amobeeEnvironments = amobeeEnvironmentFilter.map((amobeeEnvironment: IEnumAmobeeEnvironment) => ({
                display: amobeeEnvironment.label,
                enumFormat: amobeeEnvironment.environment,
            }));

            this.amobeeCreativeFormats = amobeeCreativeFormatsFilters.map((amobeeCreativeFormat) => ({
                display: amobeeCreativeFormat.label,
                enumFormat: amobeeCreativeFormat.creativeFormatEnum,
            }));

            this.amobeeDealTypes = amobeeDealTypeFilters.map((amobeeDealType) => ({
                display: amobeeDealType.label,
                enumFormat: amobeeDealType.amobeeDealType,
            }));

            this.priceRange = priceRangeFilters.map((priceRange) => ({
                display: priceRange.label,
                enumFormat: priceRange.priceRange,
            }));

            this.bidRequestVolume = bidRequestVolumeFilters.map((bidRequest) => ({
                display: bidRequest.label,
                enumFormat: bidRequest.bidRequestVolume,
            }));

            this.tags = tagsByStore.edges?.map((tag: IEnumTag) => ({
                display: tag.node,
                enumFormat: tag.node,
            }));

            this.publishers = publishersByStore.edges?.map((publisher: IEnumPublishers) => ({
                display: publisher.node,
                enumFormat: publisher.node,
            }));

            this.iabCategories = iabCategoryByStore.edges?.map((iabCategory: IEnumTag) => ({
                display: iabCategory.node,
                enumFormat: iabCategory.node,
            }));
        }
    }

    public static updateSelected(
        items: Array<IAccordionCheckListByStore>,
        selectedIds: Array<
            | string
            | number
            | { minPrice: number; maxPrice: number }
            | { minBidRequestVolume: number; maxBidRequestVolume: number }
        >,
    ): void {
        items.forEach((item) => {
            item.selected = selectedIds.indexOf(item.enumFormat ?? item.display) > -1;
        });
    }

    public static updateSelectedPriceRange(
        items: Array<IAccordionCheckListByStore>,
        selectedIds: Array<{ minPrice: number; maxPrice: number }>,
    ): void {
        items.forEach((item) => {
            const selectedId = selectedIds as unknown as Array<IPriceRangeFilter>;
            item.selected = selectedId.findIndex((x) => item.display === x.label) > -1;
        });
    }

    public static updateSelectedBidRequestVolume(
        items: Array<IAccordionCheckListByStore>,
        selectedIds: Array<IBidRequestVolumeFilter>,
    ): void {
        items.forEach((item) => {
            const selectedId = selectedIds as unknown as Array<{
                minBidRequestVolume: number;
                maxBidRequestVolume: number;
                label: string;
            }>;
            item.selected = selectedId.findIndex((x) => item.display === x.label) > -1;
        });
    }
}
