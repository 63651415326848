import { BannerMessage, Dashboard, DashboardToolbar, Tabs, Tab, ITabProps } from '@amobee/component-library';
import React, { useState, useCallback, useContext, useEffect } from 'react';
import './ProductDashboard.scss';
import DashboardItems from '../DashboardItems/DashboardItems';
import debounce from 'lodash/debounce';
import DashboardFilter from '../DashboardFilter/DashboardFilter';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import DashboardStoreToolbar from '../DashboardStoreToolbar/DashboardStoreToolbar';
import { useHistory, useParams } from 'react-router-dom';
import { IBidRequestVolumeFilter, IPriceRangeFilter } from '../../interfaces/queries/IFiltersByStore';
import { ISortType, ProductSortEnum, SortOptionLabelEnum } from '../../constants/ProductSortEnum';
import { Label } from '../../constants/LabelEnum';
import { IStore } from '../../interfaces/IStore';

const ProductDashboard: React.FunctionComponent = () => {
    const [searchState, setSearchState] = useState({ open: false, text: '' });
    const [debouncedSearch, setDebouncedSearch] = useState('');

    const [debouncedSearchDealListName, setDebouncedSearchDealListName] = useState('');

    const debouncedSearchQueryDealList = useCallback(
        debounce((text) => {
            setDebouncedSearchDealListName(text);
        }, 500),
        [],
    );

    const debouncedSearchQuery = useCallback(
        debounce((text) => {
            setDebouncedSearch(text);
        }, 500),
        [],
    );
    const [selectedInventorySource, setInventorySource] = useState<Array<string>>();
    const [updateSelectedInventorySource, setSelectedInventorySource] = useState<Array<string>>();
    const [selectedAmobeeEnvironment, setAmobeeEnvironment] = useState<Array<string>>();
    const [selectedAmobeeDealType, setAmobeeDealType] = useState<Array<string>>();
    const [selectedAmobeeCreativeFormat, setAmobeeCreativeFormat] = useState<Array<string>>();
    const [selectedRtlDealType, setRtlDealType] = useState<Array<string>>();
    const [selectedRtlCreativeType, setRtlCreativeType] = useState<Array<string>>();
    const [selectedPriceRange, setPriceRange] = useState<Array<IPriceRangeFilter>>();
    const [selectedBidRequestVolume, setBidRequestVolume] = useState<Array<IBidRequestVolumeFilter>>();
    const [updateSelectedBidRequestVolume, setSelectedBidRequestVolume] = useState<Array<IBidRequestVolumeFilter>>();
    const [selectedTags, setTags] = useState<Array<string>>([]);
    const [selectedPublishers, setPublishers] = useState<Array<string>>([]);
    const [updateSelectedPublishers, setSelectedPublishers] = useState<Array<string>>([]);
    const [selectedIabCategory, setIabCategory] = useState<Array<string>>([]);
    const [selectedCategory, setCategory] = useState<Array<string>>([]);
    const [updateSelectedCategories, setSelectedCategories] = useState<Array<string>>([]);

    const [endCursor, setEndCursor] = useState<string>();
    const marketplaceContext = useContext(MarketplaceContext);
    const { marketId } = useParams<{ marketId: string | undefined }>();

    const history = useHistory();
    const selectedStore = history.location.state as IStore;

    const [filterOpen, setFilterOpen] = useState(false);
    const [sendAfter, setSendAfter] = useState<boolean>(false);
    const [listView, setListView] = useState<boolean>(false);
    const [tileView, setTileView] = useState<boolean>(true);
    const [featuredView, setFeaturedView] = useState<boolean>(true);
    const [dealView, setDealView] = useState<boolean>(false);
    const [dealListsView, setDealListsView] = useState<boolean>(false);

    const [singleInventorySourceCheck, setSingleInventorySourceCheck] = useState<boolean>();
    const [singlePublisherCheck, setSinglePublisherCheck] = useState<boolean>();
    const [clearSelection, setClearSelection] = useState<string>();
    const [filterDataMap, setMapState] = useState(new Map());
    const [selectionMap, setSelectionMap] = useState(new Map());

    const bidRequestVolumeSortState = {
        value: ProductSortEnum.DESCENDING,
        key: ProductSortEnum.BID_REQUEST_VOLUME,
        label: SortOptionLabelEnum.BID_REQ_VOL_HIGH_TO_LOW,
    };

    const [sortState, setSortState] = useState<ISortType>(bidRequestVolumeSortState);

    const updateSortState = (prevState: ISortType) => {
        const { PRICE, IN_VIEW_RATE } = ProductSortEnum;
        if (prevState.key === PRICE || prevState.key === IN_VIEW_RATE) {
            return bidRequestVolumeSortState;
        }
        return prevState;
    };

    useEffect(() => {
        if (dealListsView) {
            setSortState((prevState) => updateSortState(prevState));
        }
    }, [dealListsView]);

    const singleInventorySource = (data: boolean) => {
        setSingleInventorySourceCheck(data);
    };
    const singlePublisher = (data: boolean) => {
        setSinglePublisherCheck(data);
    };

    const getFilterCount = (): number => {
        return (
            (selectedInventorySource?.length ?? 0) +
            (selectedAmobeeCreativeFormat?.length ?? 0) +
            (selectedAmobeeDealType?.length ?? 0) +
            (selectedAmobeeEnvironment?.length ?? 0) +
            (selectedRtlCreativeType?.length ?? 0) +
            (selectedRtlDealType?.length ?? 0) +
            (selectedPriceRange?.length ?? 0) +
            (selectedBidRequestVolume?.length ?? 0) +
            (selectedTags?.length ?? 0) +
            (selectedIabCategory?.length ?? 0) +
            (selectedPublishers?.length ?? 0) +
            (selectedCategory?.length ?? 0)
        );
    };

    const toggleFilter = (open?: boolean) => {
        setFilterOpen(open ?? !filterOpen);
    };

    const toggleSearch = (open?: boolean) => {
        setSearchText('', open ?? !searchState.open);
    };

    const setSearchText = (text: string, open?: boolean) => {
        setSearchState({ open: open ?? searchState.open, text: text });
        debouncedSearchQuery(text);
        debouncedSearchQueryDealList(text);
    };

    const onSearchBlur = () => {
        if (searchState.text === '') {
            toggleSearch(false);
        }
    };

    const handleImageClick = () => history.push(`/${marketId}/dashboard`);
    const handleTabChange = (event: ITabProps) => {
        const isList = event.id === 'list' ? true : false;
        setListView(isList);

        const isTile = event.id === 'tile' ? true : false;
        setTileView(isTile);

        return;
    };

    const handleDashboardChange = (event: ITabProps) => {
        const isFeatured = event.id === 'featuredTab' ? true : false;
        setFeaturedView(isFeatured);

        const isDeals = event.id === 'dealsTab' ? true : false;
        setDealView(isDeals);

        const isDealLists = event.id === 'dealListsTab' ? true : false;
        setDealListsView(isDealLists);

        return;
    };

    const dashboardItemsProps = {
        featuredView: featuredView,
        dealView: dealView,
        tileView: tileView,
        isList: listView,
        dealListsView: dealListsView,
        sendAfter: sendAfter,
        match: debouncedSearch,
        dealNameMatch: debouncedSearchDealListName,
        sortState: sortState,
        tags: selectedTags,
        publishers: selectedPublishers,
        iabCategories: selectedIabCategory,
        categories: selectedCategory,
        inventorySource: selectedInventorySource,
        getSingleInventorySource: singleInventorySource,
        getSinglePublisher: singlePublisher,
        amobeeEnvironments: selectedAmobeeEnvironment,
        amobeeCreativeFormats: selectedAmobeeCreativeFormat,
        amobeeDealTypes: selectedAmobeeDealType,
        rtlCreativeType: selectedRtlCreativeType,
        rtlDealType: selectedRtlDealType,
        priceRange: selectedPriceRange,
        bidRequestVolume: selectedBidRequestVolume,
        endCursor: endCursor,
        filterCount: getFilterCount(),
        getFilterCount: getFilterCount(),
        setSendAfter: (sendAfter: boolean) => setSendAfter(sendAfter),
        setEndCursor: (endCursor: string) => setEndCursor(endCursor),
        onTagClick: (tag: string) => setTags([tag]),
        onPublishersClick: (publisher: string) => setPublishers([publisher]),
        onIabCategoryClick: (iabCategory: string) => setIabCategory([iabCategory]),
        onCategoryClick: (category: string) => setCategory([category]),
    };

    const dashboardFilterProps = {
        marketId: marketId,
        dealListsView: dealListsView,
        selectedStore: selectedStore ?? {},
        singleInventorySourceCheck: singleInventorySourceCheck,
        singlePublisherCheck: singlePublisherCheck,
        selectedInventorySource: selectedInventorySource ?? [],
        updateSelectedInventorySource: updateSelectedInventorySource ?? [],
        selectedAmobeeEnvironment: selectedAmobeeEnvironment ?? [],
        selectedAmobeeCreativeFormat: selectedAmobeeCreativeFormat ?? [],
        selectedAmobeeDealType: selectedAmobeeDealType ?? [],
        selectedRtlDealType: selectedRtlDealType ?? [],
        selectedRtlCreativeType: selectedRtlCreativeType ?? [],
        selectedPriceRange: selectedPriceRange ?? [],
        selectedBidRequestVolume: selectedBidRequestVolume ?? [],
        updateSelectedBidRequestVolume: updateSelectedBidRequestVolume ?? [],
        selectedTags: selectedTags ?? [],
        selectedPublishers: updateSelectedPublishers ?? [],
        selectedIabCategory: selectedIabCategory ?? [],
        selectedCategory: updateSelectedCategories ?? [],
        clearSelection: clearSelection,
        filterDataMap: filterDataMap,
        selectionMap: selectionMap,
        featuredView: featuredView,
        getFilterCount: getFilterCount(),
        setSelectionMap: (state: Map<string, string>) => setSelectionMap(state),
        setMapState: (state: Map<string, string>) => setMapState(state),
        setClearSelection: (state: string) => setClearSelection(state),
        updateSelectedCategories: (items: Array<string>) => setSelectedCategories(items),
        updateSelectedPublishers: (items: Array<string>) => setSelectedPublishers(items),
        updateSelectedBidVolume: (items: Array<IBidRequestVolumeFilter>) => setSelectedBidRequestVolume(items),
        updateSelectedInventory: (items: Array<string>) => setSelectedInventorySource(items),
        onInventorySourceSelection: (items: Array<string>) => setInventorySource(items),
        onAmobeeEnvironmentSelection: (items: Array<string>) => setAmobeeEnvironment(items),
        onAmobeeCreativeFormatSelection: (items: Array<string>) => setAmobeeCreativeFormat(items),
        onAmobeeDealTypeSelection: (items: Array<string>) => setAmobeeDealType(items),
        onRtlDealTypeSelection: (items: Array<string>) => setRtlDealType(items),
        onRtlCreativeTypeSelection: (items: Array<string>) => setRtlCreativeType(items),
        onPriceRangeSelection: (items: Array<IPriceRangeFilter>) => setPriceRange(items),
        onBidRequestVolumeSelection: (items: Array<IBidRequestVolumeFilter>) => setBidRequestVolume(items),
        onTagSelection: (items: Array<string>) => setTags(items),
        onPublishersSelection: (items: Array<string>) => setPublishers(items),
        onIabCategorySelection: (items: Array<string>) => setIabCategory(items),
        onCategorySelection: (items: Array<string>) => setCategory(items),
    };

    const renderStoreIcon = (): React.ReactElement => {
        {
            if (selectedStore) {
                return (
                    <>
                        {selectedStore?.name !== Label.AMOBEE_PREFERRED ? (
                            <>
                                <div className="sub-toolbar-image-container">
                                    <img
                                        src="/images/amobee_logomark.svg"
                                        onClick={() => handleImageClick}
                                        style={{
                                            marginRight: '15px',
                                            paddingRight: '20px',
                                            borderRight: '1px solid #ccc',
                                        }}
                                    />
                                    <img
                                        src={
                                            selectedStore?.name === Label.AMOBEE_PREFERRED ||
                                            selectedStore?.label === Label.AMOBEE_PREFERRED
                                                ? '/images/' + (selectedStore?.name || selectedStore?.label) + '.svg'
                                                : '/images/' + (selectedStore?.name || selectedStore?.label) + '.png'
                                        }
                                        style={{ marginTop: '0px', height: '80%', width: 'auto' }}
                                    />
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {selectedStore?.name === Label.AMOBEE_PREFERRED ? (
                            <span className="nav-vertical-divider"></span>
                        ) : (
                            <></>
                        )}

                        {selectedStore?.name === Label.AMOBEE_PREFERRED ? (
                            <>
                                <div className="deal-section">
                                    <Tabs onTabChange={(tab) => handleDashboardChange(tab)}>
                                        <Tab active id="featuredTab" title="Featured">
                                            <div className="dashboard-card-container">
                                                <Dashboard renderToolbar={renderToolbar}>
                                                    {featuredView ? <DashboardItems {...dashboardItemsProps} /> : <></>}
                                                </Dashboard>
                                                <div className="dashboard-card-sidebar">
                                                    <DashboardFilter {...dashboardFilterProps} />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab id="dealsTab" title="Deals">
                                            <div className="dashboard-card-container">
                                                <Dashboard renderToolbar={renderToolbar}>
                                                    {dealView && listView ? (
                                                        <DashboardItems {...dashboardItemsProps} />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {dealView && tileView ? (
                                                        <DashboardItems {...dashboardItemsProps} />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Dashboard>
                                                <div className="dashboard-card-sidebar">
                                                    <DashboardFilter {...dashboardFilterProps} />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab id="dealListsTab" title="Deal Lists">
                                            <div className="dashboard-card-container">
                                                <Dashboard renderToolbar={renderToolbar}>
                                                    {dealListsView && listView ? (
                                                        <DashboardItems {...dashboardItemsProps} />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {dealListsView && tileView ? (
                                                        <DashboardItems {...dashboardItemsProps} />
                                                    ) : (
                                                        <></>
                                                    )}
                                                </Dashboard>
                                                <div className="dashboard-card-sidebar">
                                                    <DashboardFilter {...dashboardFilterProps} />
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        {selectedStore?.name === Label.AMOBEE_PREFERRED ? (
                            <div className="list-tile-view">
                                <Tabs onTabChange={(tab) => handleTabChange(tab)}>
                                    <Tab active id="tile" icon="Thumbnail"></Tab>
                                    <Tab id="list" icon="List">
                                        <div className="dashboard-card-container"></div>
                                    </Tab>
                                </Tabs>
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                );
            } else {
                return <></>;
            }
        }
    };

    const renderToolbarRight = (): React.ReactElement => {
        return (
            <DashboardStoreToolbar
                isList={listView}
                tileView={tileView}
                dealListsView={dealListsView}
                searchState={searchState}
                sortState={sortState}
                onSortChange={(sortState) => setSortState(sortState)}
                toggleSearch={(open) => toggleSearch(open)}
                toggleFilter={(open) => toggleFilter(open)}
                setSearchText={(text, open) => setSearchText(text, open)}
                getFilterCount={() => getFilterCount()}
                onSearchBlur={() => onSearchBlur()}
                filterOpen={filterOpen}
                onAmobeeCreativeFormatSelection={(items: Array<string>) => setAmobeeCreativeFormat(items)}
                onRtlCreativeTypeSelection={(items: Array<string>) => setRtlCreativeType(items)}
                onAmobeeEnvironmentSelection={(items: Array<string>) => setAmobeeEnvironment(items)}
                onAmobeeDealTypeSelection={(items: Array<string>) => setAmobeeDealType(items)}
                onRtlDealTypeSelection={(items: Array<string>) => setRtlDealType(items)}
                onPublishersSelection={(items: Array<string>) => setPublishers(items)}
                onCategorySelection={(items: Array<string>) => setCategory(items)}
                onPriceRangeSelection={(items: Array<IPriceRangeFilter>) => setPriceRange(items)}
                onBidRequestVolumeSelection={(items: Array<IBidRequestVolumeFilter>) => setBidRequestVolume(items)}
                onInventorySourceSelection={(items: Array<string>) => setInventorySource(items)}
                setClearSelection={(state: string) => setClearSelection(state)}
                filterDataMap={filterDataMap}
                setMapState={(state) => setInventorySource(state)}
            />
        );
    };

    const renderToolbar = (): React.ReactElement => {
        return (
            <>
                {marketplaceContext.bannerQueue
                    .filter((x) => x.page === 'products')
                    .map((banner, index) => {
                        return (
                            <BannerMessage
                                variant={banner.variant}
                                dismissible={true}
                                key={index}
                                onClose={() => {
                                    marketplaceContext.removeBannerMessage?.(banner.id);
                                }}
                            >
                                {banner.message}
                            </BannerMessage>
                        );
                    })}
            </>
        );
    };

    return (
        <>
            <div className={`dashboard-container ${filterOpen ? 'filter' : ''}`}>
                <DashboardToolbar renderLeft={renderStoreIcon} renderRight={renderToolbarRight} />
            </div>
        </>
    );
};

export default ProductDashboard;
