import React, { useEffect } from 'react';
import {
    Button,
    ButtonVariant,
    Flyout,
    FlyoutWidthSize,
    IMenuItemProps,
    Input,
    ListSelector,
} from '@amobee/component-library';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SAVE_USER_PREFERENCE_MUTATION } from '../../graphQL/queries';
import { IColumns } from '../../interfaces/IColumns';
import { IUserPreferenceData } from '../../interfaces/IUserPreferenceData';
import { ISaveUserPreferenceMutation } from '../../interfaces/mutations/ISaveUserPreferenceMutation';
import { IMenuItemBaseProps } from '@amobee/component-library/dist/components/menus/MenuItem/IMenuItemBaseProps';
import { IPreferenceDataColumns } from '../../interfaces/ICustomColumnConfig';

const CustomColumnConfig: React.FunctionComponent<{
    columns: IColumns[];
    flyoutOpen: boolean;
    isConfigEdit: boolean;
    configName?: string;
    selectedColumns?: IColumns[];
    editConfigName?: string;
    menuItems?: IMenuItemProps[];
    preferenceData: IUserPreferenceData;
    setFlyoutOpen(flyoutOpen: boolean): void;
    setErrorText(errorText: string): void;
    setDisplayColumns(displayColumns: IColumns[]): void;
    setSelectedMenu(value: string | undefined): void;
    setIsLoading(isLoading: boolean): void;
    setConfigName(configName: string): void;
    setSelectedColumns(selectedColumns: IColumns[]): void;
    setMenuItems(menuItems: IMenuItemBaseProps[]): void;
    updateDropdownMenu(preferenceData: string, refetch: boolean): void;
    isList?: boolean;
    dealView?: boolean;
}> = (props) => {
    const menuItems = props.menuItems;
    const setMenuItems = props.setMenuItems;
    const isConfigEdit = props.isConfigEdit;
    const configName = props.configName;
    const setConfigName = props.setConfigName;
    const selectedColumns = props.selectedColumns;
    const setSelectedColumns = props.setSelectedColumns;
    const editConfigName = props.editConfigName;

    const updateDropdownMenu = props.updateDropdownMenu;
    const preferenceData = props.preferenceData;

    useEffect(() => {
        // useeffect for all variables
    }, [configName, selectedColumns, isConfigEdit, editConfigName, menuItems]);

    const renderBody = () => {
        return (
            <div className="flyout">
                <div className="margin-top-10">
                    <label>Name</label>
                    <Input
                        className="margin-top-10"
                        placeholder="Enter column config name"
                        value={configName}
                        onChange={(e) => {
                            setConfigName(e.target.value);
                        }}
                    />
                </div>
                <div className="margin-top-10">
                    <label>Columns</label>
                    <ListSelector
                        className="margin-top-10"
                        optionNameAccessor="label"
                        optionUniqueIdAccessor="id"
                        optionsButtonVariant="borderless"
                        placeholder="Add objects"
                        reorderEnabled
                        resultNameAccessor="Header"
                        results={props.columns}
                        uniqueIdAccessor="id"
                        selected={selectedColumns}
                        onSelectionChange={(params) => onSelectionChange(params as IColumns[])}
                        onResetClick={() => {
                            setSelectedColumns && setSelectedColumns([] as IColumns[]);
                        }}
                    />
                </div>
            </div>
        );
    };

    const onSelectionChange = (selected: IColumns[]) => {
        setSelectedColumns(selected);
    };

    const onError = (error: string) => {
        props.setErrorText(error);
    };

    const [savePreference] = useMutation<ISaveUserPreferenceMutation>(SAVE_USER_PREFERENCE_MUTATION, {
        onError: (error) => {
            props.setIsLoading(false);
            onError(error?.message);
        },
        onCompleted: (retVal) => {
            if (retVal.errors?.length ?? 0 > 0) {
                props.setIsLoading(false);
                onError(retVal.errors?.join('<br/>') ?? '');
            } else {
                props.setFlyoutOpen(false);
                props.setIsLoading(false);
                const menuList = updateDropdownMenu(retVal.saveUserPreference, true);
                setMenuItems(menuList as unknown as IMenuItemBaseProps[]);
            }
        },
    });

    const createConfig = () => {
        const column =
            props.dealView && props.isList
                ? {
                      deals: {
                          name: configName,
                          columnsOrder: selectedColumns && selectedColumns.map((column: IColumns) => column.accessor),
                      },
                  }
                : {
                      dealLists: {
                          name: configName,
                          columnsOrder: selectedColumns && selectedColumns.map((column: IColumns) => column.accessor),
                      },
                  };

        const preference = {
            columns: [column],
        };

        const pData = JSON.parse(preferenceData?.getUserPreference);
        preference.columns = pData ? [column, ...pData?.columns] : [column];

        savePreference({
            variables: {
                preference: JSON.stringify(preference),
            },
        });

        props.dealView && props.isList
            ? props.setSelectedMenu(column.deals?.name)
            : props.setSelectedMenu(column.dealLists?.name);

        props.dealView && props.isList
            ? props.setDisplayColumns(
                  props.columns.filter(
                      (col: IColumns) =>
                          column?.deals?.columnsOrder && column?.deals?.columnsOrder.indexOf(col.accessor) > -1,
                  ),
              )
            : props.setDisplayColumns(
                  props.columns.filter(
                      (col: IColumns) =>
                          column?.dealLists?.columnsOrder && column?.dealLists?.columnsOrder.indexOf(col.accessor) > -1,
                  ),
              );
    };

    const updateConfig = () => {
        const column =
            props.dealView && props.isList
                ? {
                      deals: {
                          name: configName,
                          columnsOrder: selectedColumns && selectedColumns.map((column: IColumns) => column.accessor),
                      },
                  }
                : {
                      dealLists: {
                          name: configName,
                          columnsOrder: selectedColumns && selectedColumns.map((column: IColumns) => column.accessor),
                      },
                  };

        const preference = {
            columns: [column],
        };

        const pData = JSON.parse(preferenceData?.getUserPreference);
        let pColumns = pData?.columns;
        if (pColumns) {
            pColumns = pColumns.filter((col: IPreferenceDataColumns) =>
                props.dealView && props.isList
                    ? col.deals?.name != editConfigName
                    : col.dealLists?.name != editConfigName,
            );
        }
        preference.columns = pData ? [column, ...pColumns] : [column];

        savePreference({
            variables: {
                preference: JSON.stringify(preference),
            },
        });

        props.dealView && props.isList
            ? props.setSelectedMenu(column.deals?.name)
            : props.setSelectedMenu(column.dealLists?.name);

        props.dealView && props.isList
            ? props.setDisplayColumns(
                  props.columns.filter(
                      (col: IColumns) =>
                          column?.deals?.columnsOrder && column?.deals?.columnsOrder.indexOf(col.accessor) > -1,
                  ),
              )
            : props.setDisplayColumns(
                  props.columns.filter(
                      (col: IColumns) =>
                          column?.dealLists?.columnsOrder && column?.dealLists?.columnsOrder.indexOf(col.accessor) > -1,
                  ),
              );
    };

    return (
        <Flyout
            dimmer
            open={props.flyoutOpen ?? false}
            portalRoot="body"
            size={FlyoutWidthSize.small}
            onClose={() => props.setFlyoutOpen(false)}
        >
            <Flyout.Header>
                <h3>Custom Column Set</h3>
            </Flyout.Header>
            <Flyout.Body>{renderBody()}</Flyout.Body>
            <Flyout.Footer>
                <div className="buttonContainer float-right">
                    <Button
                        className="margin-right-10"
                        variant={ButtonVariant.NEUTRAL}
                        onClick={() => props.setFlyoutOpen(false)}
                    >
                        Cancel
                    </Button>
                    {!isConfigEdit ? (
                        <>
                            <Button variant={ButtonVariant.PRIMARY} onClick={() => createConfig()}>
                                Create
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant={ButtonVariant.PRIMARY} onClick={() => updateConfig()}>
                                Update
                            </Button>
                        </>
                    )}
                </div>
            </Flyout.Footer>
        </Flyout>
    );
};

CustomColumnConfig.propTypes = {
    columns: PropTypes.array.isRequired,
    flyoutOpen: PropTypes.bool.isRequired,
    isConfigEdit: PropTypes.bool.isRequired,
    configName: PropTypes.string,
    selectedColumns: PropTypes.array,
    menuItems: PropTypes.array.isRequired,
    preferenceData: PropTypes.shape({ getUserPreference: PropTypes.string.isRequired }).isRequired,
    setFlyoutOpen: PropTypes.func.isRequired,
    setErrorText: PropTypes.func.isRequired,
    setDisplayColumns: PropTypes.func.isRequired,
    setSelectedMenu: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setConfigName: PropTypes.func.isRequired,
    setSelectedColumns: PropTypes.func.isRequired,
    editConfigName: PropTypes.string,
    setMenuItems: PropTypes.func.isRequired,
    updateDropdownMenu: PropTypes.func.isRequired,
    isList: PropTypes.bool,
    dealView: PropTypes.bool,
};

export default CustomColumnConfig;
