import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@amobee/component-library';
import { IGetPublisherDeals } from '../../interfaces/IPublisherDealsResponse';

const DealListsFlyoutDataTable: React.FunctionComponent<{
    publisherDealsData?: IGetPublisherDeals[];
}> = (props) => {
    const publisherDealsData = props.publisherDealsData;

    const [expandedRows, setExpandedRows] = useState<Array<string>>([]);

    const handleExpandRow = (id: string) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(id);

        const newExpandedRows = isRowExpanded
            ? currentExpandedRows.filter((rowId: string) => rowId !== id)
            : currentExpandedRows.concat(id);

        setExpandedRows(newExpandedRows);
    };

    return (
        <>
            <table className="main-table">
                <thead className="main-table-headers">
                    <tr>
                        <th className="name-col">Name</th>
                        <th className="empty-cell-header"></th>
                        <th className="status-col">Status</th>
                        <th className="deal-id-col">Deal ID</th>
                        <th className="floor-cpm-col">Floor CPM</th>
                        <th className="bid-request-volume-col">Bid Request</th>
                    </tr>
                </thead>
                <tbody>
                    {publisherDealsData?.map((item: IGetPublisherDeals) => (
                        <React.Fragment key={item.id}>
                            <tr
                                id="deal-details-main-row"
                                className={expandedRows.includes(item.id) ? 'border-0' : 'border-bottom'}
                            >
                                <td>{item.dealName}</td>
                                <td className="collapse-icon">
                                    <span
                                        onClick={() => {
                                            handleExpandRow(item.id);
                                        }}
                                    >
                                        <Icon
                                            name={expandedRows.includes(item.id) ? 'ChevronUp' : 'ChevronDown'}
                                            size={16}
                                        />
                                    </span>
                                </td>
                                <td>{item.workflowStatus}</td>
                                <td>{item.dealId}</td>
                                <td>{item.price}</td>
                                <td>{item.bidRequestVolume.toLocaleString() ?? 0}</td>
                            </tr>

                            {expandedRows.includes(item.id) && (
                                <tr
                                    id="deal-details-sub-row"
                                    className={expandedRows.includes(item.id) ? 'border-bottom' : 'border-0'}
                                >
                                    <td colSpan={6}>
                                        <table className="sub-table">
                                            <thead>
                                                <tr className="sub-table-headers">
                                                    <th className="ssp-col">SSP</th>
                                                    <th className="publisher-col">Publisher</th>
                                                    <th className="deal-type-col">Deal Type</th>
                                                    <th className="format-col">Format</th>
                                                    <th className="ad-size-col">AdSize</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{item.inventorySourceId}</td>
                                                    <td>{item.publisherName}</td>
                                                    <td>{item.dealType}</td>
                                                    <td>{item.mediaFormat}</td>
                                                    <td>{item.adSize ?? 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </>
    );
};

DealListsFlyoutDataTable.propTypes = {
    publisherDealsData: PropTypes.array,
};

export default DealListsFlyoutDataTable;
