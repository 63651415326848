import { BannerMessageVariant } from '@amobee/component-library';
import React from 'react';
import { IAccount } from '../../interfaces/IAccount';

export type MarketplacePage = 'products' | 'orders';
export interface IBannerQueue {
    id: string;
    message: string | JSX.Element;
    variant: BannerMessageVariant;
    page: MarketplacePage;
}
export interface IMarketplaceContext {
    account: IAccount;
    bannerQueue: Array<IBannerQueue>;
    marketId: number;
    removeBannerMessage?: (id: string) => void;
    addBannerMessage?: (message: string | JSX.Element, variant: BannerMessageVariant, page: MarketplacePage) => void;
    currencyCode?: string;
}

export const DefaultMarketplaceContext: IMarketplaceContext = {
    account: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        resourceType: '',
        authorities: [],
        isReadOnly: true,
    },
    bannerQueue: [],
    marketId: 1,
};

export const MarketplaceContext = React.createContext<IMarketplaceContext>(DefaultMarketplaceContext);
