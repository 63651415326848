import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MarketplaceApolloFramework } from '../components/MarketplaceFramework/MarketplaceFramework';
import { GuardProvider, GuardedRoute, GuardFunction } from 'react-router-guards';
import { PortalLoginCallback } from './PortalLoginCallback';
import { PortalLogout } from './PortalLogout';
import { UniversalLogout } from './UniversalLogout';
import { Routes } from '../constants/Routes';
import { UniversalLogin } from './UniversalLogin';

export const UsePortalLogin: React.FunctionComponent = () => {
    let marketId: string;
    const requireLogin: GuardFunction = (to, from, next) => {
        if (to.meta.auth) {
            if (window.localStorage.getItem('accessToken')) {
                next();
                return;
            }

            const marketIdFromUrl = window.location.pathname.match(/\/([^/]+)\//);

            marketId = marketIdFromUrl ? marketIdFromUrl[1] : '1';
            window.sessionStorage.setItem('marketId', marketId);

            const url = (process.env.REACT_APP_AMOBEE_LOGIN ?? '') + Routes.PORTAL_LOGIN;

            window.location.href =
                url + '?platformRedirectionUrl=' + encodeURI(`${process.env.REACT_APP_URI}${Routes.CALLBACK_PATH}`);
        } else {
            next();
            return;
        }
    };
    return (
        <BrowserRouter>
            <GuardProvider guards={[requireLogin]}>
                <Switch>
                    <Route exact path={Routes.CALLBACK_PATH} component={PortalLoginCallback} />
                    <Route exact path={Routes.LOGOUT_PATH} component={PortalLogout} />
                    <Route exact path={Routes.UNIVERSAL_LOGOUT_PATH} component={UniversalLogout} />
                    <Route exact path={Routes.UNIVERSAL_LOGIN_PATH} component={UniversalLogin} />
                    <GuardedRoute
                        path="/"
                        render={() => <MarketplaceApolloFramework></MarketplaceApolloFramework>}
                        meta={{ auth: true }}
                    />
                </Switch>
            </GuardProvider>
        </BrowserRouter>
    );
};
