import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SkeletonLoader } from '@amobee/component-library';
import AccordionCheckList from '../common/AccordionCheckList/AccordionCheckList';
import { GET_FILTERS } from '../../graphQL/queries';
import { useQuery } from '@apollo/client';
import { IFiltersQuery } from '../../interfaces/queries/IFiltersQuery';
import { MarketplaceFilters } from '../common/MarketplaceFilters/MarketplaceFilters';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';

const OrdersGridFilter: React.FunctionComponent<{
    selectedCreativeFormats: Array<number>;
    selectedCreativeTypes: Array<number>;
    selectedTags: Array<number>;
    selectedEnvironments: Array<number>;
    selectedStatuses: Array<number>;
    selectedSellerIds: Array<number>;

    onCreativeFormatsSelection?(selectedItems: Array<number>): void;
    onEnvironmentsSelection?(selectedItems: Array<number>): void;
    onCreativeTypeSelection?(selectedItems: Array<number>): void;
    onTagSelection?(selectedItems: Array<number>): void;
    onOrderStatusSelection?(selectedItems: Array<number>): void;
    onSellerSelection?(selectedItems: Array<number>): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);

    const { loading, error, data } = useQuery<IFiltersQuery>(GET_FILTERS, {
        variables: {
            marketId: marketplaceContext.marketId,
        },
    });

    const loader = (
        <div style={{ marginTop: 15 }}>
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
        </div>
    );

    if (loading) return loader;
    if (error) return <>{`Error! ${error.message}`}</>;

    const filterData = new MarketplaceFilters(data);

    MarketplaceFilters.updateSelected(filterData.creativeFormats, props.selectedCreativeFormats);
    MarketplaceFilters.updateSelected(filterData.environments, props.selectedEnvironments);
    MarketplaceFilters.updateSelected(filterData.creativeTypes, props.selectedCreativeTypes);
    MarketplaceFilters.updateSelected(filterData.tags, props.selectedTags);
    MarketplaceFilters.updateSelected(filterData.orderStatuses, props.selectedStatuses);
    MarketplaceFilters.updateSelected(filterData.sellerIds, props.selectedSellerIds);

    return (
        <>
            <AccordionCheckList
                title="Status"
                items={filterData.orderStatuses}
                onItemSelection={(data) => {
                    data.map((elem) => {
                        if (elem === 3) {
                            data.push(8);
                        }
                    });
                    props.onOrderStatusSelection ? props.onOrderStatusSelection(data) : undefined;
                }}
            ></AccordionCheckList>
            <hr />
            <AccordionCheckList
                title="Publisher"
                items={filterData.sellerIds}
                onItemSelection={(data) => (props.onSellerSelection ? props.onSellerSelection(data) : undefined)}
            ></AccordionCheckList>
            <hr />
            <AccordionCheckList
                title="Environment"
                items={filterData.environments}
                onItemSelection={(data) =>
                    props.onEnvironmentsSelection ? props.onEnvironmentsSelection(data) : undefined
                }
            ></AccordionCheckList>
            <hr />
            <AccordionCheckList
                title="Creative Type"
                items={filterData.creativeTypes}
                onItemSelection={(data) =>
                    props.onCreativeTypeSelection ? props.onCreativeTypeSelection(data) : undefined
                }
            ></AccordionCheckList>
            <hr />
            <AccordionCheckList
                title="Tags"
                items={filterData.tags}
                onItemSelection={(data) => (props.onTagSelection ? props.onTagSelection(data) : undefined)}
            ></AccordionCheckList>
        </>
    );
};

OrdersGridFilter.propTypes = {
    selectedCreativeFormats: PropTypes.array.isRequired,
    selectedCreativeTypes: PropTypes.array.isRequired,
    selectedTags: PropTypes.array.isRequired,
    selectedEnvironments: PropTypes.array.isRequired,
    selectedStatuses: PropTypes.array.isRequired,
    selectedSellerIds: PropTypes.array.isRequired,

    onCreativeFormatsSelection: PropTypes.func,
    onEnvironmentsSelection: PropTypes.func,
    onCreativeTypeSelection: PropTypes.func,
    onTagSelection: PropTypes.func,
    onOrderStatusSelection: PropTypes.func,
    onSellerSelection: PropTypes.func,
};

export default OrdersGridFilter;
