import {
    Accordion,
    BannerMessage,
    BannerMessageVariant,
    BrowseAndSearch,
    BrowseAndSearchContainer,
    Button,
    ButtonVariant,
    CellType,
    DataFilter,
    DataTable,
    Flyout,
    Icon,
    Input,
    Popover,
    SkeletonLoader,
    Spinner,
    Typeahead,
} from '@amobee/component-library';
import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import './OrdersCreativeFlyout.scss';
import PropTypes from 'prop-types';
import { IOrder } from '../../interfaces/IOrder';
import { debounce } from 'lodash';
import AccordionCheckList from '../common/AccordionCheckList/AccordionCheckList';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ADVERTISERS, GET_DSP_CREATIVES_BY_MARKET, SUBMIT_CREATIVES } from '../../graphQL/queries';
import { IDspCreative } from '../../interfaces/IDspCreative';
import { IDspCreativeQuery } from '../../interfaces/queries/IDspCreativeQuery';
import { ICreateCreativeMutation } from '../../interfaces/mutations/ICreateCreativeMutation';
import { Row } from 'react-table';
import { IAdvertisersQuery } from '../../interfaces/queries/IAdvertisersQuery';
import { addSlashes, getCreativeGqlValue } from '../../utils/utils';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';

interface IAdvertiserSelection {
    name: string;
    id: string;
    label: string;
}

const OrdersCreativeFlyout: React.FunctionComponent<{
    open?: boolean;
    onClose?(): void;
    order: IOrder;
    page: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    onSubmitClick?(order: IOrder): void;
    onNewCreativeClick?(order: IOrder): void;
    creativeMutation?: ICreateCreativeMutation;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);

    const [selectedCreatives, setSelectedCreatives] = useState<Array<IDspCreative>>([]);
    const [searchString, setSearchString] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [selectedAdvertisers, setSelectedAdvertisers] = useState<IAdvertiserSelection[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [creativeData, setCreativeData] = useState<IDspCreative[]>([]);
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // Filtered Creative data
    const creativeDataCurrValue = useRef<IDspCreative[]>([]);
    const [createdCreative, setCreatedCreative] = useState<IDspCreative[]>();
    // Overall creative data
    const overallCreativeDataCurrValue = useRef<IDspCreative[]>([]);
    const marketId = marketplaceContext.marketId;

    const interactiveTvLayout = useMemo(() => {
        return getCreativeGqlValue(props.order.creativeTypes[0].id);
    }, [props.order]);

    const { error, data, loading } = useQuery<IDspCreativeQuery>(GET_DSP_CREATIVES_BY_MARKET, {
        variables: {
            marketId: marketId,
            advertiserIds: selectedAdvertisers.map((x) => x.id),
            interactiveTvLayout: interactiveTvLayout,
            match: searchString,
        },
        onCompleted: () => {
            if (data) {
                if (selectedAdvertisers.length === 0) {
                    // store the overall creative data the first time when the creative data is retrieved
                    overallCreativeDataCurrValue.current = data?.dspCreatives.edges.map((x) => x.node);
                    creativeDataCurrValue.current = [...overallCreativeDataCurrValue.current];
                } else {
                    creativeDataCurrValue.current = data?.dspCreatives.edges.map((x) => x.node);
                }
                setCreativeData([...creativeDataCurrValue.current]);
            }
        },
    });

    const advertiserQuery = useQuery<IAdvertisersQuery>(GET_ADVERTISERS, {
        variables: {
            marketId: marketId,
        },
    });

    useEffect(() => {
        const retVal = selectedCreatives?.slice() ?? [];

        if (props.creativeMutation?.createCreative) {
            retVal.push(props.creativeMutation?.createCreative);
        }

        setCreatedCreative(retVal);
        setSelectedCreatives(retVal);
    }, [props.creativeMutation]);

    useEffect(() => {
        setCreativeData([...creativeDataCurrValue.current]);
    }, [selectedCreatives]);

    const debouncedSearchQuery = useCallback(
        debounce((text) => {
            setSearchString(text);
        }, 300),
        [],
    );

    const debounceAdvertiserQuery = useCallback(
        debounce((text) => {
            setQuery(text);
        }, 150),
        [],
    );

    const [submitCreativeMutation] = useMutation(SUBMIT_CREATIVES, {
        refetchQueries: ['GetOrders'],
        onError: (error) => {
            setIsLoading(false);
            setErrorMessage(error?.message);
        },
        onCompleted: (data) => {
            props.page.current = 1;
            props.onSubmitClick?.(props.order);
            if (data.submitCreatives) {
                marketplaceContext.addBannerMessage?.(
                    `Your creative(s) will be submitted for approval for ${props.order.name}. You can check on approval status at any time through your Deal Order Inbox. Please note it may take several minutes after submission for order status to be updated.`,
                    BannerMessageVariant.SUCCESS,
                    'orders',
                );
            }
            setIsLoading(false);
        },
    });

    const filterCount = () => {
        return selectedAdvertisers.length + 1; // +1 is for the Layout which can't be changed
    };

    const previewColumn = (previewUrl: string) => {
        return (
            <div className="popOver" style={{ width: '100%' }}>
                <Popover
                    openOn="hover"
                    renderTrigger={() => (
                        <Icon style={{ marginLeft: 'auto', marginRight: 'auto', width: '100%' }} name="View" />
                    )}
                    placement="left"
                    title="Preview"
                >
                    <img
                        src={previewUrl}
                        onError={(event) => {
                            event.currentTarget.src = '/images/no_pic.svg';
                            event.currentTarget.onerror = null;
                        }}
                        width="250px"
                    />
                </Popover>
            </div>
        );
    };

    /* eslint-disable-next-line */
    const browseAndSearchColumns: Array<any> = [
        {
            Header: 'Name',
            accessor: 'name',
            id: 'name',
            highlightMatch: true,
            cellType: CellType.LINK,
        },
        {
            Header: 'Preview',
            accessor: 'previewUrl',
            id: 'previewUrl',
            /* eslint-disable-next-line */
            Cell: (rowInfo: any) => {
                return previewColumn(
                    rowInfo.row.original.previewUrl ? rowInfo.row.original.previewUrl : '/images/no_pic.svg',
                );
            },
            width: 30,
        },
        {
            Header: 'Creative ID',
            accessor: 'amobeeId',
            id: 'amobeeId',
            width: '50',
            highlightMatch: true,
            cellType: CellType.LINK,
        },
        {
            Header: 'Advertiser',
            id: 'advertiser.name',
            accessor: 'advertiser.name',
            width: '50',
        },
    ];

    /* eslint-disable-next-line */
    const selectedAndUploadedColumns: Array<any> = [
        {
            Header: 'Name',
            accessor: 'name',
            id: 'name',
            isRowActionColumn: true,
            rowActionButtons: [
                {
                    buttonType: 'button',
                    iconName: 'Remove',
                    tooltipText: 'Remove Creative',
                    onClick: (row: Row) => {
                        const creative = row.original as IDspCreative;

                        const index = selectedCreatives.findIndex((x) => x.amobeeId === creative.amobeeId);
                        const newVal = selectedCreatives.slice();

                        newVal.splice(index, 1);
                        setSelectedCreatives(newVal);
                    },
                },
            ],
        },
    ];

    const advertisers = useMemo(() => {
        if (advertiserQuery.loading || advertiserQuery.error) return [];

        return advertiserQuery.data?.advertisers?.map((advertiser) =>
            Object.assign({ label: `${advertiser.name}` }, advertiser),
        );
    }, [advertiserQuery]);

    const dataFilter = () => {
        return (
            <DataFilter open={showFilter}>
                {advertiserQuery ? (
                    <Accordion title="Advertiser" open={true}>
                        <Typeahead
                            allowMultiple={true}
                            collapsedIconName="Search"
                            optionNameAccessor="name"
                            optionUniqueIdAccessor="id"
                            noResultsText="No match found"
                            placeholder="Select"
                            resultNameAccessor="label"
                            results={
                                query
                                    ? advertisers?.filter((r) => {
                                          const reg = new RegExp(addSlashes(query), 'ig');

                                          return reg.test(r.name) || reg.test(r.id.toString());
                                      })
                                    : advertisers
                            }
                            onQueryChange={(q) => debounceAdvertiserQuery(q)}
                            onSelectionChange={(items: IAdvertiserSelection[]) => {
                                setSelectedAdvertisers(items);
                            }}
                            selected={selectedAdvertisers}
                            disableRightElement={true}
                        ></Typeahead>
                    </Accordion>
                ) : (
                    <SkeletonLoader animated columnHeight={20} columnMargin={8} />
                )}

                <AccordionCheckList
                    open={true}
                    title="Layout"
                    items={[{ display: interactiveTvLayout?.replace('_', ' ') ?? '', id: 1, selected: true }]}
                    onItemSelection={() => {
                        return false;
                    }}
                ></AccordionCheckList>
            </DataFilter>
        );
    };

    const browseAndSearchTable = () => (
        <DataTable
            callToActionProps={{
                description: 'Please try different keywords or upload new creative',
                title: 'No Result Found',
                type: 'EmptyTable',
            }}
            showCallToAction
            uniqueIdAccessor="amobeeId"
            columns={browseAndSearchColumns}
            data={creativeData ?? []}
            initialSelectedRowIds={{}}
            onRowSelectionChange={(event) => {
                const retVal: Array<IDspCreative> = [];
                // eslint-disable-next-line
                Object.entries(event.allSelectedRowIds as any).forEach(([key]) => {
                    retVal.push(
                        overallCreativeDataCurrValue.current?.find(
                            (entry: IDspCreative) => entry.amobeeId === key,
                        ) as IDspCreative,
                    );
                });
                if (createdCreative?.length) {
                    retVal.push(createdCreative[0]);
                }
                setSelectedCreatives(retVal);
            }}
            isSelectionEnabled={true}
            searchQuery={searchString}
            loading={loading}
        ></DataTable>
    );

    const selectedCreativesTable = () => (
        <DataTable
            uniqueIdAccessor="amobeeId"
            columns={selectedAndUploadedColumns}
            data={selectedCreatives}
        ></DataTable>
    );

    const searchInput = () => (
        <Input
            leftElement={<Icon name="Search" />}
            placeholder="Search by Creative Name or ID"
            showRightElement="whenFilled"
            fluid={true}
            rightElement={
                <Button variant={ButtonVariant.BORDERLESS} onClick={() => setSearchString('')} ultraCompact>
                    <Icon name="Remove" />
                </Button>
            }
            value={searchString}
            onChange={(event) => debouncedSearchQuery(event.target.value)}
        />
    );

    const renderBody = () => {
        return (
            <div className="flyout">
                <Button onClick={() => props.onNewCreativeClick?.(props.order)}>Upload New Creative</Button>
                <br />
                <br />
                <div className="title">Browse & Search Creatives</div>
                <div className="subTitle">
                    Creatives that meet seller&apos;s format requirements have been pre-filtered and shown below.
                </div>
                <BrowseAndSearchContainer>
                    <BrowseAndSearch
                        renderInput={() => searchInput()}
                        renderDataFilter={() => dataFilter()}
                        onFilterToggle={() => setShowFilter(!showFilter)}
                        activeFilterCount={filterCount()}
                    >
                        {browseAndSearchTable()}
                    </BrowseAndSearch>
                </BrowseAndSearchContainer>
                <br />
                <div className="titleBar">
                    <div className="title">Selected & Uploaded</div>
                    <Button
                        allowPropagation={true}
                        variant={ButtonVariant.BORDERLESS}
                        onClick={() => {
                            setCreatedCreative([]);
                            setCreativeData([]);
                            setSelectedCreatives([]);
                        }}
                    >
                        Clear
                    </Button>
                </div>
                <br />
                <BrowseAndSearchContainer>{selectedCreativesTable()}</BrowseAndSearchContainer>
            </div>
        );
    };

    const submitCreatives = () => {
        setIsLoading(true);
        setErrorMessage('');
        submitCreativeMutation({
            variables: {
                marketId: marketId,
                orderId: props.order.id,
                creativeIds: selectedCreatives.map((x) => x.amobeeId),
            },
        });
    };

    const renderFooter = (): JSX.Element => {
        return (
            <>
                <div className="errorText">
                    {errorMessage || error ? (
                        <Popover
                            openOn="hover"
                            placement="top"
                            renderTrigger={() => (
                                <BannerMessage variant={BannerMessageVariant.ERROR}>
                                    {errorMessage + error}
                                </BannerMessage>
                            )}
                        >
                            <div className="popover-body">
                                <div>
                                    <div className="dotred" />
                                </div>
                                <div>{errorMessage + error}</div>
                            </div>
                        </Popover>
                    ) : undefined}
                </div>
                <div className="buttonContainer">
                    <>
                        <Button
                            variant={ButtonVariant.NEUTRAL}
                            onClick={() => {
                                props.onClose?.();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant={ButtonVariant.PRIMARY} onClick={submitCreatives}>
                            Submit
                        </Button>
                    </>
                </div>
            </>
        );
    };

    return (
        <div className="order-creative-flyout">
            <Flyout
                dimmer
                open={props.open ?? false}
                portalRoot="body"
                onClose={props.onClose ? props.onClose : undefined}
                large={true}
            >
                <Flyout.Header
                    title="Submit Creatives"
                    descriptionText="Select from existing creatives or upload new ones."
                />
                <Flyout.Body>{renderBody()}</Flyout.Body>
                <Flyout.Footer>{renderFooter()}</Flyout.Footer>
            </Flyout>

            {isLoading && (
                <div className="loading light">
                    <Spinner showText size="large" indeterminateLoadingText="Submitting Creative(s)" />
                </div>
            )}
        </div>
    );
};

OrdersCreativeFlyout.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    order: PropTypes.any,
    page: PropTypes.any,
    creativeMutation: PropTypes.any,
    onSubmitClick: PropTypes.func,
    onNewCreativeClick: PropTypes.func,
};

export default OrdersCreativeFlyout;
