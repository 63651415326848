import {
    BannerMessage,
    BannerMessageVariant,
    Button,
    Icon,
    ButtonVariant,
    Flyout,
    FlyoutWidthSize,
    InlineChipContainer,
    Popover,
    DataTable,
    Modal,
    Chip,
    BasicMenu,
} from '@amobee/component-library';
import React, { useState } from 'react';
import './OrdersDetailsFlyout.scss';
import PropTypes from 'prop-types';
import { IOrder } from '../../interfaces/IOrder';
import { translateStatus, translateCreativeApprovalStatus } from '../../utils/utils';
import { getCreativeFormatsPreview } from '../../utils/utils';
import { useContext } from 'react';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { CreativeApprovalStatusEnum, ICreativeApprovalItem } from '../../interfaces/ICreativeApprovalStatus';
import CreativeApprovalPreview from '../CreativeApprovalPreview/CreativeApprovalPreview';
import { format } from 'date-fns';
import { useQuery } from '@apollo/client';
import { GET_CURRENCIES, GET_MARKET } from '../../graphQL/queries';
import { ICurrenciesQuery } from '../../interfaces/queries/ICurrenciesQuery';
import { Label } from '../../constants/LabelEnum';
import { Row } from 'react-table';

const OrdersDetailsFlyout: React.FunctionComponent<{
    open?: boolean;
    onClose?(): void;
    order: IOrder;
    errorText?: string;
    onBookClick?(product: IOrder): void;
    onSubmitClick?(product: IOrder): void;
    onDeclineClick?(product: IOrder): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);
    const marketLoader = useQuery(GET_MARKET, {
        variables: {
            marketId: marketplaceContext.marketId,
        },
    });

    const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
    const [creativeApprovalItem, setCreativeApprovalItem] = useState<ICreativeApprovalItem>();

    const currenciesResponse = useQuery<ICurrenciesQuery>(GET_CURRENCIES);
    const headerChildren = (
        <div>
            <div className="order-details-header-text">Order Details</div>
            {props.order.status.name === 'BOOKED' && props.order.dealId ? (
                <div className="order-details-header-button">
                    <Button
                        type="button"
                        icon="Launch"
                        variant={ButtonVariant.STATIC_BORDERLESS}
                        onClick={() => {
                            window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${marketplaceContext.marketId}/publisher-deal-list?invSrcId=105&mediaChannelId=2&searchString=${props.order.dealId}`;
                        }}
                    ></Button>
                </div>
            ) : null}
        </div>
    );

    const approxCurrency = (orderPrice: number, orderCurrencyCode: string, isBudget?: boolean) => {
        const marketCurrencyCode = marketLoader.data?.market.currencyCode;
        let convertedCurrency, orderCurrencyExchangeRate, marketCurrencyExchangeRate;

        if (orderCurrencyCode !== marketCurrencyCode) {
            currenciesResponse.data?.currencies.map((item) => {
                if (item.code === marketCurrencyCode) {
                    marketCurrencyExchangeRate = item?.exchangeRateUSD;
                }
                if (item.code === orderCurrencyCode) {
                    orderCurrencyExchangeRate = item?.exchangeRateUSD;
                }
            });
            if (orderCurrencyExchangeRate && marketCurrencyExchangeRate) {
                if (marketCurrencyCode === Label.USD_LABEL) {
                    convertedCurrency = (orderPrice / orderCurrencyExchangeRate).toFixed(2);
                } else {
                    const convertToUSD = orderPrice / orderCurrencyExchangeRate;
                    convertedCurrency = (convertToUSD * marketCurrencyExchangeRate).toFixed(2);
                }
            }
        }

        return (
            <>
                {convertedCurrency ? (
                    <>
                        (≈ {convertedCurrency} {marketCurrencyCode}) <> </>
                        {!isBudget ? (
                            <Popover openOn="hover" renderTrigger={() => <Icon name="Info" />}>
                                Conversion rates fluctuate. The actual amount charged for the inventory is determined at
                                impression time.
                            </Popover>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}
            </>
        );
    };

    const noSubmitCreativeButtonArray = [1, 2, 4, 9];
    const getCreativeStatusCell = (creativeApprovalItem: ICreativeApprovalItem): JSX.Element => {
        const displayStatus = translateCreativeApprovalStatus(creativeApprovalItem.approvalStatus.name);
        return (
            <div className="status">
                <div className="dot-container">
                    <Icon name={displayStatus.outline ? 'StatusDotOutline' : 'StatusDot'} color={displayStatus.color} />
                </div>
                <div style={{ display: 'block' }}>
                    <span className="text">{displayStatus.title}</span>
                    {creativeApprovalItem.approvalStatus.name === CreativeApprovalStatusEnum.REJECTED ? (
                        <Popover
                            openOn="hover"
                            placement="top"
                            renderTrigger={() => (
                                <span className="comment">{creativeApprovalItem.creativeRejectionComment}</span>
                            )}
                        >
                            {creativeApprovalItem.creativeRejectionComment}
                        </Popover>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    };
    const displayCreativePreview = (creativeApprovalItem: ICreativeApprovalItem): JSX.Element => {
        return (
            <div className="name">
                <span className="text">{creativeApprovalItem.name} </span>
            </div>
        );
    };

    const displayCreativeId = (creativeApprovalItem: ICreativeApprovalItem): JSX.Element => {
        return (
            <div className="name">
                <span className="text">{creativeApprovalItem.externalId}</span>
            </div>
        );
    };
    const displayPreview = (creativeApprovalItem: ICreativeApprovalItem) => {
        setOpenPreviewModal(true);
        setCreativeApprovalItem(creativeApprovalItem);
    };

    const getStatusCell = (order: IOrder): JSX.Element => {
        const displayStatus = translateStatus(order.status.name);
        return (
            <div className="status">
                <div className="dot-container">
                    <Icon name={displayStatus.outline ? 'StatusDotOutline' : 'StatusDot'} color={displayStatus.color} />
                </div>
                <div className="text">{displayStatus.title}</div>
            </div>
        );
    };

    const renderBody = () => {
        const order = props.order;

        return (
            <div className="flyout">
                <div className="title order-details-subtitle">Order Details</div>
                <div className="label">Name</div>
                <div className="text">{order.name}</div>
                <div className="label">Order Status</div>
                <div className="status">{getStatusCell(order)}</div>
                <div className="label">Order Id</div>
                <div className="text">{order.id}</div>
                <div className="label">Publisher</div>
                <div className="text">{order.marketPlace.name}</div>
                <div className="label">Deal Type</div>
                <div className="text">{order.dealType.name}</div>
                <div className="label">Environments</div>
                <div className="text">{order.environments?.map((x) => x.name).join(', ') ?? ''}</div>
                <div className="label">Minimum</div>
                <div className="text">
                    {`${order.price} ${order.currency?.code ?? ''} `}{' '}
                    {approxCurrency(order.price, order.currency?.code)}
                </div>
                <div className="label">Budget</div>
                <div className="text">
                    {`${order.budget} ${order.currency?.code ?? ''}`}{' '}
                    {approxCurrency(order.budget, order.currency?.code, true)}
                </div>
                <div className="label">Flight Dates</div>
                <div className="text">{`${format(new Date(order.startDate), 'dd MMM yyyy') ?? ''} - ${
                    format(new Date(order.endDate), 'dd MMM yyyy') ?? ''
                }`}</div>
                <div className="label">Tags</div>
                <div className="tags-list">
                    <InlineChipContainer
                        groups={[
                            {
                                chips:
                                    order.tags.map((chip) => {
                                        return { text: chip.name, closable: false };
                                    }) ?? [],
                            },
                        ]}
                    />
                    {props.order.tags.length > 1 ? (
                        <div className="tags-popover">
                            <Popover
                                openOn="hover"
                                renderTrigger={() => (
                                    <Chip closable={false} text={`${props.order.tags.length} Selections`}></Chip>
                                )}
                            >
                                <div>
                                    {props.order.tags.slice(0, props.order.tags.length).map((tag) => {
                                        return (
                                            <>
                                                <BasicMenu width={'100%'} style={{ fontSize: '14px' }}>
                                                    <BasicMenu.Item label={tag.name} />
                                                </BasicMenu>
                                            </>
                                        );
                                    })}
                                </div>
                            </Popover>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <hr />
                <div className="title">Creative Information</div>
                <div className="label">Creative Type</div>
                <div className="text">
                    {order.creativeTypes.map((x) => {
                        const creativeFormatPreview = getCreativeFormatsPreview(x.id);
                        return (
                            <span key={x.id}>
                                {x.name}&nbsp;
                                {creativeFormatPreview ? (
                                    <Popover openOn="hover" renderTrigger={() => <Icon name="Info" />} title={x.name}>
                                        <a href="https://smartclip.tv/wp-content/uploads/2021/02/smartclip_Ad_Guide_Addressable_TV_Europe.pdf">
                                            View more Information
                                        </a>
                                        <img src={creativeFormatPreview} width="250px" />
                                    </Popover>
                                ) : undefined}
                            </span>
                        );
                    })}
                </div>
                <div className="label">Creative Approval</div>
                <div className="text">{order.creativeApprovalRequired ? 'Yes' : 'No'}</div>
                <div style={{ marginTop: '16px', border: '1px gray solid', borderRadius: '2px' }}>
                    {order?.creativeApprovals ? (
                        <>
                            <DataTable
                                columns={[
                                    {
                                        Header: 'Name',
                                        accessor: 'name',
                                        id: 'externalId',
                                        width: 250,
                                        isRowActionColumn: true,
                                        rowActionButtons: [
                                            {
                                                buttonType: 'button',
                                                iconName: 'View',

                                                onClick: (row: Row) => {
                                                    displayPreview(row.original as ICreativeApprovalItem);
                                                },
                                            },
                                        ],
                                        Cell: (cellInfo) =>
                                            displayCreativePreview(cellInfo.row.original as ICreativeApprovalItem),
                                    },
                                    {
                                        Header: 'Creative ID',
                                        accessor: 'externalId',
                                        id: 'id',
                                        width: 100,
                                        Cell: (cellInfo) =>
                                            displayCreativeId(cellInfo.row.original as ICreativeApprovalItem),
                                    },
                                    {
                                        Header: 'Status',
                                        accessor: 'approvalStatus.name',
                                        id: 'approvalStatus.name',
                                        width: 150,
                                        Cell: (cellInfo) =>
                                            getCreativeStatusCell(cellInfo.row.original as ICreativeApprovalItem),
                                    },
                                ]}
                                uniqueIdAccessor="id"
                                data={order?.creativeApprovals}
                            ></DataTable>
                            {creativeApprovalItem ? (
                                <Modal
                                    closeButton
                                    open={openPreviewModal}
                                    onToggle={() => {
                                        setOpenPreviewModal(false);
                                    }}
                                >
                                    <CreativeApprovalPreview
                                        creativeApprovalItem={creativeApprovalItem}
                                    ></CreativeApprovalPreview>
                                </Modal>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : null}
                </div>
            </div>
        );
    };

    const canBeDeclined = () => {
        const status = props.order.status.name;

        if (
            status === 'OFFER' ||
            status === 'CREATIVE_NEEDED' ||
            status === 'APPROVAL_PENDING' ||
            status === 'CREATIVES_REJECTED' ||
            status === 'CREATIVE_APPROVED' ||
            status === 'REQUESTED'
        ) {
            return true;
        }

        return false;
    };

    const canBeApproved = () => {
        const status = props.order.status.name;

        if (status === 'OFFER' || status === 'CREATIVE_APPROVED') {
            return true; // We want it visible (not hidden) when it's offer or creative approved
        }
        return false;
    };

    const renderSecondaryButton = (): JSX.Element => {
        if (canBeDeclined()) {
            return (
                <Button
                    variant={ButtonVariant.NEUTRAL}
                    onClick={() => {
                        props.onDeclineClick?.(props.order);
                    }}
                    disabled={marketplaceContext.account.isReadOnly}
                >
                    Decline
                </Button>
            );
        } else {
            return <></>;
        }
    };

    const renderPrimaryButton = (): JSX.Element => {
        if (canBeApproved()) {
            return (
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => {
                        props.onBookClick?.(props.order);
                    }}
                    disabled={marketplaceContext.account.isReadOnly}
                >
                    Book
                </Button>
            );
        } else if (!noSubmitCreativeButtonArray.includes(props.order.status.id)) {
            return (
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => {
                        props.onSubmitClick?.(props.order);
                    }}
                    disabled={marketplaceContext.account.isReadOnly}
                >
                    Submit creative
                </Button>
            );
        } else {
            return <></>;
        }
    };

    const renderFooter = (): JSX.Element => {
        return (
            <>
                <div className="errorText">
                    {props.errorText ? (
                        <Popover
                            openOn="hover"
                            placement="top"
                            renderTrigger={() => (
                                <BannerMessage variant={BannerMessageVariant.ERROR}>{props.errorText}</BannerMessage>
                            )}
                        >
                            <div className="popover-body">
                                <div>
                                    <div className="dotred" />
                                </div>
                                <div>{props.errorText}</div>
                            </div>
                        </Popover>
                    ) : undefined}
                </div>
                <div className="buttonContainer">
                    {renderSecondaryButton()}
                    {renderPrimaryButton()}
                </div>
            </>
        );
    };

    return (
        <div className="order-details-flyout">
            <Flyout
                dimmer
                open={props.open ?? false}
                portalRoot="body"
                size={FlyoutWidthSize.small}
                onClose={props.onClose ? props.onClose : undefined}
            >
                <Flyout.Header>{headerChildren}</Flyout.Header>
                <Flyout.Body>{renderBody()}</Flyout.Body>
                <Flyout.Footer>{renderFooter()}</Flyout.Footer>
            </Flyout>
        </div>
    );
};

OrdersDetailsFlyout.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    order: PropTypes.any,
    errorText: PropTypes.string,
    onBookClick: PropTypes.func,
    onDeclineClick: PropTypes.func,
    onSubmitClick: PropTypes.func,
};

export default OrdersDetailsFlyout;
