import { Button, Modal, ButtonVariant, Spinner } from '@amobee/component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { ICreateOrderMutation } from '../../../interfaces/mutations/ICreateOrderMutation';
import './AlwaysOnModal.scss';
import { Label } from '../../../constants/LabelEnum';
import { IDealLists } from '../../../interfaces/IDealLists';

const AlwaysOnModal: React.FunctionComponent<{
    productName?: string;
    dealListName?: string;
    selectedDealList?: IDealLists;
    mutationResponse?: ICreateOrderMutation;
    open?: boolean;
    onClose?(): void;
    onAssignClick(): void;
    OnAssignDealList(): void;
    onMyDealListClick(): void;
    onMyDealsClick(): void;
    onBookAndAssign(): void;
    onBook(): void;
    onAddToDealLists(): void;
    dealPopupFlag?: boolean;
    isList?: boolean;
    featuredView?: boolean;
    dealView?: boolean;
    dealListsView?: boolean;
    tileView?: boolean;
    isLoading: boolean;
}> = (props) => {
    const close = () => {
        return props.onClose?.();
    };

    const onAssignClick = () => {
        if (props.dealListsView || featuredDealList) props.OnAssignDealList();
        else props.onAssignClick();
        close();
    };

    const existingDeal = props.mutationResponse?.createOrder?.existingDeal;

    let buttonText;
    let buttonAction;
    let productName;

    if (props.dealView || (props.featuredView && !props.selectedDealList)) {
        buttonText = Label.ADD_TO_MY_DEALS;
        buttonAction = props.onBook;
        productName = props.productName;
    } else if (props.dealListsView || (props.featuredView && props.selectedDealList)) {
        buttonText = Label.ADD_TO_MY_DEAL_LISTS;
        buttonAction = props.onAddToDealLists;
        productName = props.dealListName;
    } else {
        return null;
    }
    const featuredDealList = props.featuredView && props.selectedDealList;

    return (
        <>
            <Modal onToggle={close} open={props.dealPopupFlag} closeButton={true}>
                <Modal.Header>
                    {props.dealListsView || featuredDealList ? Label.DEAL_LIST_BOOKED : Label.DEAL_BOOKED}
                </Modal.Header>
                <Modal.Body>
                    <br />
                    {productName} {existingDeal ? Label.WAS_BOOKED : Label.IS_BOOKED}{' '}
                    <a
                        onClick={
                            props.dealListsView || featuredDealList ? props.onMyDealListClick : props.onMyDealsClick
                        }
                        href="#"
                    >
                        {props.dealListsView || featuredDealList ? Label.MY_DEAL_LIST : Label.MY_DEALS}
                    </a>
                    .
                    <p>
                        {props.dealListsView || featuredDealList
                            ? Label.MY_DEAL_LIST_INFO_TEXT
                            : Label.MY_DEAL_INFO_TEXT}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close}>Do It later</Button>
                    <Button variant={ButtonVariant.PRIMARY} onClick={onAssignClick}>
                        {Label.ASSIGN}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal onToggle={close} open={props.open} closeButton={true}>
                <Modal.Header>{Label.CONFIRM_BOOKING}</Modal.Header>
                <Modal.Body>
                    <br />
                    <>
                        Please confirm that you would like to move{' '}
                        {props.dealListsView || featuredDealList ? Label.DEAL_LIST : Label.DEAL}&nbsp;
                        {productName} to My {props.dealListsView || featuredDealList ? Label.DEAL_LISTS : Label.DEALS}.
                    </>
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close}>{Label.CANCEL}</Button>
                    <Button onClick={buttonAction} variant={ButtonVariant.PRIMARY}>
                        {buttonText}
                    </Button>
                </Modal.Footer>
            </Modal>
            {props.isLoading && (
                <div className="loading light">
                    <Spinner showText size="large" indeterminateLoadingText={Label.ACCEPTING_DEAL} />
                </div>
            )}
        </>
    );
};

AlwaysOnModal.propTypes = {
    productName: PropTypes.string,
    dealListName: PropTypes.string,
    mutationResponse: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onAssignClick: PropTypes.func.isRequired,
    OnAssignDealList: PropTypes.func.isRequired,
    onMyDealListClick: PropTypes.func.isRequired,
    onMyDealsClick: PropTypes.func.isRequired,
    onBookAndAssign: PropTypes.func.isRequired,
    onBook: PropTypes.func.isRequired,
    onAddToDealLists: PropTypes.func.isRequired,
    dealPopupFlag: PropTypes.bool,
    isList: PropTypes.bool,
    featuredView: PropTypes.bool,
    dealView: PropTypes.bool,
    dealListsView: PropTypes.bool,
    tileView: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
};

export default AlwaysOnModal;
