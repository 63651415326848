export enum DealsColumnHeadersEnum {
    DEAL_NAME = 'Deal Name',
    DEAL_ID = 'Deal Id',
    FORMAT = 'Format',
    DEAL_TYPE = 'Deal Type',
    ENVIRONMENT = 'Environment',
    PUBLISHER = 'Publisher',
    MINIMUM_CPM = 'Minimum CPM',
    IN_VIEW_RATE = 'In View Rate',
    INVENTORY_SOURCE = 'Inventory Source',
    BID_REQUESTS = 'Bid Requests (7 days)',
    AVAILABILITY_END_DATE = 'Availability End Date',
    IAB_CATEGORY = 'IAB Category',
    TAGS = 'Tags',
    RUN = 'Run',
}

export enum DealListsColumnHeadersEnum {
    DEAL_LIST_NAME = 'Deal List Name',
    DEAL_COUNT = 'Deal Count',
}

export enum ColumnHeaderFieldClickedEnum {
    DEAL_NAME = 'name',
    DEAL_ID = 'externalId',
    FORMAT = 'creativeFormats',
    DEAL_TYPE = 'amobeeDealTypes',
    ENVIRONMENT = 'environments',
    PUBLISHER = 'publisherName',
    MINIMUM_CPM = 'price',
    IN_VIEW_RATE = 'formattedInViewRate',
    INVENTORY_SOURCE = 'inventorySourceEnumValue',
    BID_RQUESTS = 'bidRequestVolume',
    AVAILABILITY_END_DATE = 'availabilityEndDate',
    IAB_CATEGORY = 'iabCategoryEnumId',
    TAGS = 'tags',
}
