import { IAccordionCheckListByStore } from '../components/common/interfaces/IAccordionCheckListByStore';
import { AmobeeEnvironmentEnum } from './ProductFilterEnums';

export class EnvironmentType {
    public static readonly INTERACTIVE_TV: IAccordionCheckListByStore = {
        display: AmobeeEnvironmentEnum.INTERACTIVE_TV,
        enumFormat: 'INTERACTIVE_TV',
        selected: false,
    };
    public static readonly DESKTOP_WEB: IAccordionCheckListByStore = {
        display: AmobeeEnvironmentEnum.DESKTOP_WEB,
        enumFormat: 'DESKTOP_WEB',
        selected: false,
    };
    public static readonly CTV_APP: IAccordionCheckListByStore = {
        display: AmobeeEnvironmentEnum.CTV_APP,
        enumFormat: 'CTV_APP',
        selected: false,
    };
    public static readonly MOBILE_APP: IAccordionCheckListByStore = {
        display: AmobeeEnvironmentEnum.MOBILE_APP,
        enumFormat: 'MOBILE_APP',
        selected: false,
    };
    public static readonly MOBILE_WEB: IAccordionCheckListByStore = {
        display: AmobeeEnvironmentEnum.MOBILE_WEB,
        enumFormat: 'MOBILE_WEB',
        selected: false,
    };
}
