import React from 'react';
import { SkeletonLoader } from '@amobee/component-library';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

export const DashboardCardLoader = (): JSX.Element => {
    const { width } = useWindowDimensions();
    const columnWidth = width > 1440 ? '50%' : '100%';

    return (
        <div className="dashboardloader">
            {Array(width > 1440 ? 15 : 16)
                .fill(null)
                .map((_, index) => (
                    <div className="loadingCard" key={index}>
                        <SkeletonLoader
                            animated
                            columnHeight={150}
                            columnMargin={0}
                            style={{ animationDuration: '1.3s' }}
                        />
                        <div style={{ padding: '10px 10px 40px 10px' }}>
                            <SkeletonLoader
                                animated
                                columnHeight={16}
                                columnMargin={10}
                                style={{ animationDuration: '1.3s', width: columnWidth }}
                            />
                            <SkeletonLoader
                                animated
                                columnHeight={16}
                                columnMargin={10}
                                style={{ animationDuration: '1.3s' }}
                            />
                            <SkeletonLoader
                                animated
                                columnHeight={16}
                                columnMargin={10}
                                style={{ animationDuration: '1.3s' }}
                            />
                            <SkeletonLoader
                                animated
                                columnHeight={16}
                                columnMargin={10}
                                style={{ animationDuration: '1.3s' }}
                            />
                        </div>
                    </div>
                ))}
        </div>
    );
};

export const DashboardFilterLoader = (): JSX.Element => (
    <div className="filter-loader">
        <SkeletonLoader animated includeBullet fadeBottom borderRadius={2} rowCount={10} />
    </div>
);
