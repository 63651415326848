export type CreativeApprovalStatus = 'PENDING' | 'APPROVED' | 'REJECTED';

export interface ICreativeApprovalItem {
    id: number;
    name: string;
    externalId: string;
    creativeRejectionComment: string;
    approvalStatus: {
        id: number;
        name: CreativeApprovalStatus;
    };
}

export enum CreativeApprovalStatusEnum {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
}
