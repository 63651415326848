import React, { useRef } from 'react';
import { DataTable, IFetchDataParams } from '@amobee/component-library';
import { pageCount } from '../../constants/OrdersPagination';
import { pageSize20 } from '../../constants/ProductsPagination';
import PropTypes from 'prop-types';
import { IColumns } from '../../interfaces/IColumns';
import { NetworkStatus, OperationVariables, QueryResult } from '@apollo/client';
import { ProductSortEnum } from '../../constants/ProductSortEnum';
import { IDealListsQuery } from '../../interfaces/queries/IDealListsQuery';
import { IDealList, IDealListDataRef } from '../../interfaces/IDealLists';
import { IRowSelection } from '../../interfaces/IRowSelection';
import { Label } from '../../constants/LabelEnum';

const DealListsDataTable: React.FunctionComponent<{
    otherDealListsResponse: QueryResult<IDealListsQuery, OperationVariables>;
    displayColumns: IColumns[];
    isLoadingDealLists: boolean;
    dealListDataRef: IDealListDataRef;
    pageIndex: React.MutableRefObject<number>;
    sortBy: React.MutableRefObject<string>;
    sortOrder: React.MutableRefObject<string>;
    setPublisherDealIds(data: Array<string>): void;
    getDealListId(id: number): void;
    getDealListName(name: string): void;
    onRowSelectionChange(event: IRowSelection): void;
}> = (props) => {
    const totalRowsRef = useRef<number>(0);
    const pageCountRef = useRef<number>(pageCount);
    const pageIndex = props.pageIndex;
    const dealListDataRef = props.dealListDataRef;
    const sortBy = props.sortBy;
    const sortOrder = props.sortOrder;

    const otherDealListsData = props.otherDealListsResponse?.data;
    const refetchOtherDealLists = props.otherDealListsResponse?.refetch;
    const networkStatus = props.otherDealListsResponse?.networkStatus;

    const netStatusRef = useRef<NetworkStatus | null>(null);

    if (networkStatus !== NetworkStatus.ready) {
        netStatusRef.current = networkStatus;
    }

    if (otherDealListsData && networkStatus === NetworkStatus.ready) {
        if (netStatusRef.current !== NetworkStatus.ready) {
            netStatusRef.current = networkStatus;
            const result = otherDealListsData?.dealList.edges.map((otherDealList: IDealList) => otherDealList.node);
            pageCountRef.current = Math.ceil(otherDealListsData?.dealList?.totalCount / pageSize20);
            totalRowsRef.current = otherDealListsData?.dealList?.totalCount;

            if (result && pageIndex.current === 1) {
                dealListDataRef.current = [...result];
            } else {
                if (result) {
                    dealListDataRef.current = dealListDataRef.current?.concat(result);
                }
            }
        }
    }

    const fetchActionOnTable = (params: IFetchDataParams) => {
        const fieldClicked = sortByField(params);
        const newSortOrder =
            params.sortBy && params.sortBy[0] && params.sortBy[0].desc
                ? ProductSortEnum.DESCENDING
                : ProductSortEnum.ASCENDING;
        const isSortIconClicked = checkIfSortIconIsClicked(fieldClicked, sortBy.current);
        const isPageIndexZero = params.pageIndex === 0;
        const isSortOrderChanged = sortOrder.current !== newSortOrder;

        if (isPageIndexZero && fieldClicked && (isSortIconClicked || isSortOrderChanged)) {
            sortOrder.current = newSortOrder;
            sortBy.current = fieldClicked === 'dealListName' ? ProductSortEnum.NAME : sortBy.current;
            pageIndex.current = 1;

            refetchOtherDealLists({
                sortBy: sortBy.current,
                sortOrder: sortOrder.current,
                pageNumber: params.pageIndex + 1,
            });
        } else if (checkIfPageIndexChanged(params.pageIndex + 1, pageIndex.current)) {
            pageIndex.current = params.pageIndex + 1;

            refetchOtherDealLists({ pageNumber: params.pageIndex + 1 });
        }
    };

    const sortByField = (params: IFetchDataParams): string => {
        const sortByString = params?.sortBy?.map((elem) => {
            if (elem.desc) return elem.id;
            else return elem.id;
        });
        if (sortByString) {
            return sortByString[0];
        }
        return '';
    };

    const checkIfSortIconIsClicked = (fieldClicked: string, current: string): boolean => {
        return fieldClicked.toLowerCase() !== current.toLowerCase();
    };

    const checkIfPageIndexChanged = (pageindexDataTable: number, pageIndexQuery: number): boolean => {
        return pageindexDataTable !== pageIndexQuery;
    };

    return (
        <DataTable
            columns={props.displayColumns}
            data={dealListDataRef?.current?.length > 0 ? dealListDataRef?.current : []}
            uniqueIdAccessor="id"
            totalRows={totalRowsRef.current}
            pageCount={pageCountRef.current}
            pageSize={pageSize20}
            onFetchData={(params) => fetchActionOnTable(params)}
            isPaginationEnabled={true}
            isSelectionEnabled={true}
            defaultSort={{
                desc: true,
                id: 'dealListName',
            }}
            onRowSelectionChange={($event) => props.onRowSelectionChange($event as unknown as IRowSelection)}
            callToActionProps={{
                description: Label.NO_DATA_FOUND_MSG,
                title: Label.NO_DEALS,
                type: Label.TYPE_EMPTY_TABLE,
            }}
            showCallToAction={true}
            disableSortRemove={true}
            loading={props.isLoadingDealLists}
        />
    );
};

DealListsDataTable.propTypes = {
    otherDealListsResponse: PropTypes.any,
    displayColumns: PropTypes.any,
    isLoadingDealLists: PropTypes.bool.isRequired,
    dealListDataRef: PropTypes.shape({ current: PropTypes.array.isRequired }).isRequired,
    pageIndex: PropTypes.any,
    setPublisherDealIds: PropTypes.func.isRequired,
    getDealListId: PropTypes.func.isRequired,
    getDealListName: PropTypes.func.isRequired,
    sortOrder: PropTypes.any,
    sortBy: PropTypes.any,
    onRowSelectionChange: PropTypes.func.isRequired,
};

export default DealListsDataTable;
