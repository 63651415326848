import React from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Routes } from '../constants/Routes';

export const PortalLoginCallback: React.FunctionComponent = () => {
    const history = useHistory();
    const location = history.location.search;
    const queryValues = queryString.parse(location);

    if (queryValues.accessToken) {
        window.localStorage.setItem('accessToken', queryValues.accessToken as string);

        history.push(Routes.DASHBOARD);

        return <>Loading...</>;
    } else {
        return <div>No access token returned from server.</div>;
    }
};
