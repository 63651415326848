import React from 'react';
import { Icon } from '@amobee/component-library';
import { useCSVDownloader } from 'react-papaparse';
import PropTypes from 'prop-types';
import { IColumns } from '../../interfaces/IColumns';
import { DealsColumnHeadersEnum, DealListsColumnHeadersEnum } from '../../constants/ColumnHeadersEnum';
import { OperationVariables, QueryResult } from '@apollo/client';
import { IProductsQuery } from '../../interfaces/queries/IProductsQuery';
import { IDealListsQuery } from '../../interfaces/queries/IDealListsQuery';
import { IEdge } from '../../interfaces/paging/IEdge';
import { IProduct } from '../../interfaces/IProduct';
import { IDealLists } from '../../interfaces/IDealLists';
import { IDealObj, IDealListObj } from '../../interfaces/IExportCSV';

const ExportCSV: React.FunctionComponent<{
    otherDealsResponse: QueryResult<IProductsQuery, OperationVariables>;
    otherDealListsResponse: QueryResult<IDealListsQuery, OperationVariables>;
    displayColumns: IColumns[];
    dealListsColumns: IColumns[];
    isList?: boolean;
    dealView?: boolean;
}> = (props) => {
    const { CSVDownloader, Type } = useCSVDownloader();

    const otherDealsData = props.otherDealsResponse?.data;
    const otherDealListsData = props.otherDealListsResponse?.data;
    const dealsTableColumns = props.displayColumns;
    const dealListsTableColumns = props.dealListsColumns;

    const exportCSV = () => {
        const csvDeals: IDealObj[] | IDealListObj[] = [];

        if (props.dealView && props.isList) {
            otherDealsData?.productsByStore?.edges.forEach((edge: IEdge<IProduct>) => {
                const dealObj: IDealObj = {};

                dealsTableColumns.forEach((col: IColumns) => {
                    const key = col.Header;
                    switch (key) {
                        case DealsColumnHeadersEnum.DEAL_NAME:
                            dealObj[key] = edge.node.name;
                            break;
                        case DealsColumnHeadersEnum.DEAL_ID:
                            dealObj[key] = edge.node.externalId;
                            break;
                        case DealsColumnHeadersEnum.FORMAT:
                            dealObj[key] = edge.node.creativeFormats;
                            break;
                        case DealsColumnHeadersEnum.DEAL_TYPE:
                            dealObj[key] = edge.node.amobeeDealTypes;
                            break;
                        case DealsColumnHeadersEnum.ENVIRONMENT:
                            dealObj[key] = edge.node.environments;
                            break;
                        case DealsColumnHeadersEnum.PUBLISHER:
                            dealObj[key] = edge.node.publisherName;
                            break;
                        case DealsColumnHeadersEnum.MINIMUM_CPM:
                            dealObj[key] = edge.node.price;
                            break;
                        case DealsColumnHeadersEnum.IN_VIEW_RATE:
                            dealObj[key] = edge.node.inViewRate;
                            break;
                        case DealsColumnHeadersEnum.INVENTORY_SOURCE:
                            dealObj[key] = edge.node.inventorySourceEnumValue;
                            break;
                        case DealsColumnHeadersEnum.BID_REQUESTS:
                            dealObj[key] = edge.node.bidRequestVolume;
                            break;
                        case DealsColumnHeadersEnum.AVAILABILITY_END_DATE:
                            dealObj[key] = edge.node.availabilityEndDate;
                            break;
                        case DealsColumnHeadersEnum.IAB_CATEGORY:
                            dealObj[key] = edge.node.iabCategoryEnumId;
                            break;
                        case DealsColumnHeadersEnum.TAGS:
                            dealObj[key] = edge.node.tags
                                .map((tag: { id: number; name: string }) => tag.name)
                                .join(', ');
                            break;
                    }
                });

                csvDeals.push(dealObj);
            });
        } else {
            otherDealListsData?.dealList?.edges.forEach((edge: IEdge<IDealLists>) => {
                const dealListsObj: IDealListObj = {};

                dealListsTableColumns.forEach((col: IColumns) => {
                    const key = col.Header;
                    switch (key) {
                        case DealListsColumnHeadersEnum.DEAL_LIST_NAME:
                            dealListsObj[key] = edge.node?.dealListName;
                            break;
                        case DealListsColumnHeadersEnum.DEAL_COUNT:
                            dealListsObj[key] = edge.node?.count;
                            break;
                    }
                });

                csvDeals.push(dealListsObj);
            });
        }

        return csvDeals;
    };

    return (
        <CSVDownloader
            type={Type.Button}
            filename={'deal-list'}
            bom={true}
            config={{
                delimiter: ';',
            }}
            data={() => exportCSV()}
            className="export"
        >
            <Icon name="Download" />
        </CSVDownloader>
    );
};

ExportCSV.propTypes = {
    otherDealsResponse: PropTypes.any,
    otherDealListsResponse: PropTypes.any,
    displayColumns: PropTypes.any,
    dealListsColumns: PropTypes.any,
    isList: PropTypes.bool,
    dealView: PropTypes.bool,
};

export default ExportCSV;
