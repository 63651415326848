import React from 'react';

export const UniversalLogin: React.FunctionComponent = () => {
    localStorage.removeItem('accessToken');
    const urlParams = new URLSearchParams(window.location.search);
    const tok = urlParams.get('accessToken') as string;
    console.log('the token is: ', tok);
    localStorage.setItem('accessToken', tok);
    // Notify parent iframe that this has successfully loaded
    const SUCCESSFULLY_LOADED = '1';
    const sourceURL = document.referrer;
    if (sourceURL && window.parent) {
        window.parent.postMessage(SUCCESSFULLY_LOADED, sourceURL);
    } else {
        console.error('Something was missing... Source URL:', sourceURL, 'window.parent:', window.parent);
    }
    return <div>Logged out</div>;
};
