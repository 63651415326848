import {
    BannerMessage,
    BannerMessageVariant,
    Button,
    ButtonVariant,
    Flyout,
    Icon,
    Input,
    InputVariant,
    Popover,
    Radio,
    RadioGroup,
    Select,
    Option,
    Spinner,
} from '@amobee/component-library';
import React, { useContext, useMemo, useState } from 'react';
import './OrdersNewCreativeFlyout.scss';
import PropTypes from 'prop-types';
import { IOrder } from '../../interfaces/IOrder';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import { CREATE_CREATIVE, GET_IAB_CATEGORIES, SINGLE_FILE_UPLOAD } from '../../graphQL/queries';
import { useMutation, useQuery } from '@apollo/client';
import { ISingleFileUploadMutation } from '../../interfaces/mutations/ISingleFileUploadMutation';
import AdvertiserSelect from '../common/AdvertiserSelect/AdvertiserSelect';
import { IAdvertiser } from '../../interfaces/IAdvertiser';
import { getCreativeFormatsPreview, translateInteractiveTvLayoutToAmobee } from '../../utils/utils';
import LanguageSelect from '../common/LanguageSelect/LanguageSelect';
import { ILanguage } from '../../laungages';
import { IIabCategoriesQuery } from '../../interfaces/queries/IIabCategoriesQuery';
import { IIabCategory } from '../../interfaces/IIabCategory';
import ThirdPartyTracking, { IThirdPartyTracking } from '../common/ThirdPartyTracking/ThirdPartyTracking';
import { ICreateCreativeMutation } from '../../interfaces/mutations/ICreateCreativeMutation';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';

type UploadStatus = undefined | 'upload' | 'success' | 'fail';

const OrdersNewCreativeFlyout: React.FunctionComponent<{
    open?: boolean;
    onClose?(): void;
    onGoBackClick?(order: IOrder): void;
    order: IOrder;
    onSaveClick?(order: IOrder, mutation: ICreateCreativeMutation): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);
    const marketId = marketplaceContext.marketId;
    const [errorMessage, setErrorMessage] = useState('');

    const [uploadStatus, setUploadStatus] = useState<UploadStatus>();

    const [selectedAdvertiser, setSelectedAdvertiser] = useState<IAdvertiser>();
    const [selectedFormat, setSelectedFormat] = useState<string>('video');
    const [selectedAdFormat, setSelectedAdFormat] = useState<string>('interactiveTv');
    const [selectedInteractiveLayout, setselectedInteractiveLayout] = useState<string>(
        props.order.creativeTypes[0].id.toString(),
    );
    const [uploadedFile, setUploadedFile] = useState<ISingleFileUploadMutation>();
    const [name, setName] = useState<string>('');
    const [previewUrl, setPreviewUrl] = useState<string>();
    const [clickUrlProtocol, setClickUrlProtocol] = useState<string>('https://');
    const [clickUrl, setClickUrl] = useState<string>();
    const interactiveDuration = '10';
    const [language, setLaunguage] = useState<ILanguage>();
    const [thirdPartyAdId, setThirdPartyAdId] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<IIabCategory>();
    const [selectedSubCategory, setSelectedSubCategory] = useState<IIabCategory>();
    const [thirdPartyTracking, setThirdPartyTracking] = useState<Array<IThirdPartyTracking>>([]);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [isClickURLValid, setIsClickURLValid] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState(false);

    const iabCategories = useQuery<IIabCategoriesQuery>(GET_IAB_CATEGORIES, { variables: { marketId: marketId } });

    const [createCreatives] = useMutation<ICreateCreativeMutation>(CREATE_CREATIVE, {
        onCompleted: (data) => {
            setIsLoading(false);
            props.onSaveClick?.(props.order, data);
        },
        onError: (error) => {
            setIsLoading(false);
            setErrorMessage(error.message);
        },
    });

    const categories: Array<IIabCategory> = useMemo(() => {
        if (!iabCategories.data) return [];

        return iabCategories.data.iabCategories.filter((category) => category.parentId == 0);
    }, [iabCategories.data]);

    const subCategories: Array<IIabCategory> = useMemo(() => {
        if (!iabCategories.data) return [];
        if (!selectedCategory) return [];

        return iabCategories.data?.iabCategories.filter((category) => category.parentId === selectedCategory.id);
    }, [iabCategories.data, selectedCategory]);

    const [singleFileUpload] = useMutation<ISingleFileUploadMutation>(SINGLE_FILE_UPLOAD, {
        onCompleted: (data) => {
            setUploadStatus('success');
            setUploadedFile(data);
        },
        onError: (error) => {
            setUploadStatus('fail');
            setErrorMessage(error.message);
        },
    });

    const isValidURL = (str: string): boolean => {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' +
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
                '((\\d{1,3}\\.){3}\\d{1,3}))' +
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
                '(\\?[;&a-z\\d%_.~+=-]*)?' +
                '(\\#[-a-z\\d_]*)?$',
            'i',
        );
        return !!pattern.test(str);
    };

    const onClickUrlChange = (url: string) => {
        setClickUrl(url);
        if (url.length >= 1) {
            const isValid = isValidURL(clickUrlProtocol + url);
            setFormIsValid(isValid);
            setIsClickURLValid(isValid);
            isValid ? setErrorMessage('') : setErrorMessage('Please enter a valid URL');
        } else {
            setIsClickURLValid(true);
            setErrorMessage('');
        }
    };

    const onSubmitClick = () => {
        setIsLoading(true);
        if (formIsValid) {
            createCreatives({
                variables: {
                    marketId: marketId,
                    advertiserId: selectedAdvertiser?.id.toString(),
                    name: name,
                    adFormat: selectedAdFormat,
                    mediaFormat: selectedFormat,
                    language: language?.code,
                    iabCategory: selectedSubCategory?.apiValueName,
                    amobeeAdServer: {
                        adSizeId: uploadedFile?.singleUpload?.adSizeId,
                        url: `${clickUrlProtocol}${clickUrl}`,
                        creativeAssetKey: uploadedFile?.singleUpload?.creativeAssetKey,
                    },
                    interactiveTvLayout: translateInteractiveTvLayoutToAmobee(parseInt(selectedInteractiveLayout)),
                    interactiveTvDuration: interactiveDuration,
                    thirdPartyTrackings: thirdPartyTracking.map((x) => {
                        return {
                            playerEvent: x.event?.apiValueName,
                            provider: x.platform?.apiValueName,
                            tracker: x.url,
                        };
                    }),
                    externalThirdPartyAdServerId: thirdPartyAdId,
                },
            });
        }
    };

    const renderBody = () => {
        return (
            <>
                <div className="flyout">
                    <div className="flex">
                        <div className="column">
                            <div className="title">Advertisers</div>
                            <AdvertiserSelect
                                marketId={marketId}
                                selectedAdvertiser={selectedAdvertiser}
                                onAdvertiserSelect={(advertiser) => setSelectedAdvertiser(advertiser)}
                            />
                        </div>
                        <div className="column">
                            <div className="title">Format</div>
                            <RadioGroup
                                onChange={(value) => setSelectedFormat((value.target as HTMLInputElement).value)}
                                selectedValue={selectedFormat}
                            >
                                <Radio value="display" disabled>
                                    <Icon name="ChannelBanner" /> Display
                                </Radio>
                                <Radio value="video">
                                    <Icon name="ChannelVideo" /> Video
                                </Radio>
                                <Radio value="audio" disabled>
                                    <Icon name="ChannelAudio" /> Audio
                                </Radio>
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="column">
                            <div className="title">Ad Format</div>
                            <RadioGroup
                                onChange={(value) => setSelectedAdFormat((value.target as HTMLInputElement).value)}
                                selectedValue={selectedAdFormat}
                            >
                                <Radio value="standard" disabled>
                                    Standard Video
                                </Radio>
                                <Radio value="interactiveTv">Interactive TV</Radio>
                            </RadioGroup>
                        </div>
                        <div className="column">
                            <div className="title">
                                Interactive TV Layout
                                {renderPreview(props.order.creativeTypes[0].name, selectedInteractiveLayout)}
                            </div>
                            <RadioGroup
                                onChange={(value) =>
                                    setselectedInteractiveLayout((value.target as HTMLInputElement).value)
                                }
                                selectedValue={selectedInteractiveLayout}
                            >
                                <Radio value="3" disabled={selectedInteractiveLayout !== '3'}>
                                    SwitchIn Zoom
                                </Radio>
                                <Radio value="4" disabled={selectedInteractiveLayout !== '4'}>
                                    SwitchIn Masthead
                                </Radio>
                                <Radio value="2" disabled={selectedInteractiveLayout !== '2'}>
                                    SwitchIn XXL
                                </Radio>
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="column">
                            <div className="title">Upload Creative</div>
                            <div className="uploadTarget">
                                <Dropzone
                                    accept="image/*"
                                    maxFiles={1}
                                    onChangeStatus={(file, status) => {
                                        if (status === 'done') {
                                            setUploadedFile(undefined);
                                            setUploadStatus('upload');
                                            singleFileUpload({ variables: { file: file.file, marketId: marketId } });
                                            setName(file.meta.name);
                                            setPreviewUrl(file.meta.previewUrl);
                                        }
                                    }}
                                    InputComponent={(input) => {
                                        /* eslint-disable @typescript-eslint/no-explicit-any */
                                        const getFilesFromEvent = (e: any): Promise<File[]> => {
                                            return new Promise((resolve) => {
                                                getDroppedOrSelectedFiles(e).then((chosenFiles: any) => {
                                                    resolve(chosenFiles.map((f: any) => f.fileObject as File));
                                                });
                                            });
                                        };
                                        /* eslint-enable @typescript-eslint/no-explicit-any */

                                        return (
                                            <div className="content">
                                                <Icon name="CloudUpload" color="#34A1CF" size={40} />
                                                <div className="description">
                                                    PNG, JPG, GIF file or{' '}
                                                    <label className="blue">
                                                        Browse File
                                                        <input
                                                            style={{ display: 'none' }}
                                                            type="file"
                                                            accept={input.accept}
                                                            onChange={(e) => {
                                                                getFilesFromEvent(e).then((chosenFiles) => {
                                                                    input.onFiles(chosenFiles);
                                                                });
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    }}
                                    PreviewComponent={(preview) => {
                                        return (
                                            <>
                                                <table>
                                                    <thead>
                                                        <tr className="titlebar">
                                                            <td>File Name</td>
                                                            <td>File Format</td>
                                                            <td>Status</td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {preview.files.map((preview) => {
                                                            return (
                                                                <tr key={preview.file.name}>
                                                                    <td>{preview.file.name}</td>
                                                                    <td>{preview.file.type}</td>
                                                                    <td>
                                                                        <div className="status">
                                                                            <div className="dot-container">
                                                                                {renderStatus(uploadStatus)}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div
                                                                            className="previewRemove"
                                                                            onClick={() => {
                                                                                preview.remove();
                                                                            }}
                                                                        >
                                                                            <Icon name="Remove" />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        );
                                    }}
                                ></Dropzone>
                            </div>
                        </div>
                    </div>

                    {uploadedFile ? (
                        <>
                            <div className="flex">
                                <div className="column">
                                    <div className="title">Name</div>
                                    <Input
                                        placeholder="Enter"
                                        variant={InputVariant.UNDERLINED}
                                        value={name}
                                        onChange={(change) => setName(change.target.value)}
                                    />
                                </div>
                                <div className="column">
                                    <div className="title">Preview</div>

                                    <img src={previewUrl} height="180"></img>
                                </div>
                            </div>
                            <div className="flex short">
                                <div className="column">
                                    <div className="title">
                                        {selectedAdFormat === 'interactiveTv' ? 'Advertiser Webpage' : 'Click URL'}
                                    </div>
                                    <div className="url">
                                        <Select
                                            irreversible
                                            value={{
                                                label: clickUrlProtocol,
                                                value: clickUrlProtocol,
                                            }}
                                            onChange={(selection) => setClickUrlProtocol(selection.value)}
                                        >
                                            <Option label="http://" value="http://"></Option>
                                            <Option label="https://" value="https://"></Option>
                                        </Select>

                                        <Input
                                            placeholder="Enter"
                                            variant={InputVariant.UNDERLINED}
                                            value={clickUrl}
                                            error={!isClickURLValid}
                                            onChange={(change) => onClickUrlChange(change.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex no-height">
                                <div className="column">
                                    <div className="title">Third Party Tracking</div>

                                    <ThirdPartyTracking
                                        marketId={marketId ?? 1}
                                        thirdPartyTracking={thirdPartyTracking}
                                        onChange={(change) => setThirdPartyTracking(change ?? [])}
                                    ></ThirdPartyTracking>
                                </div>
                            </div>
                            <div className="flex short">
                                <div className="column">
                                    <div className="title">Language</div>
                                    <LanguageSelect
                                        selectedLangauge={language}
                                        onSelectedLanguage={(language) => setLaunguage(language)}
                                    ></LanguageSelect>
                                </div>
                                <div className="column">
                                    <div className="title">Third-Party Ad ID</div>
                                    <Input
                                        placeholder="Enter"
                                        variant={InputVariant.UNDERLINED}
                                        value={thirdPartyAdId}
                                        onChange={(event) => setThirdPartyAdId(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex short">
                                <div className="column">
                                    <div className="title">Category</div>
                                    <Select
                                        irreversible
                                        onChange={(option) => {
                                            setSelectedCategory(
                                                categories.find((x) => x.id.toString() === option.value),
                                            );
                                        }}
                                        value={
                                            selectedCategory
                                                ? {
                                                      label: selectedCategory.name ?? '',
                                                      value: selectedCategory.id.toString() ?? '',
                                                  }
                                                : undefined
                                        }
                                        placeholder="Select"
                                    >
                                        {categories.map((x) => (
                                            <Option label={x.name} value={x.id.toString()} key={x.id} />
                                        ))}
                                    </Select>
                                </div>
                                <div className="column">
                                    <div className="title">Subcategory</div>
                                    <Select
                                        irreversible
                                        onChange={(option) => {
                                            setSelectedSubCategory(
                                                subCategories.find((x) => x.id.toString() === option.value),
                                            );
                                        }}
                                        value={
                                            selectedSubCategory
                                                ? {
                                                      label: selectedSubCategory.name ?? '',
                                                      value: selectedSubCategory.id.toString() ?? '',
                                                  }
                                                : undefined
                                        }
                                        placeholder="Select"
                                    >
                                        {subCategories.map((x) => (
                                            <Option label={x.name} value={x.id.toString()} key={x.id} />
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    };

    const renderFooter = (): JSX.Element => {
        return (
            <>
                <div className="errorText">
                    {errorMessage ? (
                        <Popover
                            openOn="hover"
                            placement="top"
                            renderTrigger={() => (
                                <BannerMessage variant={BannerMessageVariant.ERROR}>{errorMessage}</BannerMessage>
                            )}
                        >
                            <div className="popover-body">
                                <div>
                                    <div className="dotred" />
                                </div>
                                <div>{errorMessage}</div>
                            </div>
                        </Popover>
                    ) : undefined}
                </div>
                <div className="buttonContainer">
                    <>
                        <Button
                            variant={ButtonVariant.NEUTRAL}
                            onClick={() => {
                                props.onClose?.();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={onSubmitClick}
                            disabled={!uploadedFile || !formIsValid || !!errorMessage}
                        >
                            Save
                        </Button>
                    </>
                </div>
            </>
        );
    };

    return (
        <div className="order-new-creative-flyout">
            <Flyout
                dimmer
                open={props.open ?? false}
                portalRoot="body"
                onClose={props.onClose ? props.onClose : undefined}
                large={true}
            >
                <Flyout.Header>
                    <h1>
                        <Button
                            variant={ButtonVariant.STATIC_BORDERLESS}
                            onClick={() => props.onGoBackClick?.(props.order)}
                        >
                            <Icon name="ArrowLeft"></Icon>
                        </Button>
                        New Creative
                    </h1>
                </Flyout.Header>
                <Flyout.Body>{renderBody()}</Flyout.Body>
                <Flyout.Footer>{renderFooter()}</Flyout.Footer>
            </Flyout>

            {isLoading && (
                <div className="loading light">
                    <Spinner showText size="large" indeterminateLoadingText="Saving Creative" />
                </div>
            )}
        </div>
    );
};

OrdersNewCreativeFlyout.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    order: PropTypes.any,
    onSaveClick: PropTypes.func,
    onGoBackClick: PropTypes.func,
};

export default OrdersNewCreativeFlyout;

function renderStatus(uploadStatus: UploadStatus): React.ReactNode {
    switch (uploadStatus) {
        case 'success':
            return (
                <>
                    <Icon name="StatusDot" color="green" />
                    Uploaded
                </>
            );
        case 'fail':
            return (
                <>
                    <Icon name="StatusDot" color="red" />
                    Upload Failed
                </>
            );
        case 'upload':
            return (
                <>
                    <Icon name="StatusDotOutline" color="green" />
                    Uploading...
                </>
            );
        default:
            <></>;
    }
}
function renderPreview(title: string, selectedInteractiveLayout: string): React.ReactNode {
    const creativeFormatPreview = getCreativeFormatsPreview(parseInt(selectedInteractiveLayout));

    return (
        <Popover
            openOn="hover"
            renderTrigger={() => (
                <div style={{ marginLeft: '10px' }}>
                    <Icon name="Info" />
                </div>
            )}
            closeOnOutsideClick={true}
            title={title}
        >
            <img src={creativeFormatPreview} width="250px" />
        </Popover>
    );
}
