import { OrderStatus } from '../interfaces/IOrder';
import { CreativeApprovalStatus } from '../interfaces/ICreativeApprovalStatus';

export function getCreativeFormatsPreview(id: number): string | undefined {
    switch (id) {
        case 2:
            return '/images/switchin_xxl_thumbnail.png';
        case 3:
            return '/images/switchin_zoom_thumbnail.png';
        case 4:
            return '/images/switchin_masthead_thumbnail.png';
        default:
            return undefined;
    }
}

export type InteractiveTvLayout = 'Vast_Video' | 'SwitchIn_Zoom' | 'SwitchIn_Masthead' | 'SwitchIn_XXL' | undefined;

export function getCreativeGqlValue(id: number): InteractiveTvLayout {
    switch (id) {
        case 1:
            return 'Vast_Video';
        case 2:
            return 'SwitchIn_XXL';
        case 3:
            return 'SwitchIn_Zoom';
        case 4:
            return 'SwitchIn_Masthead';
        default:
            return undefined;
    }
}

export const translateStatus = (
    status: OrderStatus,
): { title: string; description: string; color: string; outline?: boolean } => {
    switch (status) {
        case 'OFFER':
            return {
                title: 'Approval Needed',
                description: 'Accept terms and add to My Deals',
                color: '#52a145',
            };
        case 'DECLINED':
            return { title: 'Declined', description: 'Declined by buyer', color: '#bbb' };
        case 'REJECTED':
            return { title: 'Rejected', description: 'Rejected by seller', color: '#e75556' };
        case 'CREATIVE_NEEDED':
            return { title: 'Creative Needed', description: 'Submit your creative', color: '#ec810b' };
        case 'APPROVAL_PENDING':
            return {
                title: 'Creative Submitted',
                description: 'Seller reviewing creative submission',
                color: '#52a145',
                outline: true,
            };
        case 'CREATIVES_REJECTED':
            return { title: 'Creative Rejected', description: 'Submit a new creative', color: '#e75556' };
        case 'CREATIVE_APPROVED':
            return {
                title: 'Approval Needed',
                description: 'Accept terms and add to My Deals',
                color: '#52a145',
            };
        case 'BOOKED':
            return { title: 'Approved', description: 'View/manage in My Deals', color: '#52a145' };
        case 'REQUESTED':
            return {
                title: 'Requested',
                description: 'Seller reviewing request',
                color: '#52a145',
                outline: true,
            };
        default:
            return { title: status, description: 'Unknown Status', color: '#000000' };
    }
};

export const translateCreativeApprovalStatus = (
    status: CreativeApprovalStatus,
): { title: string; color: string; outline?: boolean } => {
    switch (status) {
        case 'PENDING':
            return {
                title: 'Reviewing',
                color: '#52a145',
                outline: true,
            };
        case 'APPROVED':
            return {
                title: 'Approved',
                color: '#52a145',
            };
        case 'REJECTED':
            return {
                title: 'Rejected',
                color: '#e75556',
            };
        default:
            return { title: status, color: '#000000' };
    }
};

export const translateInteractiveTvLayoutFromAmobee = (amobeeId: number): number => {
    switch (amobeeId) {
        case 1:
            return 3;
        case 2:
            return 4;
        case 3:
            return 2;
        default:
            return -1;
    }
};

export const translateInteractiveTvLayoutToAmobee = (smartClipId: number): number => {
    switch (smartClipId) {
        case 3:
            return 1;
        case 4:
            return 2;
        case 2:
            return 3;
        default:
            return -1;
    }
};

/**
 * Returns a string with special characters escaped with backslashes
 * Special characters:  \ - ^ $ * + ? . ( ) | [ ] { }
 * @param str
 */
export const addSlashes = (str: string): string => {
    return str
        .trim()
        .replace(/\\/g, '\\\\')
        .replace(/[-^$*+?.()|[\]{}]/g, '\\$&');
};
