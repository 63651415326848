import { Breadcrumbs, CollapsibleNav, CollapsibleNavItem, SubHeader, Tooltip } from '@amobee/component-library';
import _, { sortBy } from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { IMarket } from '../../interfaces/IMarket';
import { IMarketsQuery } from '../../interfaces/queries/IMarketsQuery';
import ProductDashboard from '../ProductDashboard/ProductDashboard';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import OrdersGrid from '../OrdersGrid/OrdersGrid';
import { AccessDenied } from '../common/AccessDenied/AccessDenied';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { IStoreQuery } from '../../interfaces/queries/IStoreQuery';
import { GET_STORES } from '../../graphQL/queries';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { Label } from '../../constants/LabelEnum';
import './StorefrontFramework.scss';
import { IAmobeePreferredStore, IStore } from '../../interfaces/IStore';

export const StorefrontFramework: React.FunctionComponent<{
    data: IMarketsQuery | undefined;
    selectedMarket: IMarket;
    selectedStore?: IStore;
}> = (props) => {
    const history = useHistory();

    const marketplaceContext = useContext(MarketplaceContext);

    const { data } = useQuery<IStoreQuery>(GET_STORES, {
        variables: {
            marketId: marketplaceContext.marketId,
        },
    });

    const storeDataObjs = data?.stores;
    const sortedStoresData = _.sortBy(storeDataObjs, 'name');

    const containsOnlyAmobeePreferred =
        data?.stores?.length === 1 && data?.stores?.map((s) => s.name == Label.AMOBEE_PREFERRED).pop();

    // Left Pane navigation bar
    const dealItems = [
        {
            label: 'My Deals',
            onSelection: () => {
                window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${props.selectedMarket?.id}/publisher-deal-list`;
            },
        },
        {
            label: 'Deal Lists',
            onSelection: () => {
                window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${props.selectedMarket?.id}/deal-list`;
            },
            hasDivider: true,
        },
    ];

    const amobeePreferredStore: IAmobeePreferredStore[] = sortedStoresData
        ?.filter((s) => s.name === Label.AMOBEE_PREFERRED)
        .map((store) => ({
            label: 'Amobee Preferred Store',
            onSelection: () => {
                history.push(`/${props.selectedMarket?.id}/dashboard/${store.id}`, store);
            },
        }));

    const dealOrder = [
        {
            label: 'Deal Orders',
            onSelection: () => {
                history.push(`/${props.selectedMarket?.id}/orders`);
            },
        },
    ];

    let dealsChildItems = [...dealItems, ...amobeePreferredStore];
    if (!containsOnlyAmobeePreferred) {
        dealsChildItems = [...dealsChildItems, ...dealOrder];
    }

    // END of left pane navigation options

    const navigateMarket = (market: IMarket) => {
        history.push(`/${market.id}/dashboard`);
    };

    const navigateStore = (store: IStore) => {
        if (store.name === Label.AMOBEE_PREFERRED) {
            const amobeeStore: { label: string } = { label: '' };
            amobeeStore.label = 'Amobee Preferred Store';
            history.push(`/${props.selectedMarket?.id}/dashboard/${store.id}`, store);
            return amobeeStore;
        } else {
            const otherStore: { label: string } = { label: '' };
            otherStore.label = store.name;
            history.push(`/${props.selectedMarket?.id}/dashboard/${store.id}`, store);
            return otherStore;
        }
    };

    useEffect(() => {
        const aclCollapsibleNav = document.querySelector('.acl-CollapsibleNav');
        if (aclCollapsibleNav) {
            const links = aclCollapsibleNav.querySelectorAll('a');
            links.forEach((link) => link.removeAttribute('title'));
        }
    }, []);

    return (
        <>
            <SubHeader>
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            label: 'Market',
                            title: props.selectedMarket?.name ?? '',
                            dropdownEnabled: true,
                            dropdownItems: sortBy(props.data?.markets, 'id').map((market) => {
                                return {
                                    label: `${market?.id} - ${market?.name}`,
                                    onClick: () => navigateMarket(market),
                                };
                            }),
                            dropdownSearchable: true,
                        },
                        ...(props.selectedStore != undefined
                            ? [
                                  {
                                      label: 'Deals',
                                      title:
                                          props.selectedStore?.name === Label.AMOBEE_PREFERRED
                                              ? Label.AMOBEE_PREFERRED_STORE
                                              : props.selectedStore?.name,
                                      dropdownEnabled: true,
                                      dropdownItems: sortedStoresData?.map((store) => {
                                          return {
                                              label:
                                                  store.name === Label.AMOBEE_PREFERRED
                                                      ? Label.AMOBEE_PREFERRED_STORE
                                                      : store.name,
                                              onClick: () => navigateStore(store),
                                          };
                                      }),
                                      dropdownSearchable: true,
                                  },
                              ]
                            : []),
                    ]}
                />
            </SubHeader>

            <div className={'frame-content'}>
                <CollapsibleNav>
                    <Tooltip
                        arrow
                        delay={300}
                        placement="right"
                        text="Advertisers"
                        trigger={() => (
                            <CollapsibleNavItem
                                icon="Options"
                                label="Advertisers"
                                linkProps={{
                                    href: `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${props.selectedMarket?.id}/advertiser-list`,
                                }}
                            ></CollapsibleNavItem>
                        )}
                    />

                    <Tooltip
                        arrow
                        delay={300}
                        placement="right"
                        text="Creative"
                        trigger={() => (
                            <CollapsibleNavItem
                                icon="Creatives"
                                label="Creative"
                                linkProps={{
                                    href: `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${props.selectedMarket?.id}/creatives`,
                                }}
                            ></CollapsibleNavItem>
                        )}
                    />

                    <div className="tooltip">
                        <span className="tooltip-text deals">Deals</span>
                        <CollapsibleNavItem icon="Deal" label="Deals" childItems={dealsChildItems}></CollapsibleNavItem>
                    </div>

                    <div className="tooltip">
                        <span className="tooltip-text resource-library">Resource Library</span>
                        <CollapsibleNavItem
                            icon="Resources"
                            label="Resource Library"
                            childItems={[
                                {
                                    label: 'Contextual Targets',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/library-dashboard/markets/${props.selectedMarket?.id}/contextual-targets`;
                                    },
                                },
                                {
                                    label: 'Audience Segments',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/audience-suite-shared/#/library-dashboard/markets/${props.selectedMarket?.id}/segments`;
                                    },
                                },
                                {
                                    label: 'Concepts',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/library-dashboard/markets/${props.selectedMarket?.id}/concepts`;
                                    },
                                },
                                {
                                    label: 'Experiments',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${props.selectedMarket?.id}/experiments`;
                                    },
                                },
                                {
                                    label: 'Inventory Lists',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/library-dashboard/markets/${props.selectedMarket?.id}/inventory-lists`;
                                    },
                                },
                                {
                                    label: 'IP Lists',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/library-dashboard/markets/${props.selectedMarket?.id}/location-groups`;
                                    },
                                },
                                {
                                    label: 'Location Groups',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${props.selectedMarket?.id}/beacon-list`;
                                    },
                                },
                                {
                                    label: 'Beacons',
                                    onSelection: () => {
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/library-dashboard/markets/${props.selectedMarket?.id}/publisher-deal-list`;
                                    },
                                },
                            ]}
                        ></CollapsibleNavItem>
                    </div>

                    <Tooltip
                        arrow
                        delay={300}
                        placement="right"
                        text="Market Settings"
                        trigger={() => (
                            <CollapsibleNavItem
                                icon="Settings"
                                label="Market Settings"
                                linkProps={{
                                    href: `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${props.selectedMarket?.id}/marketDefaultConfiguration`,
                                }}
                            ></CollapsibleNavItem>
                        )}
                    />
                </CollapsibleNav>

                <div className="route-outlet" id="route-outlet">
                    <Switch>
                        <Route path="/:marketId/dashboard" component={ProductDashboard} />
                        <Route path="/:marketId/orders" component={OrdersGrid} />
                        <Route path="/access-denied" component={AccessDenied} />
                        <Route component={ErrorComponent} />
                    </Switch>
                </div>
            </div>
        </>
    );
};
StorefrontFramework.propTypes = {
    data: PropTypes.any,
    selectedMarket: PropTypes.any,
    selectedStore: PropTypes.any,
};

export default StorefrontFramework;
