import React, { useContext } from 'react';
import { Spinner } from '@amobee/component-library';
import { ICreativeApprovalItem } from '../../interfaces/ICreativeApprovalStatus';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_DSP_CREATIVES_BY_ID } from '../../graphQL/queries';
import { IDspCreativeByIDQuery } from '../../interfaces/queries/IDspCreativeByIDQuery';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';

const CreativeApprovalPreview: React.FunctionComponent<{
    creativeApprovalItem: ICreativeApprovalItem;
}> = (props) => {
    const amobeeIds: Array<number> = [];
    amobeeIds.push(Number(props.creativeApprovalItem.externalId));
    const marketplaceContext = useContext(MarketplaceContext);
    let creativeDspCreativeData: IDspCreativeByIDQuery | undefined;

    const { error, data, loading, networkStatus } = useQuery<IDspCreativeByIDQuery>(GET_DSP_CREATIVES_BY_ID, {
        variables: {
            marketId: marketplaceContext.marketId,
            amobeeIds: amobeeIds,
        },
    });
    if (loading) return <Spinner size="large" />;
    else if (error) return <img src="/images/no_pic.svg" width="300px" />;
    else {
        if (networkStatus === 7) {
            creativeDspCreativeData = data;
        }
        const previewColumn = (previewUrl: string) => {
            return (
                <img
                    src={previewUrl}
                    onError={(event) => {
                        event.currentTarget.src = '/images/no_pic.svg';
                        event.currentTarget.onerror = null;
                    }}
                    width="300px"
                />
            );
        };
        const displayCreativePreviewUrl = creativeDspCreativeData?.dspCreativesById.map((dspCreative) => {
            return <span key={dspCreative.amobeeId}>{previewColumn(dspCreative.previewUrl)}</span>;
        });
        return <>{displayCreativePreviewUrl}</>;
    }
};

CreativeApprovalPreview.propTypes = {
    creativeApprovalItem: PropTypes.any,
};

export default CreativeApprovalPreview;
