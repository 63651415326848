import {
    Icon,
    DataTable,
    Input,
    TableToolbar,
    TableToolbarOptions,
    DataFilter,
    FilterToolbar,
    Button,
    ButtonVariant,
    InputVariant,
    IFetchDataParams,
    BannerMessage,
    BannerMessageVariant,
    Spinner,
} from '@amobee/component-library/';
import { useMutation, useQuery, NetworkStatus } from '@apollo/client';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import React, { useCallback, useState, useRef, useReducer, Reducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-table';
import { GET_ORDERS, SEND_ACCEPT_ORDER, SEND_DECLINE_ORDER } from '../../graphQL/queries';
import { IOrder } from '../../interfaces/IOrder';
import { IOrdersQuery } from '../../interfaces/queries/IOrdersQuery';
import { translateStatus } from '../../utils/utils';
import './OrdersGrid.scss';
import OrdersGridFilter from '../OrderGridFilter/OrdersGridFilter';
import BookOrderModal from '../modals/BookOrderModal/BookOrderModal';
import DeclineOrderModal from '../modals/DeclineOrderModal/DeclineOrderModal';
import ErrorModal from '../modals/ErrorModal/ErrorModal';
import OrdersDetailsFlyout from '../OrdersDetailsFlyout/OrdersDetailsFlyout';
import OrdersCreativeFlyout from '../OrdersCreativeFlyout/OrdersCreativeFlyout';
import OrdersNewCreativeFlyout from '../OrdersNewCreativeFlyout/OrdersNewCreativeFlyout';
import { useContext } from 'react';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { pageSize, pageCount, pageNumber } from '../../constants/OrdersPagination';
import { ICreateCreativeMutation } from '../../interfaces/mutations/ICreateCreativeMutation';
import {
    ISendAcceptOrderMutation,
    ISendDeclineOrderMutation,
} from '../../interfaces/mutations/ISendAcceptOrderMutation';
import queryString from 'query-string';

type DialogStates = {
    selectedOrder?: IOrder;
    creativeMutation?: ICreateCreativeMutation;
    showDetails: boolean;
    showBookDialog: boolean;
    showDeclineModal: boolean;
    showCreativeFlyout: boolean;
    showNewCreativeFlyout?: boolean;
    showError: boolean;
    errorText: string;
};

type DialogStateActions =
    | { type: 'showDetail'; payload: IOrder }
    | { type: 'showBookModal'; payload: IOrder }
    | { type: 'showDeclineModal'; payload: IOrder }
    | { type: 'showCreativeFlyout'; payload: { order: IOrder; mutation?: ICreateCreativeMutation } }
    | { type: 'showNewCreativeFlyout'; payload: IOrder }
    | { type: 'showError'; payload: string }
    | { type: 'hideDetail' }
    | { type: 'hideBookModal' }
    | { type: 'hideDeclineModal' }
    | { type: 'hideCreativeFlyout' }
    | { type: 'hideNewCreativeFlyout' }
    | { type: 'hideError' }
    | { type: 'reset' };

export type OrderRedirects = 'none' | 'create' | 'assignment';

const reducer: Reducer<DialogStates, DialogStateActions> = (
    state: DialogStates,
    action: DialogStateActions,
): DialogStates => {
    switch (action.type) {
        case 'showDetail':
            return {
                selectedOrder: action.payload,
                creativeMutation: undefined,
                showDetails: true,
                showBookDialog: false,
                showDeclineModal: false,
                showError: false,
                showCreativeFlyout: false,
                showNewCreativeFlyout: false,
                errorText: '',
            };
        case 'showBookModal':
            return {
                selectedOrder: action.payload,
                creativeMutation: undefined,
                showDetails: false,
                showBookDialog: true,
                showDeclineModal: false,
                showError: false,
                showCreativeFlyout: false,
                showNewCreativeFlyout: false,
                errorText: '',
            };
        case 'showDeclineModal':
            return {
                selectedOrder: action.payload,
                creativeMutation: undefined,
                showDetails: false,
                showBookDialog: false,
                showDeclineModal: true,
                showError: false,
                showCreativeFlyout: false,
                showNewCreativeFlyout: false,
                errorText: '',
            };
        case 'showCreativeFlyout':
            return {
                selectedOrder: action.payload?.order,
                creativeMutation: action.payload?.mutation,
                showDetails: false,
                showBookDialog: false,
                showDeclineModal: false,
                showError: false,
                showCreativeFlyout: true,
                showNewCreativeFlyout: false,
                errorText: '',
            };
        case 'showNewCreativeFlyout':
            return {
                selectedOrder: action.payload,
                creativeMutation: undefined,
                showDetails: false,
                showBookDialog: false,
                showDeclineModal: false,
                showError: false,
                showCreativeFlyout: false,
                showNewCreativeFlyout: true,
                errorText: '',
            };
        case 'showError':
            return {
                selectedOrder: state.selectedOrder,
                creativeMutation: undefined,
                showDetails: false,
                showBookDialog: false,
                showDeclineModal: false,
                showError: true,
                showCreativeFlyout: false,
                showNewCreativeFlyout: false,
                errorText: action.payload,
            };
        case 'hideDetail':
            return { ...state, showDetails: false };
        case 'hideBookModal':
            return { ...state, showBookDialog: false };
        case 'hideDeclineModal':
            return { ...state, showDeclineModal: false };
        case 'hideCreativeFlyout':
            return { ...state, showCreativeFlyout: false };
        case 'hideNewCreativeFlyout':
            return { ...state, showNewCreativeFlyout: false };
        case 'reset':
        default:
            return {
                selectedOrder: undefined,
                showDetails: false,
                showBookDialog: false,
                showDeclineModal: false,
                showError: false,
                showCreativeFlyout: false,
                errorText: '',
            };
    }
};

const defaultSortByField = '-startDate';
const OrdersGrid: React.FunctionComponent = () => {
    const marketplaceContext = useContext(MarketplaceContext);
    const initialStatuses = [1, 2, 3, 5, 6, 7, 8];
    const marketId = marketplaceContext.marketId;
    const [openFilter, setOpenFilter] = useState(false);
    const [selectedCreativeFormats, setCreativeFormats] = useState<Array<number>>();
    const [selectedCreativeTypes, setCreativeTypes] = useState<Array<number>>();
    const [selectedEnvironments, setEnvironments] = useState<Array<number>>();
    const [selectedTags, setTags] = useState<Array<number>>();
    const [selectedStatuses, setStatuses] = useState<Array<number>>(initialStatuses);
    const [selectedSellerIds, setSellerIds] = useState<Array<number>>();
    const [isDecliningOrder, setIsDecliningOrder] = useState(false);
    const [isAcceptingOrder, setIsAcceptingOrder] = useState(false);
    const sortBy = useRef(defaultSortByField);
    const location = useHistory().location.search;
    const queryValues = queryString.parse(location);

    const [searchState, setSearchState] = useState({
        open: queryValues.orderId ? true : false,
        text: queryValues.orderId ? queryValues.orderId.toString() : '',
    });
    const [debouncedSearch, setDebouncedSearch] = useState(queryValues.orderId ? queryValues.orderId.toString() : '');
    const pageIndex = useRef(pageNumber);
    const orderDataRef = useRef<IOrder[]>([]);
    const pageCountRef = useRef<number>(pageCount);
    const totalRowsRef = useRef<number>(0);
    const netStatusRef = useRef<NetworkStatus | null>(null);

    const [dialogState, dialogStateDispatch] = useReducer(reducer, {
        selectedOrder: undefined,
        showDetails: false,
        showBookDialog: false,
        showDeclineModal: false,
        showError: false,
        showCreativeFlyout: false,
        errorText: '',
    });

    const debouncedSearchQuery = useCallback(
        debounce((text) => {
            if (text !== '') {
                pageIndex.current = 1;
            }
            setDebouncedSearch(text);
        }, 500),
        [],
    );

    const [sendAcceptOrder] = useMutation<ISendAcceptOrderMutation>(SEND_ACCEPT_ORDER, {
        refetchQueries: ['GetOrders'],
        onCompleted: (data) => {
            setIsAcceptingOrder(false);
            marketplaceContext.addBannerMessage?.(
                `${data.acceptOrder?.name} has been booked.`,
                BannerMessageVariant.ERROR,
                'orders',
            );
        },
        onError: (error) => {
            setIsAcceptingOrder(false);
            dialogStateDispatch({ type: 'showError', payload: error.message });
        },
    });

    const [sendDeclineOrder] = useMutation<ISendDeclineOrderMutation>(SEND_DECLINE_ORDER, {
        refetchQueries: ['GetOrders'],
        onCompleted: (data) => {
            setIsDecliningOrder(false);
            marketplaceContext.addBannerMessage?.(
                `${data.declineOrder?.name} has been declined.`,
                BannerMessageVariant.ERROR,
                'orders',
            );
        },
        onError: (error) => {
            setIsDecliningOrder(false);
            dialogStateDispatch({ type: 'showError', payload: error.message });
        },
    });

    const toggleSearch = (open?: boolean) => {
        setSearchText('', open ?? !searchState.open);
    };

    const setSearchText = (text: string, open?: boolean) => {
        setSearchState({ open: open ?? searchState.open, text: text });
        debouncedSearchQuery(text);
    };

    const onSearchBlur = () => {
        if (searchState.text === '') {
            toggleSearch(false);
        }
    };

    const acceptOrder = (order: IOrder, redirect: OrderRedirects = 'none', advertiserId?: string) => {
        setIsAcceptingOrder(true);
        sendAcceptOrder({
            variables: {
                marketId: marketId,
                orderId: order.id,
                advertiserId: advertiserId !== undefined ? [advertiserId] : [],
            },
        }).then((x) => {
            pageIndex.current = 1;
            if (x.data) {
                switch (redirect) {
                    case 'create':
                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/lineItem/new?publisherDealId=${x.data?.acceptOrder?.publisherDeal.id}`;
                        break;
                    case 'assignment':
                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/publisherDeal/assign?marketId=${marketId}&mediaChannelId=2&publisherDealId=${x.data?.acceptOrder?.publisherDeal.id}`;
                        break;
                    default:
                        dialogStateDispatch({ type: 'reset' });
                        return;
                }
            }
        });
    };

    const declineOrder = (order: IOrder) => {
        setIsDecliningOrder(true);
        sendDeclineOrder({
            variables: {
                marketId: marketId,
                orderId: order.id,
            },
        }).then(() => {
            pageIndex.current = 1;
            dialogStateDispatch({ type: 'reset' });
        });
    };

    const { error, data, loading, refetch, networkStatus } = useQuery<IOrdersQuery>(GET_ORDERS, {
        variables: {
            marketId: marketId,
            statuses: selectedStatuses,
            tagIds: selectedTags,
            creativeTypeIds: selectedCreativeTypes,
            environmentIds: selectedEnvironments,
            creativeFormatIds: selectedCreativeFormats,
            match: debouncedSearch,
            marketPlaceIds: selectedSellerIds,
            sortBy: sortBy.current,
            size: pageSize,
            page: pageIndex.current,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });

    if (networkStatus !== 7) {
        netStatusRef.current = networkStatus;
    }
    if (error) {
        return <>Error</>;
    }

    if (data && networkStatus === NetworkStatus.ready) {
        if (netStatusRef.current !== 7) {
            netStatusRef.current = networkStatus;
            const result = data?.orders.edges.map((x) => x.node);
            pageCountRef.current = Math.ceil(data.orders.totalCount / pageSize);
            totalRowsRef.current = data?.orders.totalCount;
            if (result && pageIndex.current === 1) {
                orderDataRef.current = [...result];
            } else {
                if (result) {
                    orderDataRef.current = orderDataRef.current?.concat(result);
                }
            }
        }
    }

    const getFilterCount = (): number => {
        return (
            (selectedCreativeFormats?.length ?? 0) +
            (selectedTags?.length ?? 0) +
            (selectedCreativeTypes?.length ?? 0) +
            (selectedEnvironments?.length ?? 0) +
            getStatusFilterCount(selectedStatuses) +
            (selectedSellerIds?.length ?? 0)
        );
    };

    const getStatusFilterCount = (selectedStatuses: Array<number>): number => {
        if (selectedStatuses.length > 0) {
            return selectedStatuses?.indexOf(8) > -1 ? selectedStatuses?.length - 1 : selectedStatuses?.length;
        }
        return 0;
    };

    const getStatusCell = (order: IOrder): JSX.Element => {
        const displayStatus = translateStatus(order.status.name);

        return (
            <div className="status">
                <div className="dot-container">
                    <Icon name={displayStatus.outline ? 'StatusDotOutline' : 'StatusDot'} color={displayStatus.color} />
                </div>
                <div className="text">
                    <div className="title">{displayStatus.title}</div>
                    <div className="description">{displayStatus.description}</div>
                </div>
            </div>
        );
    };

    const fetchActionOnTable = (params: IFetchDataParams) => {
        const fieldClicked = sortByField(params);
        if (fieldClicked && checkIfSortIconIsClicked(fieldClicked, sortBy.current)) {
            sortBy.current = fieldClicked;
            if (sortBy.current) {
                pageIndex.current = 1;
                refetch({ sortBy: sortBy.current, page: params.pageIndex + 1 });
            }
        } else if (checkIfPageIndexChanged(params.pageIndex + 1, pageIndex.current)) {
            pageIndex.current = params.pageIndex + 1;
            refetch({ page: params.pageIndex + 1 });
        }
    };

    const checkIfPageIndexChanged = (pageindexDataTable: number, pageIndexQuery: number): boolean => {
        return pageindexDataTable === pageIndexQuery ? false : true;
    };

    const checkIfSortIconIsClicked = (fieldClicked: string, current: string): boolean => {
        return fieldClicked === current ? false : true;
    };

    const sortByField = (params: IFetchDataParams): string => {
        const sortByString = params?.sortBy?.map((elem) => {
            if (elem.desc) return '-' + elem.id;
            else return elem.id;
        });
        if (sortByString) {
            return sortByString[0];
        }
        return '';
    };

    /* eslint-disable-next-line */
    const keyPress = (e: any) => {
        if (e.keyCode === 8) {
            pageIndex.current = 1;
        }
    };

    const renderToolbar = (): React.ReactElement => {
        return (
            <>
                {marketplaceContext.bannerQueue
                    .filter((x) => x.page === 'orders')
                    .map((banner, index) => {
                        return (
                            <BannerMessage
                                variant={banner.variant}
                                dismissible={true}
                                key={index}
                                onClose={() => {
                                    marketplaceContext.removeBannerMessage?.(banner.id);
                                }}
                            >
                                {banner.message}
                            </BannerMessage>
                        );
                    })}
            </>
        );
    };

    return (
        <div className="orders-grid">
            {renderToolbar()}
            <div className="tableHeader">
                <div className="title">Deal Orders</div>
                <div className="subtitle">
                    Submit creatives for seller’s approval after receiving deal orders. If creatives are approved you
                    can book the deal and it will be moved to `My Deal` or resubmit creatives if rejected.
                </div>
            </div>
            <DataTable
                callToActionProps={{
                    description: 'There was no data found',
                    title: 'Empty Data',
                    type: 'EmptyTable',
                }}
                columns={[
                    {
                        Header: 'Name',
                        accessor: 'name',
                        id: 'name',
                        isRowActionColumn: true,
                        minWidth: 200,
                        rowActionButtons: [
                            {
                                buttonType: 'button',
                                iconName: 'Upload',
                                tooltipText: 'Submit Creative',
                                isHidden: (row: Row) => {
                                    if (marketplaceContext.account.isReadOnly) return true;

                                    const status = (row.original as IOrder).status.name;

                                    if (status === 'CREATIVE_NEEDED' || status === 'CREATIVES_REJECTED') {
                                        return false; // We want it visible (not hidden) when it's offer or creative approved
                                    }
                                    return true;
                                },
                                onClick: (row: Row) => {
                                    dialogStateDispatch({
                                        type: 'showCreativeFlyout',
                                        payload: { order: row.original as IOrder },
                                    });
                                },
                            },
                            {
                                buttonType: 'button',
                                iconName: 'Check',
                                tooltipText: 'Book',
                                isHidden: (row: Row) => {
                                    if (marketplaceContext.account.isReadOnly) return true;
                                    const status = (row.original as IOrder).status.name;

                                    if (status === 'OFFER' || status === 'CREATIVE_APPROVED') {
                                        return false; // We want it visible (not hidden) when it's offer or creative approved
                                    }
                                    return true;
                                },
                                onClick: (row: Row) => {
                                    dialogStateDispatch({
                                        type: 'showBookModal',
                                        payload: row.original as IOrder,
                                    });
                                },
                            },
                            {
                                buttonType: 'button',
                                iconName: 'StatusBan',
                                titleText: 'Decline',
                                isHidden: (row: Row) => {
                                    if (marketplaceContext.account.isReadOnly) return true;

                                    const status = (row.original as IOrder).status.name;

                                    if (
                                        status === 'OFFER' ||
                                        status === 'CREATIVE_NEEDED' ||
                                        status === 'APPROVAL_PENDING' ||
                                        status === 'CREATIVES_REJECTED' ||
                                        status === 'CREATIVE_APPROVED' ||
                                        status === 'REQUESTED'
                                    ) {
                                        return false; // We want it visible (not hidden) when it's offer or creative approved
                                    }
                                    return true;
                                },
                                onClick: (row: Row) => {
                                    dialogStateDispatch({
                                        type: 'showDeclineModal',
                                        payload: row.original as IOrder,
                                    });
                                },
                            },
                            {
                                buttonType: 'button',
                                iconName: 'Details',
                                titleText: 'Details',
                                onClick: (row: Row) => {
                                    dialogStateDispatch({
                                        type: 'showDetail',
                                        payload: row.original as IOrder,
                                    });
                                },
                            },
                            {
                                buttonType: 'button',
                                iconName: 'Launch',
                                titleText: 'View in My Deals',
                                isHidden: (row: Row) => {
                                    const status = (row.original as IOrder).status.name;
                                    const dealId = (row.original as IOrder).dealId;
                                    if (status === 'BOOKED' && dealId) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                },
                                onClick: (row: Row) => {
                                    const dealId = (row.original as IOrder).dealId;
                                    if (dealId)
                                        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${marketId}/publisher-deal-list?invSrcId=105&mediaChannelId=2&searchString=${dealId}`;
                                },
                            },
                        ],
                    },
                    {
                        Header: 'Status',
                        accessor: '',
                        id: '',
                        disableSortBy: true,
                        Cell: (cellInfo) => getStatusCell(cellInfo.row.original as IOrder),
                        width: 175,
                    },
                    {
                        Header: 'Order ID',
                        accessor: 'id',
                        id: 'id',
                        disableSortBy: true,
                        width: 75,
                    },
                    {
                        Header: 'Publisher',
                        accessor: 'marketPlace.name',
                        id: 'marketPlace.name',
                        disableSortBy: false,
                    },
                    {
                        Header: 'Start Date',
                        accessor: 'startDate',
                        id: 'startDate',
                        width: 100,
                        disableSortBy: false,
                        Cell: (cellInfo) => {
                            const order = cellInfo.row.original as IOrder;
                            const date = !!order.startDate ? new Date(order.startDate) : null;
                            return !!date ? format(date, 'dd MMM yyyy') : '';
                        },
                    },
                    {
                        Header: 'Environment(s)',
                        accessor: '',
                        id: '',
                        disableSortBy: true,
                        Cell: (cellInfo) => {
                            const order = cellInfo.row.original as IOrder;
                            return order.environments?.map((inv) => inv.name).join(', ') ?? '';
                        },
                    },
                ]}
                data={orderDataRef.current.length > 0 ? orderDataRef.current : []}
                defaultSort={{
                    desc: true,
                    id: 'startDate',
                }}
                showCallToAction
                loading={loading}
                totalRows={totalRowsRef.current}
                pageCount={pageCountRef.current}
                pageSize={pageSize}
                isPaginationEnabled={true}
                disableSortRemove={true}
                onFetchData={(params) => fetchActionOnTable(params)}
                uniqueIdAccessor="id"
                isSelectionEnabled={true}
                renderDataFilter={() => (
                    <DataFilter open={openFilter}>
                        <OrdersGridFilter
                            selectedTags={selectedTags ?? []}
                            selectedCreativeTypes={selectedCreativeTypes ?? []}
                            selectedEnvironments={selectedEnvironments ?? []}
                            selectedCreativeFormats={selectedCreativeFormats ?? []}
                            selectedStatuses={selectedStatuses ?? []}
                            selectedSellerIds={selectedSellerIds ?? []}
                            onCreativeFormatsSelection={(items) => {
                                pageIndex.current = 1;
                                setCreativeFormats(items);
                            }}
                            onEnvironmentsSelection={(items) => {
                                pageIndex.current = 1;
                                setEnvironments(items);
                            }}
                            onCreativeTypeSelection={(items) => {
                                pageIndex.current = 1;
                                setCreativeTypes(items);
                            }}
                            onTagSelection={(items) => {
                                pageIndex.current = 1;
                                setTags(items);
                            }}
                            onOrderStatusSelection={(items) => {
                                pageIndex.current = 1;
                                setStatuses(items);
                            }}
                            onSellerSelection={(items) => {
                                pageIndex.current = 1;
                                setSellerIds(items);
                            }}
                        ></OrdersGridFilter>
                    </DataFilter>
                )}
            >
                <TableToolbar>
                    <TableToolbarOptions hide={false}>
                        <div className="dashboard-button-container-divider-table right">
                            <div className="container border-right border-left">
                                <Input
                                    className={searchState.open ? 'show-input' : 'hidden'}
                                    leftElement={<Icon name="Search" />}
                                    rightElement={
                                        <div onClick={() => toggleSearch()}>
                                            <Icon name="Remove" />
                                        </div>
                                    }
                                    showRightElement="onHover"
                                    placeholder="Search by Order Name or ID"
                                    variant={InputVariant.BORDERLESS}
                                    onBlur={onSearchBlur}
                                    value={searchState.text}
                                    onKeyDown={(e) => {
                                        keyPress(e);
                                    }}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </div>
                            <div className="container border-right border-left">
                                <Button
                                    className={searchState.open ? 'hidden' : undefined}
                                    variant={ButtonVariant.STATIC_BORDERLESS}
                                    onClick={() => toggleSearch()}
                                >
                                    <Icon name="Search" />
                                </Button>
                            </div>
                        </div>

                        <FilterToolbar
                            activeFilterCount={getFilterCount()}
                            open={openFilter}
                            onFilterToggle={() => setOpenFilter(!openFilter)}
                        ></FilterToolbar>
                    </TableToolbarOptions>
                </TableToolbar>
            </DataTable>

            {dialogState.selectedOrder ? (
                <>
                    <OrdersDetailsFlyout
                        open={dialogState.showDetails}
                        onClose={() => dialogStateDispatch({ type: 'hideDetail' })}
                        order={dialogState.selectedOrder}
                        onBookClick={(order) => dialogStateDispatch({ type: 'showBookModal', payload: order })}
                        onSubmitClick={(order) =>
                            dialogStateDispatch({ type: 'showCreativeFlyout', payload: { order: order } })
                        }
                        onDeclineClick={(order) => {
                            dialogStateDispatch({ type: 'showDeclineModal', payload: order });
                        }}
                    />

                    {dialogState.showCreativeFlyout ? (
                        <OrdersCreativeFlyout
                            open={dialogState.showCreativeFlyout}
                            onClose={() => dialogStateDispatch({ type: 'hideCreativeFlyout' })}
                            order={dialogState.selectedOrder}
                            page={pageIndex}
                            creativeMutation={dialogState.creativeMutation}
                            onSubmitClick={() => dialogStateDispatch({ type: 'hideCreativeFlyout' })}
                            onNewCreativeClick={(payload: IOrder) =>
                                dialogStateDispatch({ type: 'showNewCreativeFlyout', payload: payload })
                            }
                        />
                    ) : (
                        <></>
                    )}

                    {dialogState.showNewCreativeFlyout ? (
                        <OrdersNewCreativeFlyout
                            open={dialogState.showNewCreativeFlyout}
                            onGoBackClick={(order) =>
                                dialogStateDispatch({ type: 'showCreativeFlyout', payload: { order: order } })
                            }
                            onClose={() => dialogStateDispatch({ type: 'hideNewCreativeFlyout' })}
                            order={dialogState.selectedOrder}
                            onSaveClick={(order, mutation) =>
                                dialogStateDispatch({
                                    type: 'showCreativeFlyout',
                                    payload: { order: order, mutation: mutation },
                                })
                            }
                        />
                    ) : (
                        <></>
                    )}

                    {dialogState.showBookDialog ? (
                        <BookOrderModal
                            open={isAcceptingOrder ? false : dialogState.showBookDialog}
                            order={dialogState.selectedOrder}
                            onClose={() => {
                                dialogStateDispatch({ type: 'hideBookModal' });
                            }}
                            onBookClick={(order, redirect, advertiserId) => {
                                acceptOrder(order, redirect, advertiserId);
                            }}
                        />
                    ) : (
                        <></>
                    )}

                    {dialogState.showDeclineModal ? (
                        <DeclineOrderModal
                            open={isDecliningOrder ? false : dialogState.showDeclineModal}
                            order={dialogState.selectedOrder}
                            onClose={() => {
                                dialogStateDispatch({ type: 'hideDeclineModal' });
                            }}
                            onDeclineClick={(order) => {
                                order ? declineOrder(order) : undefined;
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </>
            ) : undefined}

            <ErrorModal
                open={dialogState.showError}
                error={dialogState.errorText}
                onClose={() => dialogStateDispatch({ type: 'hideError' })}
            />

            {isAcceptingOrder && (
                <div className="loading light">
                    <Spinner showText size="large" indeterminateLoadingText="Accepting Deal" />
                </div>
            )}
            {isDecliningOrder && (
                <div className="loading light">
                    <Spinner showText size="large" indeterminateLoadingText="Declining Order" />
                </div>
            )}
        </div>
    );
};

export default OrdersGrid;
