import React from 'react';
import { IThirdPartyEvent } from '../../../interfaces/IThirdPartyEvent';
import { IThirdPartyPlatform } from '../../../interfaces/IThirdPartyPlatform';
import PropTypes from 'prop-types';
import { Icon, Input, InputVariant, Select, Option } from '@amobee/component-library';
import { GET_THIRD_PARTY_DATA } from '../../../graphQL/queries';
import { IThirdPartyDataQuery } from '../../../interfaces/queries/IThirdPartyDataQuery';
import { useQuery } from '@apollo/client';

export interface IThirdPartyTracking {
    platform?: IThirdPartyPlatform;
    event?: IThirdPartyEvent;
    url?: string;
}

const ThirdPartyTracking: React.FunctionComponent<{
    marketId: number;
    thirdPartyTracking?: Array<IThirdPartyTracking>;
    onChange(thirdPartyTracking?: Array<IThirdPartyTracking>): void;
}> = (props) => {
    const thirdPartyDataQuery = useQuery<IThirdPartyDataQuery>(GET_THIRD_PARTY_DATA, {
        variables: { marketId: props.marketId },
    });

    const addRow = () => {
        const temp = props.thirdPartyTracking?.slice();

        temp?.push({});

        props.onChange(temp);
    };

    const removeRow = (index: number) => {
        const temp = props.thirdPartyTracking?.slice() ?? [];

        temp.splice(index, 1);

        props.onChange(temp);
    };

    const setPlatform = (index: number, value?: IThirdPartyPlatform) => {
        const temp = props.thirdPartyTracking?.slice() ?? [];
        temp[index].platform = value;
        props.onChange(temp);
    };

    const setEvent = (index: number, value?: IThirdPartyEvent) => {
        const temp = props.thirdPartyTracking?.slice() ?? [];
        temp[index].event = value;
        props.onChange(temp);
    };

    const setUrl = (index: number, value?: string) => {
        const temp = props.thirdPartyTracking?.slice() ?? [];
        temp[index].url = value;
        props.onChange(temp);
    };

    return (
        <table>
            <thead>
                <tr className="actionbar">
                    <td>
                        <div onClick={() => addRow()}>
                            <Icon name="Add" />
                        </div>
                    </td>
                </tr>
                <tr className="titlebar">
                    <td>Third Party Tracking</td>
                    <td>Event</td>
                    <td>Third Party Tracking Url</td>
                    <td></td>
                </tr>
            </thead>
            <tbody>
                {props.thirdPartyTracking?.map((tracking, index) => (
                    <tr key={index}>
                        <td>
                            <Select
                                irreversible
                                placeholder="Select"
                                value={
                                    tracking.platform
                                        ? {
                                              label: tracking.platform.description,
                                              value: tracking.platform.apiValueName,
                                          }
                                        : undefined
                                }
                                onChange={(change) => {
                                    setPlatform(
                                        index,
                                        thirdPartyDataQuery.data?.thirdPartyPlatforms.find(
                                            (data) => data.apiValueName === change.value,
                                        ),
                                    );
                                }}
                            >
                                {thirdPartyDataQuery.data?.thirdPartyPlatforms.map((data) => {
                                    return (
                                        <Option
                                            key={data.apiValueName}
                                            label={data.description}
                                            value={data.apiValueName.toString()}
                                        />
                                    );
                                })}
                            </Select>
                        </td>
                        <td>
                            <Select
                                irreversible
                                placeholder="Select"
                                value={
                                    tracking.event
                                        ? {
                                              label: tracking.event.description,
                                              value: tracking.event.apiValueName,
                                          }
                                        : undefined
                                }
                                onChange={(change) => {
                                    setEvent(
                                        index,
                                        thirdPartyDataQuery.data?.thirdPartyEvents.find(
                                            (data) => data.apiValueName === change.value,
                                        ),
                                    );
                                }}
                            >
                                {thirdPartyDataQuery.data?.thirdPartyEvents.map((data) => {
                                    return (
                                        <Option
                                            key={data.apiValueName}
                                            label={data.description}
                                            value={data.apiValueName.toString()}
                                        />
                                    );
                                })}
                            </Select>
                        </td>
                        <td>
                            <Input
                                variant={InputVariant.BORDERLESS}
                                placeholder="Enter"
                                value={tracking.url}
                                onChange={(event) => setUrl(index, event.target.value)}
                            />
                        </td>
                        <td>
                            <div onClick={() => removeRow(index)}>
                                <Icon name="Remove" />
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

ThirdPartyTracking.propTypes = {
    marketId: PropTypes.number.isRequired,
    thirdPartyTracking: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func.isRequired,
};

export default ThirdPartyTracking;
