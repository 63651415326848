import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ApolloClient, InMemoryCache, ApolloProvider, ServerError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { OktaAuth } from '@okta/okta-auth-js';
import { Routes } from '../../constants/Routes';
import { useLocation } from 'react-router-dom';

export const ApolloContextProvider: React.FunctionComponent<{
    oktaAuth?: OktaAuth;
    children: ReactNode;
}> = (props) => {
    const history = useHistory();
    const location = useLocation();

    const httpLink = createUploadLink({
        uri: `${process.env.REACT_APP_GRAPHQL_URI}`,
    });

    const logoutLink = onError((error) => {
        const networkError = error.networkError as ServerError;

        let status: number = networkError?.statusCode;

        // While we are loading, we might have some rogue 401s while the token propagate.
        // We need to give it a few seconds to propagate.

        if (location.pathname === Routes.ACCESS_DENIED) return;

        if (status === 400) {
            status = parseInt((networkError.result.code as string).substring(0, 3));
        }

        if (status === 401) {
            const extendedCode = error.response?.data?.code;

            if (!extendedCode) {
                history.push(Routes.LOGOUT_PATH);
            }
        } else if (status === 403) {
            history.push(Routes.ACCESS_DENIED);
        }
    });

    const authLink = setContext((_, { headers }) => {
        if (props.oktaAuth) {
            const token = props.oktaAuth.getAccessToken();

            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : '',
                },
            };
        } else {
            const token = localStorage.getItem('accessToken');

            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : '',
                },
            };
        }
    });

    const client = new ApolloClient({
        link: authLink.concat(logoutLink).concat(httpLink),
        cache: new InMemoryCache({
            typePolicies: {
                DspCreative: {
                    fields: {
                        amobeeId: {
                            read(amobeeId) {
                                return amobeeId.toString();
                            },
                        },
                    },
                },
            },
        }),
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

ApolloContextProvider.propTypes = {
    oktaAuth: PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
