import { Button, Modal, ButtonVariant, Icon, IconSize } from '@amobee/component-library';
import React from 'react';
import PropTypes from 'prop-types';

const ErrorModal: React.FunctionComponent<{
    open?: boolean;
    title?: string;
    error?: string;
    onClose?(): void;
}> = (props) => {
    const close = () => {
        return props.onClose?.();
    };

    return (
        <Modal onToggle={close} open={props.open} closeButton={true}>
            <Modal.Header>
                <Icon name="StatusAlert" color="#E75556" size={IconSize.base} />
                {props.title}
            </Modal.Header>
            <Modal.Body>{props.error}</Modal.Body>
            <Modal.Footer>
                <Button variant={ButtonVariant.PRIMARY} onClick={close}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ErrorModal.propTypes = {
    open: PropTypes.bool,
    error: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func,
};

export default ErrorModal;
