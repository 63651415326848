import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { MarketplaceApolloFramework } from '../components/MarketplaceFramework/MarketplaceFramework';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Routes } from '../constants/Routes';
import { OktaLogout } from './OktaLogout';

const config = {
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENTID,
    redirectUri: `${process.env.REACT_APP_URI}${Routes.CALLBACK_PATH}`,
    scopes: (process.env.REACT_APP_OKTA_SCOPE ?? '').split(/\s+/),
};

const oktaAuth = new OktaAuth(config);

export const UseOktaLogin: React.FunctionComponent = () => {
    const history = useHistory();

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Route exact path={Routes.CALLBACK_PATH} component={LoginCallback} />
            <Route exact path={Routes.LOGOUT_PATH} component={OktaLogout} />

            <SecureRoute
                path="/"
                render={() => <MarketplaceApolloFramework oktaAuth={oktaAuth}></MarketplaceApolloFramework>}
            />
        </Security>
    );
};
