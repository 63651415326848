import { IAccountQuery } from '../../interfaces/queries/IAccountQuery';
import { IMarketsQuery } from '../../interfaces/queries/IMarketsQuery';
import { OperationVariables, QueryResult, useQuery } from '@apollo/client';
import { GET_ACCOUNT, GET_DEAL_LISTS, GET_MARKETS, GET_PRODUCTS_QUERY } from '../../graphQL/queries';
import { IProductsQuery } from '../../interfaces/queries/IProductsQuery';
import { IDealListsQuery } from '../../interfaces/queries/IDealListsQuery';
import { IGetDealsVariables } from '../../interfaces/IDeal';
import { IGetDealListsVariables } from '../../interfaces/IDealLists';

export const useQueryMultiple = (): [
    QueryResult<IMarketsQuery, OperationVariables>,
    QueryResult<IAccountQuery, OperationVariables>,
] => {
    const markets = useQuery<IMarketsQuery>(GET_MARKETS);
    const accounts = useQuery<IAccountQuery>(GET_ACCOUNT);
    return [markets, accounts];
};

export const useQueryMultipleData = (
    dealsVariables: IGetDealsVariables,
    dealListsVariables: IGetDealListsVariables,
    isFeaturedView?: boolean,
    isDealView?: boolean,
    isDealListView?: boolean,
): [
    QueryResult<IProductsQuery, OperationVariables>,
    QueryResult<IProductsQuery, OperationVariables>,
    QueryResult<IDealListsQuery, OperationVariables>,
    QueryResult<IDealListsQuery, OperationVariables>,
] => {
    const featuredDealsVariable = { ...dealsVariables, featured: true };
    const otherDealsVariable = { ...dealsVariables, featured: false };
    const featuredDealListsVariable = { ...dealListsVariables, featured: true };
    const otherDealListsVariable = { ...dealListsVariables, featured: false };

    const dealOrDealListView = isDealView || isDealListView;
    const featuredOrDealView = isFeaturedView || isDealView;
    const featuredOrDealListView = isFeaturedView || isDealListView;

    const getProductQuery = (variables: IGetDealsVariables, skipCondition: boolean) =>
        useQuery<IProductsQuery>(GET_PRODUCTS_QUERY, {
            variables: variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache',
            skip: skipCondition,
        });

    const getDealListsQuery = (variables: IGetDealListsVariables, skipCondition: boolean) =>
        useQuery<IDealListsQuery>(GET_DEAL_LISTS, {
            variables: variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache',
            skip: skipCondition,
        });

    const featuredProducts = getProductQuery(featuredDealsVariable, dealOrDealListView ?? false);
    const otherProducts = getProductQuery(otherDealsVariable, featuredOrDealListView ?? false);
    const featuredDealLists = getDealListsQuery(featuredDealListsVariable, dealOrDealListView ?? false);
    const otherDealLists = getDealListsQuery(otherDealListsVariable, featuredOrDealView ?? false);

    return [featuredProducts, otherProducts, featuredDealLists, otherDealLists];
};
