import React from 'react';
import { Routes } from '../constants/Routes';
import logo from '../assets/logo_2.gif';
import '../components/MarketplaceFramework/MarketplaceFramework.scss';

export const PortalLogout: React.FunctionComponent = () => {
    localStorage.removeItem('accessToken');

    const url = (process.env.REACT_APP_AMOBEE_LOGIN ?? '') + Routes.PORTAL_LOGOUT;

    window.location.href =
        url + '?platformRedirectionUrl=' + encodeURI(`${process.env.REACT_APP_URI}${Routes.CALLBACK_PATH}`);

    return (
        <div className="loading">
            <img src={logo} className="logoImage" />
        </div>
    );
};
