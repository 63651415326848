import { Button, Modal, ButtonVariant } from '@amobee/component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { IOrder } from '../../../interfaces/IOrder';

const DeclineOrderModal: React.FunctionComponent<{
    order?: IOrder;
    open?: boolean;
    onClose?(): void;
    onDeclineClick(order?: IOrder): void;
}> = (props) => {
    const close = () => {
        return props.onClose?.();
    };

    return (
        <Modal onToggle={close} open={props.open} closeButton={true}>
            <Modal.Header>Decline Order</Modal.Header>
            <Modal.Body>
                Are you sure you want to decline {props.order?.name}? This action can&apos;t be undone.
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.onDeclineClick(props.order)}>Decline</Button>
                <Button variant={ButtonVariant.PRIMARY} onClick={close}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

DeclineOrderModal.propTypes = {
    order: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDeclineClick: PropTypes.func.isRequired,
};

export default DeclineOrderModal;
