import React, { useState, useCallback, useEffect } from 'react';
import { Card, Button, ButtonVariant, Link } from '@amobee/component-library';
import PropTypes from 'prop-types';
import { GET_FILTERS_BY_STORE } from '../../graphQL/queries';
import { useQuery } from '@apollo/client';
import { IFiltersByStoreQuery } from '../../interfaces/queries/IFiltersByStoreQuery';
import { MarketplaceFiltersByStore } from '../common/MarketplaceFilters/MarketplaceFiltersByStore';
import { AccordionCheckListByStore } from '../common/AccordionCheckListByStore/AccordionCheckListByStore';
import { useHistory } from 'react-router-dom';
import {
    AccordionFilterEnum,
    DashboardFilterEnum,
    BidRequestValue,
    FilterStateEnum,
} from '../../constants/ProductFilterEnums';
import { IBidRequestVolumeFilter, IPriceRangeFilter } from '../../interfaces/queries/IFiltersByStore';
import { debounce } from 'lodash';
import { Label } from '../../constants/LabelEnum';
import { EnvironmentType } from '../../constants/EnvironmentTypeEnum';
import { AmobeeCreativeType } from '../../constants/CreativeTypeEnum';
import { AmobeeDealTypes } from '../../constants/DealTypeEnums';
import { IAccordionCheckListByStore } from '../common/interfaces/IAccordionCheckListByStore';
import { DealDetailsColumnEnum } from '../../constants/DealDetailsColumnEnum';
import { Publishers } from '../../constants/PriorityPublishersEnum';
import { IStore } from '../../interfaces/IStore';
import { MapStateType } from '../../constants/MapStateType';
import { DashboardFilterLoader } from '../Loader/Loader';

const DashboardFilter: React.FunctionComponent<{
    selectedInventorySource: Array<string>;
    updateSelectedInventorySource: Array<string>;
    selectedAmobeeEnvironment: Array<string>;
    selectedAmobeeCreativeFormat: Array<string>;
    selectedAmobeeDealType: Array<string>;
    selectedRtlDealType: Array<string>;
    selectedRtlCreativeType: Array<string>;
    selectedPriceRange: Array<IPriceRangeFilter>;
    selectedBidRequestVolume: Array<IBidRequestVolumeFilter>;
    updateSelectedBidRequestVolume: Array<IBidRequestVolumeFilter>;
    selectedTags: Array<string>;
    selectedPublishers: Array<string>;
    selectedIabCategory: Array<string>;
    selectedCategory: Array<string>;
    marketId: string | undefined;
    singleInventorySourceCheck?: boolean;
    singlePublisherCheck?: boolean;
    dealListsView?: boolean;
    clearSelection?: string;
    filterDataMap?: Map<string, Array<string> | Array<IBidRequestVolumeFilter> | Array<IPriceRangeFilter>>;
    selectionMap?: Map<string, string>;
    featuredView: boolean;
    getFilterCount?: number;
    setSelectionMap(state: Map<string, string>): void;
    setMapState(state: MapStateType): void;
    setClearSelection(state: string): void;
    updateSelectedCategories(selectedItems: Array<string>): void;
    updateSelectedPublishers(selectedItems: Array<string>): void;
    updateSelectedBidVolume(selectedItems: Array<IBidRequestVolumeFilter>): void;
    updateSelectedInventory(selectedItems: Array<string>): void;
    onInventorySourceSelection(selectedItems: Array<string>): void;
    onAmobeeEnvironmentSelection(selectedItems: Array<string>): void;
    onAmobeeCreativeFormatSelection(selectedItems: Array<string>): void;
    onAmobeeDealTypeSelection(selectedItems: Array<string>): void;
    onRtlDealTypeSelection(selectedItems: Array<string>): void;
    onRtlCreativeTypeSelection(selectedItems: Array<string>): void;
    onPriceRangeSelection(selectedItems: Array<IPriceRangeFilter>): void;
    onBidRequestVolumeSelection(selectedItems: Array<IBidRequestVolumeFilter>): void;
    onTagSelection(selectedItems: Array<string>): void;
    onPublishersSelection(selectedItems: Array<string>): void;
    onIabCategorySelection(selectedItems: Array<string>): void;
    onCategorySelection(selectedItems: Array<string>): void;
}> = (props) => {
    const history = useHistory();
    const selectedStore = history.location.state as IStore;

    const [searchString, setSearchString] = useState('');

    const filterVariables = ![
        ...props.selectedInventorySource,
        ...props.selectedAmobeeEnvironment,
        ...props.selectedAmobeeCreativeFormat,
        ...props.selectedAmobeeDealType,
        ...props.selectedRtlCreativeType,
        ...props.selectedRtlDealType,
        ...props.selectedPriceRange,
        ...props.selectedBidRequestVolume,
    ].length
        ? null
        : {
              inventorySource: props.selectedInventorySource,
              amobeeEnvironments: props.selectedAmobeeEnvironment,
              amobeeCreativeFormats: props.selectedAmobeeCreativeFormat,
              amobeeDealTypes: props.selectedAmobeeDealType,
              rtlCreativeType: props.selectedRtlCreativeType,
              rtlDealType: props.selectedRtlDealType,
              priceRanges: props.selectedPriceRange?.map((range) => {
                  return { minPrice: range.minPrice, maxPrice: range.maxPrice };
              }),
              bidRequstVolumeRange: props.selectedBidRequestVolume?.map((range) => {
                  return {
                      minBidRequestVolume: range.minBidRequestVolume,
                      maxBidRequestVolume: range.maxBidRequestVolume,
                  };
              })[0],
          };

    const marketId = Number(props.marketId);

    const { data, loading } = useQuery<IFiltersByStoreQuery>(GET_FILTERS_BY_STORE, {
        variables: {
            marketId: marketId,
            storeId: selectedStore ? selectedStore?.value || selectedStore?.id : '0',
            featured: props.featuredView,
            filters: filterVariables,
        },
    });

    useEffect(() => {
        let selectionStatus = false;
        props.selectionMap?.forEach((value: string) => {
            if (value == FilterStateEnum.SELECTED) {
                selectionStatus = true;
            }
        });
        if (props.clearSelection && !selectionStatus) {
            props.setClearSelection(FilterStateEnum.REMOVE_FILTERS);
        }
    }, [props.selectionMap]);

    const debouncedSearchQuery = useCallback(
        debounce((text) => {
            setSearchString(text);
        }, 50),
        [],
    );
    const setSearchText = (text: string) => {
        debouncedSearchQuery(text);
    };
    const filterData = new MarketplaceFiltersByStore(data, props.dealListsView);

    const publishersData: IAccordionCheckListByStore[] = filterData.publishers;

    const publishersToShowFirst: Publishers[] = [
        Publishers.PEACOCK,
        Publishers.HBO_MAX,
        Publishers.SPOTIFY,
        Publishers.PANDORA,
        Publishers.DISCOVERY_PLUS,
        Publishers.VIACOMCBS,
        Publishers.DIRECTV,
        Publishers.SLING,
        Publishers.PARAMOUNT,
        Publishers.NBCU,
    ];

    const sortedPublishersData = publishersData.sort((a: IAccordionCheckListByStore, b: IAccordionCheckListByStore) => {
        const indexA = publishersToShowFirst.indexOf(a.display as Publishers);
        const indexB = publishersToShowFirst.indexOf(b.display as Publishers);

        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        } else if (indexA !== -1) {
            return -1;
        } else if (indexB !== -1) {
            return 1;
        } else {
            return 0;
        }
    });

    const combinedCategoryData = [...filterData.tags, ...filterData.iabCategories];

    const categoryData = combinedCategoryData.reduce((uniqueData, obj) => {
        const existingObj = uniqueData.find((item: IAccordionCheckListByStore) => item.display === obj.display);
        if (!existingObj) {
            uniqueData.push(obj);
        }
        return uniqueData;
    }, [] as Array<IAccordionCheckListByStore>);

    const [selectedTextPriceRange, setSelectedTextPriceRange]: [IPriceRangeFilter, (range: IPriceRangeFilter) => void] =
        useState({ minPrice: 0, maxPrice: 0 });

    const EnvironmentArray = [
        EnvironmentType.INTERACTIVE_TV,
        EnvironmentType.DESKTOP_WEB,
        EnvironmentType.CTV_APP,
        EnvironmentType.MOBILE_APP,
        EnvironmentType.MOBILE_WEB,
    ];

    const EnvironmentArrayForDealList = [
        EnvironmentType.DESKTOP_WEB,
        EnvironmentType.CTV_APP,
        EnvironmentType.MOBILE_APP,
        EnvironmentType.MOBILE_WEB,
    ];

    const AmobeeDealTypeArray = [
        AmobeeDealTypes.PRIVATE_AUCTION,
        AmobeeDealTypes.PROGRAMMATIC_GUARENTEED,
        AmobeeDealTypes.UNRESERVED_FIXED,
    ];

    const AmobeeCreativeFormatsArray = [AmobeeCreativeType.AUDIO, AmobeeCreativeType.DISPLAY, AmobeeCreativeType.VIDEO];

    MarketplaceFiltersByStore.updateSelected(filterData.inventorySource, props.updateSelectedInventorySource);
    MarketplaceFiltersByStore.updateSelected(filterData.amobeeEnvironments, props.selectedAmobeeEnvironment);
    MarketplaceFiltersByStore.updateSelected(filterData.amobeeCreativeFormats, props.selectedAmobeeCreativeFormat);
    MarketplaceFiltersByStore.updateSelected(filterData.amobeeDealTypes, props.selectedAmobeeDealType);
    MarketplaceFiltersByStore.updateSelectedPriceRange(filterData.priceRange, props.selectedPriceRange);
    MarketplaceFiltersByStore.updateSelectedBidRequestVolume(
        filterData.bidRequestVolume,
        props.updateSelectedBidRequestVolume,
    );
    MarketplaceFiltersByStore.updateSelected(filterData.tags, props.selectedTags);
    MarketplaceFiltersByStore.updateSelected(filterData.publishers, props.selectedPublishers);
    MarketplaceFiltersByStore.updateSelected(filterData.iabCategories, props.selectedIabCategory);
    MarketplaceFiltersByStore.updateSelected(categoryData, props.selectedCategory);

    const dealTypeFixedItemsValue = filterData.amobeeDealTypes;
    const dealTypeItems = AmobeeDealTypeArray;
    const dealTypeHandle = (data: string[]) => {
        props.onAmobeeDealTypeSelection ? props.onAmobeeDealTypeSelection(data) : undefined;
    };
    const createFormatTitle = DashboardFilterEnum.FORMAT;
    const creativeFormatFixedValue = filterData.amobeeCreativeFormats;
    const creativeFormatItems = AmobeeCreativeFormatsArray;
    const creativeFormatHandle = (data: string[]) => {
        props.onAmobeeCreativeFormatSelection ? props.onAmobeeCreativeFormatSelection(data) : undefined;
    };
    const assignPriceRange = (data: string[], label: string) => {
        if (label === DashboardFilterEnum.MIN) {
            selectedTextPriceRange.minPrice = Number(data);
        } else if (label === DashboardFilterEnum.MAX) {
            selectedTextPriceRange.maxPrice = Number(data);
        }
    };
    const updateSelectedPriceRange = (selectedPriceRange: Array<IPriceRangeFilter>) => {
        if (selectedTextPriceRange.minPrice || selectedTextPriceRange.maxPrice) {
            const record: IPriceRangeFilter = { minPrice: 0, maxPrice: 0 };
            selectedPriceRange.push(record);
            if (selectedTextPriceRange.minPrice) {
                selectedPriceRange[0].minPrice = selectedTextPriceRange.minPrice;
            }
            if (selectedTextPriceRange.maxPrice) {
                selectedPriceRange[0].maxPrice = selectedTextPriceRange.maxPrice;
            }
        }
        props.setMapState(
            (item: Map<string, Array<IPriceRangeFilter>>) =>
                new Map(item.set(AccordionFilterEnum.CPM, selectedPriceRange)),
        );
        props.setClearSelection(FilterStateEnum.SELECTED);
    };

    const cpmHandle = (data: string[], label: string) => {
        const selectedPriceRange: Array<IPriceRangeFilter> = [];
        assignPriceRange(data, label);
        updateSelectedPriceRange(selectedPriceRange);
    };
    const bidRequestHandle = (data: string[] | IAccordionCheckListByStore[]) => {
        const selectedBidRequestVolume: Array<IBidRequestVolumeFilter> = [];

        data.forEach((i: string | IAccordionCheckListByStore) => {
            const label = filterData.bidRequestVolume.find((range) => i === range.enumFormat)?.display ?? '';

            switch (label) {
                case Label.UNDER_ONE_MIL_LABEL:
                    selectedBidRequestVolume.push({
                        minBidRequestVolume: BidRequestValue.ZERO_VALUE,
                        maxBidRequestVolume: BidRequestValue.UNDER_ONE_MIL,
                        label: Label.UNDER_ONE_MIL_LABEL,
                    });
                    break;
                case Label.TEN_MIL_LABEL:
                    selectedBidRequestVolume.push({
                        minBidRequestVolume: BidRequestValue.ONE_MIL,
                        maxBidRequestVolume: BidRequestValue.TEN_MIL,
                        label: Label.TEN_MIL_LABEL,
                    });
                    break;
                case Label.HUNDRED_MIL_LABEL:
                    selectedBidRequestVolume.push({
                        minBidRequestVolume: BidRequestValue.TEN_MIL,
                        maxBidRequestVolume: BidRequestValue.HUNDRED_MIL,
                        label: Label.HUNDRED_MIL_LABEL,
                    });
                    break;
                case Label.ONE_BIL_LABEL:
                    selectedBidRequestVolume.push({
                        minBidRequestVolume: BidRequestValue.HUNDRED_MIL,
                        maxBidRequestVolume: BidRequestValue.ONE_BIL,
                        label: Label.ONE_BIL_LABEL,
                    });
                    break;
                case Label.TEN_BIL_LABEL:
                    selectedBidRequestVolume.push({
                        minBidRequestVolume: BidRequestValue.ONE_BIL,
                        maxBidRequestVolume: BidRequestValue.TEN_BIL,
                        label: Label.TEN_BIL_LABEL,
                    });
                    break;
                case Label.ABOVE_TEN_BIL_LABEL:
                    selectedBidRequestVolume.push({
                        minBidRequestVolume: BidRequestValue.TEN_BIL,
                        maxBidRequestVolume: BidRequestValue.HUNDRED_BIL,
                        label: Label.ABOVE_TEN_BIL_LABEL,
                    });
                    break;
            }
        });
        props.setMapState(
            (item: Map<string, Array<IBidRequestVolumeFilter>>) =>
                new Map(item.set(Label.BID_REQUEST_VOLUME, selectedBidRequestVolume)),
        );
        props.updateSelectedBidVolume?.(selectedBidRequestVolume);
        props.setClearSelection(FilterStateEnum.SELECTED);
    };

    const clearSelection = () => {
        props.setClearSelection(FilterStateEnum.CLEAR);
        props.filterDataMap?.forEach(
            (value: Array<string> | Array<IBidRequestVolumeFilter> | Array<IPriceRangeFilter>, key: string) => {
                props.setMapState((item: Map<string, undefined>) => new Map(item.set(key, undefined)));
                handleSelection(key, undefined);
            },
        );
        setSelectedTextPriceRange({ minPrice: 0, maxPrice: 0 });
    };

    const handleSelection = (
        key: string,
        value: string[] | IPriceRangeFilter[] | IBidRequestVolumeFilter[] | undefined,
    ) => {
        switch (key) {
            case createFormatTitle:
                creativeFormatHandle(value as string[]);
                break;
            case DashboardFilterEnum.ENVIRONMENT:
                props.onAmobeeEnvironmentSelection?.(value as string[]);
                break;
            case DealDetailsColumnEnum.DEAL_TYPE:
                dealTypeHandle(value as string[]);
                break;
            case AccordionFilterEnum.PUBLISHERS:
                props.onPublishersSelection?.(value as string[]);
                break;
            case Label.CATEGORY_FILTER_TITLE:
                props.onCategorySelection?.(value as string[]);
                break;
            case AccordionFilterEnum.CPM:
                props.onPriceRangeSelection?.(value as IPriceRangeFilter[]);
                break;
            case Label.BID_REQUEST_VOLUME:
                props.onBidRequestVolumeSelection?.(value as IBidRequestVolumeFilter[]);
                break;
            case DashboardFilterEnum.INVENTORY_SOURCE:
                props.onInventorySourceSelection?.(value as string[]);
                break;
            default:
                break;
        }
    };

    const renderBodyFn = (): JSX.Element => {
        return (
            <div className="apply-filter">
                <Link onClick={clearSelection} className={`${props.getFilterCount ? '' : 'link-disabled'}`}>
                    {FilterStateEnum.CLEAR}
                </Link>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    disabled={
                        props.clearSelection == undefined ||
                        props.clearSelection == FilterStateEnum.RESET ||
                        props.clearSelection == FilterStateEnum.REMOVE_FILTERS ||
                        props.clearSelection == FilterStateEnum.WARNING
                    }
                    onClick={() => {
                        props.filterDataMap?.forEach(
                            (
                                value: Array<string> | Array<IBidRequestVolumeFilter> | Array<IPriceRangeFilter>,
                                key: string,
                            ) => {
                                handleSelection(key, value);
                                if (value == undefined) {
                                    props.filterDataMap?.delete(key);
                                }
                            },
                        );
                    }}
                >
                    {FilterStateEnum.APPLY}
                </Button>
            </div>
        );
    };

    return (
        <>
            <div className="apply-filter-card">
                <Card renderBody={renderBodyFn} />
            </div>
            {!loading ? (
                <>
                    <AccordionCheckListByStore
                        title={createFormatTitle}
                        fixedItemsValue={creativeFormatFixedValue}
                        items={creativeFormatItems}
                        onItemSelection={(data) => {
                            props.setMapState(
                                (item: Map<string, Array<string>>) => new Map(item.set(createFormatTitle, data)),
                            );
                            props.setClearSelection(FilterStateEnum.SELECTED);
                        }}
                        clearSelectionState={props.clearSelection}
                        setClearSelectionState={(state: string) => {
                            props.setClearSelection(state);
                        }}
                        setSelectionMap={(item: Map<string, string>) => {
                            props.setSelectionMap(item);
                        }}
                    ></AccordionCheckListByStore>
                    <hr />
                    <AccordionCheckListByStore
                        title={DashboardFilterEnum.ENVIRONMENT}
                        items={props.dealListsView ? EnvironmentArrayForDealList : EnvironmentArray}
                        fixedItemsValue={filterData.amobeeEnvironments}
                        onItemSelection={(data) => {
                            props.setMapState(
                                (item: Map<string, Array<string>>) =>
                                    new Map(item.set(DashboardFilterEnum.ENVIRONMENT, data)),
                            );
                            props.setClearSelection(FilterStateEnum.SELECTED);
                        }}
                        clearSelectionState={props.clearSelection}
                        setClearSelectionState={(state: string) => {
                            props.setClearSelection(state);
                        }}
                        setSelectionMap={(item: Map<string, string>) => {
                            props.setSelectionMap(item);
                        }}
                    ></AccordionCheckListByStore>
                    <hr />
                    {!props.dealListsView ? (
                        <>
                            <AccordionCheckListByStore
                                title={DealDetailsColumnEnum.DEAL_TYPE}
                                fixedItemsValue={dealTypeFixedItemsValue}
                                items={dealTypeItems}
                                onItemSelection={(data) => {
                                    props.setMapState(
                                        (item: Map<string, Array<string>>) =>
                                            new Map(item.set(DealDetailsColumnEnum.DEAL_TYPE, data)),
                                    );
                                    props.setClearSelection(FilterStateEnum.SELECTED);
                                }}
                                clearSelectionState={props.clearSelection}
                                setClearSelectionState={(state: string) => {
                                    props.setClearSelection(state);
                                }}
                                setSelectionMap={(item: Map<string, string>) => {
                                    props.setSelectionMap(item);
                                }}
                            ></AccordionCheckListByStore>
                            <hr />
                            {props.singlePublisherCheck ? (
                                <></>
                            ) : (
                                <>
                                    <AccordionCheckListByStore
                                        searchString={searchString}
                                        title={AccordionFilterEnum.PUBLISHERS}
                                        items={sortedPublishersData}
                                        fixedItemsValue={sortedPublishersData}
                                        setSearchText={(text) => setSearchText(text)}
                                        onItemSelection={(data) => {
                                            props.updateSelectedPublishers?.(data);
                                            props.setMapState(
                                                (item: Map<string, Array<string>>) =>
                                                    new Map(item.set(AccordionFilterEnum.PUBLISHERS, data)),
                                            );
                                            props.setClearSelection(FilterStateEnum.SELECTED);
                                        }}
                                        clearSelectionState={props.clearSelection}
                                        setClearSelectionState={(state: string) => {
                                            props.setClearSelection(state);
                                        }}
                                        setSelectionMap={(item: Map<string, string>) => {
                                            props.setSelectionMap(item);
                                        }}
                                        publisherItems={sortedPublishersData}
                                    ></AccordionCheckListByStore>
                                    <hr />
                                </>
                            )}
                            <AccordionCheckListByStore
                                searchString={searchString}
                                title={Label.CATEGORY_FILTER_TITLE}
                                fixedItemsValue={categoryData}
                                items={categoryData}
                                setSearchText={(text) => setSearchText(text)}
                                onItemSelection={(data) => {
                                    props.updateSelectedCategories?.(data);
                                    props.setMapState(
                                        (item: Map<string, Array<string>>) =>
                                            new Map(item.set(Label.CATEGORY_FILTER_TITLE, data)),
                                    );
                                    props.setClearSelection(FilterStateEnum.SELECTED);
                                }}
                                clearSelectionState={props.clearSelection}
                                setClearSelectionState={(state: string) => {
                                    props.setClearSelection(state);
                                }}
                                setSelectionMap={(item: Map<string, string>) => {
                                    props.setSelectionMap(item);
                                }}
                                categoryItems={categoryData}
                            ></AccordionCheckListByStore>
                            <hr />
                            <AccordionCheckListByStore
                                title={AccordionFilterEnum.CPM}
                                items={filterData.priceRange}
                                fixedItemsValue={filterData.priceRange}
                                onItemSelection={(data, label: string) => cpmHandle(data, label)}
                                clearSelectionState={props.clearSelection}
                                setClearSelectionState={(state: string) => {
                                    props.setClearSelection(state);
                                }}
                                setSelectionMap={(item: Map<string, string>) => {
                                    props.setSelectionMap(item);
                                }}
                            ></AccordionCheckListByStore>
                            <hr />
                            <AccordionCheckListByStore
                                title={DashboardFilterEnum.MINIMUM_BID_REQUEST_VOLUME}
                                fixedItemsValue={filterData.bidRequestVolume}
                                items={filterData.bidRequestVolume}
                                onItemSelection={(data) => bidRequestHandle(data)}
                                clearSelectionState={props.clearSelection}
                                setClearSelectionState={(state: string) => {
                                    props.setClearSelection(state);
                                }}
                                setSelectionMap={(item: Map<string, string>) => {
                                    props.setSelectionMap(item);
                                }}
                            ></AccordionCheckListByStore>
                            <hr />
                            {props.singleInventorySourceCheck ? (
                                <></>
                            ) : (
                                <>
                                    <AccordionCheckListByStore
                                        title={DashboardFilterEnum.INVENTORY_SOURCE}
                                        items={filterData.inventorySource}
                                        fixedItemsValue={filterData.inventorySource}
                                        onItemSelection={(data) => {
                                            props.setMapState(
                                                (item: Map<string, Array<string>>) =>
                                                    new Map(item.set(DashboardFilterEnum.INVENTORY_SOURCE, data)),
                                            );
                                            props.updateSelectedInventory?.(data);
                                            props.setClearSelection(FilterStateEnum.SELECTED);
                                        }}
                                        clearSelectionState={props.clearSelection}
                                        setClearSelectionState={(state: string) => {
                                            props.setClearSelection(state);
                                        }}
                                        setSelectionMap={(item: Map<string, string>) => {
                                            props.setSelectionMap(item);
                                        }}
                                    ></AccordionCheckListByStore>
                                    <hr />
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <DashboardFilterLoader />
            )}
        </>
    );
};

DashboardFilter.propTypes = {
    selectedInventorySource: PropTypes.array.isRequired,
    selectedAmobeeEnvironment: PropTypes.array.isRequired,
    selectedAmobeeCreativeFormat: PropTypes.array.isRequired,
    selectedAmobeeDealType: PropTypes.array.isRequired,
    selectedRtlDealType: PropTypes.array.isRequired,
    selectedRtlCreativeType: PropTypes.array.isRequired,
    selectedPriceRange: PropTypes.array.isRequired,
    selectedBidRequestVolume: PropTypes.array.isRequired,
    selectedTags: PropTypes.array.isRequired,
    selectedIabCategory: PropTypes.array.isRequired,
    selectedCategory: PropTypes.array.isRequired,
    marketId: PropTypes.any.isRequired,
    singleInventorySourceCheck: PropTypes.bool,
    singlePublisherCheck: PropTypes.bool,
    onInventorySourceSelection: PropTypes.func.isRequired,
    onAmobeeEnvironmentSelection: PropTypes.func.isRequired,
    onAmobeeCreativeFormatSelection: PropTypes.func.isRequired,
    onAmobeeDealTypeSelection: PropTypes.func.isRequired,
    onRtlCreativeTypeSelection: PropTypes.func.isRequired,
    onRtlDealTypeSelection: PropTypes.func.isRequired,
    onPriceRangeSelection: PropTypes.func.isRequired,
    onBidRequestVolumeSelection: PropTypes.func.isRequired,
    onTagSelection: PropTypes.func.isRequired,
    onPublishersSelection: PropTypes.func.isRequired,
    onIabCategorySelection: PropTypes.func.isRequired,
    onCategorySelection: PropTypes.func.isRequired,
    dealListsView: PropTypes.bool,
};

export default DashboardFilter;
