import React, { useContext } from 'react';
import { Button, ButtonVariant, Flyout, FlyoutWidthSize, SkeletonLoader } from '@amobee/component-library';
import PropTypes from 'prop-types';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import './DealListsFlyout.scss';
import ExportCSVDealListsFlyout from '../ExportCSVDealsListsFlyout/ExportCSVDealListsFlyout';
import DealListsFlyoutDataTable from '../DealListsFlyoutDataTable/DealListsFlyoutDataTable';
import { IDealLists } from '../../interfaces/IDealLists';
import { IPublisherDealsResponse } from '../../interfaces/IPublisherDealsResponse';
import { Label } from '../../constants/LabelEnum';

const DealListsFlyout: React.FunctionComponent<{
    open?: boolean;
    selectedDealList?: IDealLists;
    getPublisherDeals: IPublisherDealsResponse;
    onClose?(): void;
    onAddToDealListsClick?(deals: IDealLists): void;
    setShowDealListFlyout(showDealListsFlyout: boolean): void;
    dealListsView?: boolean;
    isList?: boolean;
    isPublisherDealsLoading?: boolean;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);

    const publisherDealsData = props.getPublisherDeals?.data?.getPublisherDeals;

    const loader = (
        <div style={{ marginTop: 15 }}>
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
            <SkeletonLoader animated columnHeight={20} columnMargin={8} />
        </div>
    );

    const renderBody = () => {
        return props.isPublisherDealsLoading ? (
            loader
        ) : (
            <>
                <label className="title">NAME</label>
                <p className="deal-list-name">{props.selectedDealList?.dealListName}</p>
                <div className="export-csv">
                    <label className="title">DEALS</label>
                    <ExportCSVDealListsFlyout
                        publisherDealsData={publisherDealsData}
                        selectedDealList={props.selectedDealList}
                    />
                </div>

                <DealListsFlyoutDataTable publisherDealsData={publisherDealsData} />
            </>
        );
    };

    const renderFooter = (): JSX.Element => {
        return (
            <div className="buttonContainer">
                <Button
                    className="margin-right-10"
                    variant={ButtonVariant.NEUTRAL}
                    onClick={() => props.setShowDealListFlyout(false)}
                >
                    Cancel
                </Button>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => {
                        props.selectedDealList && props.onAddToDealListsClick?.(props.selectedDealList);
                    }}
                    disabled={marketplaceContext.account.isReadOnly || props.selectedDealList?.isBooked}
                >
                    {Label.ADD_TO_MY_DEAL_LISTS}
                </Button>
            </div>
        );
    };

    return (
        <div className="deal-lists-flyout">
            <Flyout
                dimmer
                open={props.open}
                portalRoot="body"
                size={FlyoutWidthSize.large}
                onClose={props.onClose ? props.onClose : undefined}
            >
                <Flyout.Header title="Deal List Details" />
                <Flyout.Body>{renderBody()}</Flyout.Body>
                <Flyout.Footer>{renderFooter()}</Flyout.Footer>
            </Flyout>
        </div>
    );
};

DealListsFlyout.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onAddToDealListsClick: PropTypes.func,
    setShowDealListFlyout: PropTypes.func.isRequired,
    selectedDealList: PropTypes.any,
    getPublisherDeals: PropTypes.any,
    dealListsView: PropTypes.bool,
    isList: PropTypes.bool,
    isPublisherDealsLoading: PropTypes.bool,
};

export default DealListsFlyout;
