import { IEnumType } from '../../../interfaces/IEnumType';
import { IOrderStatus } from '../../../interfaces/IOrderStatus';
import { IFiltersQuery } from '../../../interfaces/queries/IFiltersQuery';
import { IAccordionCheckListItem } from '../interfaces/IAccordionCheckListItem';

export class MarketplaceFilters {
    public dealTypes: Array<IAccordionCheckListItem> = [];
    public creativeTypes: Array<IAccordionCheckListItem> = [];
    public tags: Array<IAccordionCheckListItem> = [];
    public creativeFormats: Array<IAccordionCheckListItem> = [];
    public environments: Array<IAccordionCheckListItem> = [];
    public orderStatuses: Array<IAccordionCheckListItem> = [];
    public sellerIds: Array<IAccordionCheckListItem> = [];

    constructor(filters: IFiltersQuery | undefined) {
        if (!!filters) {
            this.dealTypes =
                filters.dealTypes?.map((dealType: IEnumType) => {
                    return { display: dealType.name, id: dealType.id };
                }) ?? [];

            this.creativeTypes =
                filters.creativeTypes?.map((creativeType: IEnumType) => {
                    return { display: creativeType.name, id: creativeType.id };
                }) ?? [];

            this.tags =
                filters.tags?.map((tag: IEnumType) => {
                    return { display: tag.name, id: tag.id };
                }) ?? [];

            this.creativeFormats =
                filters.creativeFormats?.map((format: IEnumType) => {
                    return { display: format.name, id: format.id };
                }) ?? [];

            this.environments =
                filters.environments?.map((environment: IEnumType) => {
                    return { display: environment.name, id: environment.id };
                }) ?? [];
            this.sellerIds =
                filters.marketPlaces?.map((seller: IEnumType) => {
                    return { display: seller.name, id: seller.id };
                }) ?? [];
            this.orderStatuses =
                filters.orderStatuses
                    ?.filter((orderStatus) => orderStatus.id !== 8 && orderStatus.id !== 10)
                    .map((orderStatus: IOrderStatus) => {
                        return { display: orderStatus.display, id: orderStatus.id };
                    }) ?? [];
        }
    }

    public static updateSelected(items: Array<IAccordionCheckListItem>, selectedIds: Array<number | string>): void {
        items.forEach((item) => {
            item.selected = selectedIds.indexOf(item.id) > -1;
        });
    }
}
