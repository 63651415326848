export enum DealDetailsColumnEnum {
    NAME = 'Name',
    STATUS = 'Status',
    DEAL_ID = 'Deal ID',
    FLOOR_CPM = 'Floor CPM',
    SSP = 'SSP',
    PUBLISHER = 'Publisher',
    DEAL_TYPE = 'Deal Type',
    FORMAT = 'Format',
    BID_REQUEST_VOLUME = 'Bid Request',
    AD_SIZE = 'Ad Size',
}
