import { InputVariant, Input, Icon, Select, Option, FilterToolbar, Link } from '@amobee/component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ISortType, ProductSortEnum, SortOptionLabelEnum } from '../../constants/ProductSortEnum';
import { FilterStateEnum } from '../../constants/ProductFilterEnums';
import { ISearchState } from '../../interfaces/ISearchState';
import { IStore } from '../../interfaces/IStore';
import { IBidRequestVolumeFilter, IPriceRangeFilter } from '../../interfaces/queries/IFiltersByStore';
import { ISortStatePropTypes } from '../../constants/propTypes/ISortStatePropTypes';
import { ISearchStatePropTypes } from '../../constants/propTypes/ISearchStatePropTypes';

const DashboardStoreToolbar: React.FunctionComponent<{
    searchState: ISearchState;
    sortState: ISortType;
    onSortChange(sortState: ISortType): void;
    toggleSearch(open?: boolean): void;
    toggleFilter(open?: boolean): void;
    setSearchText(text: string, open?: boolean): void;
    getFilterCount(): number;
    onSearchBlur(): void;
    isList: boolean;
    tileView: boolean;
    dealListsView: boolean;
    filterOpen: boolean;
    filterDataMap: Map<string, string[]>;
    onAmobeeCreativeFormatSelection(items: Array<string> | undefined): void;
    onRtlCreativeTypeSelection(items: Array<string> | undefined): void;
    onAmobeeEnvironmentSelection(items: Array<string> | undefined): void;
    onAmobeeDealTypeSelection(items: Array<string> | undefined): void;
    onRtlDealTypeSelection(items: Array<string> | undefined): void;
    onPublishersSelection(items: Array<string> | undefined): void;
    onCategorySelection(items: Array<string> | undefined): void;
    onPriceRangeSelection(items: Array<IPriceRangeFilter> | undefined): void;
    onBidRequestVolumeSelection(items: Array<IBidRequestVolumeFilter> | undefined): void;
    onInventorySourceSelection(items: Array<string> | undefined): void;
    setClearSelection(state: string): void;
    setMapState(state: Array<string>): void;
}> = (props) => {
    const searchState = props.searchState;
    const history = useHistory();
    const selectedStore = history.location.state as IStore;
    const isDealListsView = props.dealListsView;

    return (
        <div className="dashboard-button-container">
            <div className="dashboard-button-container-divider">
                <div className="dashboard-button-container-divider-table">
                    <Input
                        compact
                        className="show-input"
                        leftElement={<Icon name="Search" />}
                        rightElement={
                            <div onClick={() => props.toggleSearch()}>
                                <Icon name="Remove" />
                            </div>
                        }
                        showRightElement="onHover"
                        placeholder="Search by Product Name or ID"
                        variant={InputVariant.BORDERLESS}
                        onBlur={props.onSearchBlur}
                        value={searchState.text}
                        onChange={(e) => props.setSearchText(e.target.value)}
                    />
                </div>
            </div>
            {selectedStore && (selectedStore.value === '2' || selectedStore.id === 2) && !props.isList ? (
                <>
                    <div className="dashboard-button-container-divider">
                        <div className="dashboard-button-container-divider-table">
                            <div style={{ display: 'flex' }}>
                                <Icon
                                    name="ArrowDown"
                                    size={16}
                                    style={{
                                        margin: '10px -6px',
                                        marginTop: '6px',
                                    }}
                                />
                                <Icon
                                    name="ArrowUp"
                                    size={16}
                                    style={{
                                        margin: '10px 0px',
                                        marginBottom: '6px',
                                    }}
                                />
                                <Select
                                    irreversible
                                    onChange={(option: ISortType) => {
                                        props.onSortChange({
                                            label: option.label,
                                            key: option.value.split(',')[0],
                                            value: option.value.split(',')[1],
                                        });
                                    }}
                                    value={{ value: props.sortState.label ?? '' }}
                                >
                                    <Option
                                        label={SortOptionLabelEnum.NEWEST}
                                        value={ProductSortEnum.UPDATED_DATE + ',' + ProductSortEnum.DESCENDING}
                                        key={ProductSortEnum.UPDATED_DATE}
                                    />
                                    <Option
                                        label={SortOptionLabelEnum.NAME_A_TO_Z}
                                        value={ProductSortEnum.NAME + ',' + ProductSortEnum.ASCENDING}
                                        key={ProductSortEnum.NAME}
                                    />
                                    <Option
                                        label={SortOptionLabelEnum.NAME_Z_TO_A}
                                        value={ProductSortEnum.NAME + ',' + ProductSortEnum.DESCENDING}
                                        key={ProductSortEnum.NAME}
                                    />
                                    {!isDealListsView ? (
                                        <Option
                                            label={SortOptionLabelEnum.MIN_CPM_HIGH_TO_LOW}
                                            value={ProductSortEnum.PRICE + ',' + ProductSortEnum.DESCENDING}
                                            key={ProductSortEnum.PRICE}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {!isDealListsView ? (
                                        <Option
                                            label={SortOptionLabelEnum.MIN_CPM_LOW_TO_HIGH}
                                            value={ProductSortEnum.PRICE + ',' + ProductSortEnum.ASCENDING}
                                            key={ProductSortEnum.PRICE}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    <Option
                                        label={SortOptionLabelEnum.BID_REQ_VOL_HIGH_TO_LOW}
                                        value={ProductSortEnum.BID_REQUEST_VOLUME + ',' + ProductSortEnum.DESCENDING}
                                        key={ProductSortEnum.BID_REQUEST_VOLUME}
                                    />
                                    <Option
                                        label={SortOptionLabelEnum.BID_REQ_VOL_LOW_TO_HIGH}
                                        value={ProductSortEnum.BID_REQUEST_VOLUME + ',' + ProductSortEnum.ASCENDING}
                                        key={ProductSortEnum.BID_REQUEST_VOLUME}
                                    />
                                    {!isDealListsView ? (
                                        <Option
                                            label={SortOptionLabelEnum.IN_VIEW_RATE_HIGH_TO_LOW}
                                            value={ProductSortEnum.IN_VIEW_RATE + ',' + ProductSortEnum.DESCENDING}
                                            key={ProductSortEnum.IN_VIEW_RATE}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {!isDealListsView ? (
                                        <Option
                                            label={SortOptionLabelEnum.IN_VIEW_RATE_LOW_TO_HIGH}
                                            value={ProductSortEnum.IN_VIEW_RATE + ',' + ProductSortEnum.ASCENDING}
                                            key={ProductSortEnum.IN_VIEW_RATE}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
            {!props.filterOpen && props.getFilterCount() ? (
                <div className="dashboard-button-container-divider">
                    <div className="remove-filter">
                        <Link
                            onClick={() => {
                                props.onAmobeeCreativeFormatSelection(undefined);
                                props.onRtlCreativeTypeSelection(undefined);
                                props.onAmobeeEnvironmentSelection(undefined);
                                props.onAmobeeDealTypeSelection(undefined);
                                props.onRtlDealTypeSelection(undefined);
                                props.onPublishersSelection(undefined);
                                props.onCategorySelection(undefined);
                                props.onPriceRangeSelection(undefined);
                                props.onBidRequestVolumeSelection(undefined);
                                props.onInventorySourceSelection(undefined);
                                props.setClearSelection(FilterStateEnum.CLEAR);
                                props.setMapState(props.filterDataMap.clear() as unknown as string[]);
                            }}
                        >
                            {FilterStateEnum.REMOVE_FILTERS}
                        </Link>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <div className="dashboard-button-container-divider-table">
                <FilterToolbar
                    activeFilterCount={props.getFilterCount()}
                    open={false}
                    onFilterToggle={() => props.toggleFilter()}
                ></FilterToolbar>
            </div>
        </div>
    );
};

DashboardStoreToolbar.propTypes = {
    searchState: PropTypes.shape(ISearchStatePropTypes).isRequired,
    sortState: PropTypes.shape(ISortStatePropTypes).isRequired,
    onSortChange: PropTypes.func.isRequired,
    toggleSearch: PropTypes.func.isRequired,
    toggleFilter: PropTypes.func.isRequired,
    setSearchText: PropTypes.func.isRequired,
    getFilterCount: PropTypes.func.isRequired,
    onSearchBlur: PropTypes.func.isRequired,
    isList: PropTypes.bool.isRequired,
    tileView: PropTypes.bool.isRequired,
    dealListsView: PropTypes.bool.isRequired,
    filterOpen: PropTypes.bool.isRequired,
    filterDataMap: PropTypes.any.isRequired,
    onAmobeeCreativeFormatSelection: PropTypes.func.isRequired,
    onRtlCreativeTypeSelection: PropTypes.func.isRequired,
    onAmobeeEnvironmentSelection: PropTypes.func.isRequired,
    onAmobeeDealTypeSelection: PropTypes.func.isRequired,
    onRtlDealTypeSelection: PropTypes.func.isRequired,
    onPublishersSelection: PropTypes.func.isRequired,
    onCategorySelection: PropTypes.func.isRequired,
    onPriceRangeSelection: PropTypes.func.isRequired,
    onBidRequestVolumeSelection: PropTypes.func.isRequired,
    onInventorySourceSelection: PropTypes.func.isRequired,
    setClearSelection: PropTypes.func.isRequired,
    setMapState: PropTypes.func.isRequired,
};

export default DashboardStoreToolbar;
