import { Select, Option } from '@amobee/component-library';
import React from 'react';
import PropTypes from 'prop-types';
import { ILanguage, Languages } from '../../../laungages';
import { ILanguagePropTypes } from '../../../constants/propTypes/ILanguagePropTypes';

const LanguageSelect: React.FunctionComponent<{
    selectedLangauge?: ILanguage;
    onSelectedLanguage(laungauge?: ILanguage): void;
}> = (props) => {
    return (
        <Select
            irreversible
            onChange={(option) => {
                props.onSelectedLanguage(Languages.find((x) => x.id.toString() === option.value));
            }}
            value={
                props.selectedLangauge
                    ? {
                          label: props.selectedLangauge?.name ?? '',
                          value: props.selectedLangauge?.id.toString() ?? '',
                      }
                    : undefined
            }
            placeholder="Select"
        >
            {Languages.map((x) => {
                return <Option label={x.name} value={x.id.toString()} key={x.id} />;
            })}
        </Select>
    );
};

LanguageSelect.propTypes = {
    selectedLangauge: PropTypes.shape(ILanguagePropTypes),
    onSelectedLanguage: PropTypes.func.isRequired,
};

export default LanguageSelect;
