import React, { useContext } from 'react';
import { Icon } from '@amobee/component-library';
import './AccessDenied.scss';
import { MarketplaceContext } from '../../MarketplaceContext/MarketplaceContext';
import { Routes } from '../../../constants/Routes';

export const AccessDenied: React.FunctionComponent = () => {
    const marketplaceContext = useContext(MarketplaceContext);

    return (
        <div className="access-denied-block">
            <Icon style={{ textShadow: '5px 5px 5px #ccc ' }} color="#b22222" name="StatusBan" size={70} />
            <div className="icon-name">Access Denied</div>
            <div className="access-rights">
                You do not have access to this feature. Contact your Amobee Administrator for the correct permissions
            </div>
            <div className="signed-in-message">You are currently signed in as</div>
            <div>
                <span className="first-name">{marketplaceContext.account.email}</span>
            </div>
            <div className="sign-in-link">
                <a href={Routes.LOGOUT_PATH}>Sign in as a different user</a>
            </div>
        </div>
    );
};
