import { gql } from '@apollo/client';

export const PRODUCT_COMMON_FIELDS = gql`
    fragment ProductCommonFields on Product {
        id
        name
        basePrice
        description
        inventoryDescription
        imageUrl
        isBooked
        attachmentUrl
        available
        creativeApprovalRequired
        availabilityStartDate
        availabilityEndDate
        deliveryStartDate
        deliveryEndDate
        adSize
        currency {
            id
            name
            code
        }
        marketPlace {
            id
            name
        }
        demandSource {
            id
            name
        }
        productType {
            id
            name
        }
        currency {
            id
            name
            code
        }
        pricingType {
            id
            name
        }
        tags {
            id
            name
        }
        dealTypes {
            id
            name
        }
        contact {
            firstName
            lastName
            email
        }
        environments {
            id
            name
        }
        creativeFormats {
            id
            name
        }
        creativeTypes {
            id
            name
        }
    }
`;

export const GET_PRODUCTS_QUERY = gql`
    query productsByStore(
        $marketId: Int!
        $first: Int
        $after: String
        $match: String
        $sortBy: SortByEnum
        $sortOrder: SortOrderEnum
        $filters: Filters
        $storeId: Int
        $featured: Boolean
    ) {
        productsByStore(
            marketId: $marketId
            first: $first
            after: $after
            match: $match
            sortBy: $sortBy
            sortOrder: $sortOrder
            filters: $filters
            storeId: $storeId
            featured: $featured
        ) {
            totalCount
            edges {
                cursor
                node {
                    id
                    name
                    productSource
                    externalId
                    imageUrl
                    logoUrl
                    attachmentUrl
                    description
                    isBooked
                    price
                    currency {
                        id
                        name
                    }
                    negotiationType
                    amobeeDealTypes
                    rtlDealTypes
                    iabCategoryEnumId
                    tags {
                        id
                        name
                    }
                    adSize
                    inventorySourceEnumValue
                    inventoryDescription
                    creativeApprovalRequired
                    availabilityStartDate
                    availabilityEndDate
                    deliveryStartDate
                    deliveryEndDate
                    publisherName
                    rtlCreativeTypes
                    creativeTypes {
                        id
                        name
                    }
                    creativeFormats
                    environments
                    currency {
                        id
                        name
                        code
                    }
                    featured
                    bidRequestVolume
                    inViewRate
                }
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_PRODUCTS_QUERY_OLD = gql`
    ${PRODUCT_COMMON_FIELDS}
    query GetProductsQuery(
        $marketId: Int!
        $match: String
        $tagIds: [Int]
        $dealTypeIds: [Int]
        $creativeTypeIds: [Int]
        $creativeFormatIds: [Int]
        $environmentIds: [Int]
        $publisherIds: [Int]
        $size: Int
        $page: Int
    ) {
        products(
            marketId: $marketId
            match: $match
            tagIds: $tagIds
            dealTypeIds: $dealTypeIds
            creativeTypeIds: $creativeTypeIds
            creativeFormatIds: $creativeFormatIds
            environmentIds: $environmentIds
            publisherIds: $publisherIds
            size: $size
            page: $page
            sortBy: "-id"
        ) {
            totalCount
            edges {
                cursor
                node {
                    ...ProductCommonFields
                }
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_PRODUCT_QUERY = gql`
    ${PRODUCT_COMMON_FIELDS}
    query GetProductQuery($marketId: Int!, $productId: Int!) {
        product(marketId: $marketId, id: $productId) {
            ...ProductCommonFields
        }
    }
`;

export const GET_MARKETS = gql`
    query GetMarkets {
        markets {
            id
            name
            currencyCode
        }
    }
`;

export const GET_CURRENCIES = gql`
    query GetCurrencies {
        currencies {
            id
            name
            code
            symbol
            exchangeRateUSD
        }
    }
`;

export const GET_FILTERS = gql`
    query GetFilters($marketId: Int!) {
        dealTypes(marketId: $marketId) {
            id
            name
        }
        creativeTypes(marketId: $marketId) {
            id
            name
        }
        tags(marketId: $marketId) {
            id
            name
        }
        creativeFormats(marketId: $marketId) {
            id
            name
        }
        environments(marketId: $marketId) {
            id
            name
        }
        marketPlaces(marketId: $marketId) {
            id
            name
        }
        orderStatuses {
            id
            name
            display
            description
        }
    }
`;

export const GET_FILTERS_BY_STORE = gql`
    query filters($storeId: Int!, $marketId: Int!, $match: String, $featured: Boolean, $filters: Filters) {
        filtersForDealList(marketId: $marketId, storeId: $storeId, featured: $featured, filters: $filters) {
            amobeeEnvironmentFilters {
                label
                environment
            }
            amobeeCreativeFormatFilters {
                label
                creativeFormatEnum
            }
        }
        filtersByStore(marketId: $marketId, storeId: $storeId, featured: $featured, filters: $filters) {
            inventorySourceFilters {
                label
            }

            amobeeEnvironmentFilters {
                label
                environment
            }

            amobeeCreativeFormatFilters {
                label
                creativeFormatEnum
            }

            amobeeDealTypeFilters {
                label
                amobeeDealType
            }

            rtlCreativeTypeFilters {
                label
                creativeTypeEnum
            }
            rtlDealTypeFilters {
                label
                rtlDealType
            }

            priceRangeFilters {
                label
                priceRange
            }

            bidRequestVolumeFilters {
                label
                bidRequestVolume
            }

            negotiationTypeFilters {
                label
                negotiationType
            }
        }
        tagsByStore(storeId: $storeId, marketId: $marketId, match: $match) {
            totalCount
            edges {
                cursor
                node
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
        publishersByStore(storeId: $storeId, marketId: $marketId, match: $match) {
            totalCount
            edges {
                cursor
                node
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
        iabCategoryByStore(storeId: $storeId, marketId: $marketId, match: $match) {
            totalCount
            edges {
                cursor
                node
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_ORDERS = gql`
    query GetOrders(
        $marketId: Int!
        $statuses: [Int]
        $tagIds: [Int]
        $creativeTypeIds: [Int]
        $environmentIds: [Int]
        $creativeFormatIds: [Int]
        $match: String
        $marketPlaceIds: [Int]
        $sortBy: String
        $size: Int
        $page: Int
    ) {
        orders(
            marketId: $marketId
            name: ""
            statuses: $statuses
            marketPlaceIds: $marketPlaceIds
            tagIds: $tagIds
            creativeTypeIds: $creativeTypeIds
            environmentIds: $environmentIds
            creativeFormatIds: $creativeFormatIds
            match: $match
            sortBy: $sortBy
            size: $size
            page: $page
        ) {
            totalCount
            edges {
                cursor
                node {
                    id
                    name
                    status {
                        name
                        id
                    }
                    marketPlace {
                        name
                        id
                    }
                    startDate
                    endDate
                    product {
                        id
                    }
                    dealType {
                        id
                        name
                    }
                    environments {
                        id
                        name
                    }
                    creativeFormats {
                        id
                        name
                    }
                    creativeTypes {
                        id
                        name
                    }
                    pricingType {
                        id
                        name
                    }
                    tags {
                        id
                        name
                    }
                    dealId
                    price
                    budget
                    currency {
                        id
                        name
                        code
                    }
                    publisherDeal {
                        id
                    }
                    creativeApprovals {
                        id
                        name
                        externalId
                        creativeRejectionComment
                        approvalStatus {
                            id
                            name
                        }
                    }
                    creativeApprovalRequired
                }
            }
        }
    }
`;

export const GET_MARKET = gql`
    query GetMarket($marketId: Int!) {
        market(id: $marketId) {
            id
            name
            currencyCode
        }
    }
`;

export const GET_STORES = gql`
    query GetStores($marketId: Int) {
        stores(marketId: $marketId) {
            id
            name
            description
        }
    }
`;

export const GET_DEAL_LISTS = gql`
    query GetDealList(
        $marketId: Int!
        $pageNumber: Int
        $pageSize: Int
        $name: String
        $featured: Boolean
        $sortBy: SortByEnum
        $sortOrder: SortOrderEnum
        $filters: Filters
    ) {
        dealList(
            marketId: $marketId
            pageNumber: $pageNumber
            pageSize: $pageSize
            name: $name
            featured: $featured
            sortBy: $sortBy
            sortOrder: $sortOrder
            filters: $filters
        ) {
            totalCount
            edges {
                cursor
                node {
                    id
                    dealListId
                    dealListName
                    dealIds
                    cardUrl
                    count
                    isFeatured
                    isBooked
                }
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_PUBLISHER_DEALS = gql`
    query GetPublisherDeals($externalDealIds: [String!]) {
        getPublisherDeals(externalDealIds: $externalDealIds) {
            id
            dealName
            workflowStatus
            dealId
            price
            resourceType
            publisherName
            dealType
            mediaFormat
            inventorySourceId
            bidRequestVolume
            adSize
        }
    }
`;

export const GET_USER_PREFERENCE = gql`
    query GetUserPreference($externalId: String!) {
        getUserPreference(externalId: $externalId)
    }
`;
export const GET_SINGLE_PUBLISHER_BY_STORE = gql`
    query SinglePublisherByStore($marketId: Int!, $storeId: Int) {
        singlePublisherByStore(marketId: $marketId, storeId: $storeId)
    }
`;

export const GET_SINGLE_INVENTORY_SOURCE_BY_STORE = gql`
    query SingleInventorySourceByStore($marketId: Int!, $storeId: Int) {
        singleInventorySourceByStore(marketId: $marketId, storeId: $storeId)
    }
`;

export const GET_DSP_CREATIVES_BY_ID = gql`
    query GetDspCreativesById($marketId: Int!, $amobeeIds: [Int!]!) {
        dspCreativesById(marketId: $marketId, amobeeIds: $amobeeIds) {
            amobeeId
            name
            previewUrl
            advertiser {
                id
                name
            }
        }
    }
`;

export const GET_DSP_CREATIVES_BY_MARKET = gql`
    query GetDspCreativesByMarket(
        $marketId: Int!
        $interactiveTvLayout: InteractiveTvLayout!
        $advertiserIds: [Int]
        $match: String
    ) {
        dspCreatives(
            marketId: $marketId
            interactiveTvLayout: $interactiveTvLayout
            advertiserIds: $advertiserIds
            match: $match
        ) {
            totalCount
            edges {
                cursor
                node {
                    amobeeId
                    name
                    previewUrl
                    advertiser {
                        name
                        id
                    }
                }
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export const CREATE_ORDER_MUTATION = gql`
    mutation CreateOrderMutation(
        $marketId: Int!
        $productId: String!
        $advertiserId: String
        $productInternalId: Int!
    ) {
        createOrder(
            input: {
                marketId: $marketId
                productId: $productId
                advertiserId: $advertiserId
                productInternalId: $productInternalId
            }
        ) {
            id
            publisherDeal {
                id
                marketId
                dealId
            }
            existingDeal
        }
    }
`;

export const CREATE_BULK_ORDER_MUTATION = gql`
    mutation CreateBulkOrderForAPDMutation($marketId: Int!, $productInternalId: [Int!]) {
        createBulkOrderForAPD(input: { marketId: $marketId, productInternalId: $productInternalId }) {
            id
            publisherDeal {
                id
                marketId
                dealId
            }
            existingDeal
        }
    }
`;

export const ADD_DEAL_LIST_TO_MARKET = gql`
    mutation addDeaListToMarket($marketId: Int!, $dealListId: Int!) {
        addDeaListToMarket(input: { marketId: $marketId, dealListId: $dealListId }) {
            dealListDTO {
                dealListId
                dealListName
            }
        }
    }
`;

export const CREATE_BULK_ORDER_FOR_PUBLISHER_DEAL = gql`
    mutation CreateBulkOrderForPublisherDealMutation($marketId: Int!, $publisherDeals: [PublisherDealDTOForBooking]) {
        createBulkOrderForPublisherDeal(input: { marketId: $marketId, publisherDeals: $publisherDeals }) {
            id
            publisherDeal {
                id
                marketId
                dealId
            }
            existingDeal
        }
    }
`;

export const SAVE_USER_PREFERENCE_MUTATION = gql`
    mutation SaveUserPreferenceMutation($preference: String) {
        saveUserPreference(input: $preference)
    }
`;

export const GET_ADVERTISERS = gql`
    query GetAdvertisers($marketId: Int!) {
        advertisers(marketId: $marketId) {
            id
            name
        }
    }
`;

export const SEND_PRODUCTS_REQUEST_MUTATION = gql`
    mutation SendProductsRequestMutation(
        $contact: ContactRequestForm!
        $dealType: DealTypeInput
        $name: String
        $productId: Int!
        $marketId: Int!
        $agency: String
        $budget: Float
        $additionalSpecifications: String
        $startDate: String
        $endDate: String
    ) {
        createNegotiableOrder(
            input: {
                contact: $contact
                dealType: $dealType
                name: $name
                productId: $productId
                marketId: $marketId
                agency: $agency
                budget: $budget
                additionalSpecifications: $additionalSpecifications
                startDate: $startDate
                endDate: $endDate
            }
        ) {
            id
            name
        }
    }
`;

export const SEND_ACCEPT_ORDER = gql`
    mutation SendAcceptOrder($marketId: Int!, $orderId: Int!, $advertiserId: [String]) {
        acceptOrder(input: { marketId: $marketId, orderId: $orderId, advertiserId: $advertiserId }) {
            id
            name
            publisherDeal {
                id
                marketId
                dealId
            }
        }
    }
`;

export const SEND_DECLINE_ORDER = gql`
    mutation SendDeclineOrder($marketId: Int!, $orderId: Int!) {
        declineOrder(input: { marketId: $marketId, orderId: $orderId }) {
            id
            name
        }
    }
`;

export const SUBMIT_CREATIVES = gql`
    mutation SubmitCreatives($marketId: Int!, $orderId: String!, $creativeIds: [String!]) {
        submitCreatives(input: { marketId: $marketId, orderId: $orderId, creativeIds: $creativeIds })
    }
`;

export const SINGLE_FILE_UPLOAD = gql`
    mutation SingleFileUpload($file: Upload!, $marketId: Int!) {
        singleUpload(file: $file, marketId: $marketId) {
            marketId
            filename
            creativeAssetKey
            adSizeId
            resourceType
        }
    }
`;

export const GET_ACCOUNT = gql`
    query GetAccount($id: String) {
        account(id: $id) {
            id
            email
            firstName
            lastName
            resourceType
            isReadOnly
            authorities
        }
    }
`;

export const GET_IAB_CATEGORIES = gql`
    query GetIabCategories($marketId: Int!) {
        iabCategories(marketId: $marketId) {
            id
            name
            level
            parentId
            desc
            apiValueName
        }
    }
`;

export const GET_THIRD_PARTY_DATA = gql`
    query GetThirdPartyData($marketId: Int!) {
        thirdPartyPlatforms(marketId: $marketId) {
            tpaPlatformId
            adXId
            apiValueName
            description
            domainIdentifiers
        }
        thirdPartyEvents(marketId: $marketId) {
            engagementEventEnumId
            apiValueName
            description
            engagementEventEnumVisible
            interactiveTvVisible
            vastElement
        }
    }
`;

export const CREATE_CREATIVE = gql`
    mutation CreateCreative(
        $marketId: Int!
        $advertiserId: String!
        $name: String!
        $adFormat: String!
        $mediaFormat: String!
        $language: String
        $iabCategory: String
        $amobeeAdServer: AmobeeAdServer!
        $interactiveTvLayout: String!
        $interactiveTvDuration: String!
        $thirdPartyTrackings: [ThirdPartyTracking]
        $externalThirdPartyAdServerId: String
    ) {
        createCreative(
            input: {
                marketId: $marketId
                advertiserId: $advertiserId
                name: $name
                adFormat: $adFormat
                mediaFormat: $mediaFormat
                language: $language
                iabCategory: $iabCategory
                amobeeAdServer: $amobeeAdServer
                interactiveTvLayout: $interactiveTvLayout
                interactiveTvDuration: $interactiveTvDuration
                thirdPartyTrackings: $thirdPartyTrackings
                externalThirdPartyAdServerId: $externalThirdPartyAdServerId
            }
        ) {
            amobeeId
            name
            previewUrl
            advertiser {
                name
                id
            }
        }
    }
`;
