import { Button, Modal, ButtonVariant, Spinner, Typeahead } from '@amobee/component-library';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import './BookOrderModal.scss';
import { IOrder } from '../../../interfaces/IOrder';
import { OrderRedirects } from '../../OrdersGrid/OrdersGrid';
import { useQuery } from '@apollo/client';
import { GET_ADVERTISERS } from '../../../graphQL/queries';
import { IAdvertisersQuery } from '../../../interfaces/queries/IAdvertisersQuery';
import { IAdvertiser } from '../../../interfaces/IAdvertiser';
import { MarketplaceContext } from '../../MarketplaceContext/MarketplaceContext';

const BookOrderModal: React.FunctionComponent<{
    order: IOrder;
    open?: boolean;
    onClose?(): void;
    onBookClick?(order: IOrder, redirect: OrderRedirects, advertiserId?: string): void;
}> = (props) => {
    const close = () => {
        return props.onClose?.();
    };

    const [advertiser, setAdvertiser] = useState<string | undefined>(undefined);
    const [result, setResult] = useState<Array<IAdvertiser>>([]);
    const [filteredData, setFilteredData] = useState<Array<IAdvertiser>>([]);
    const [item, setItem] = useState<Array<IAdvertiser>>([]);
    const isPgDeals = props.order.dealType.id == '7'; /* PG Deals */
    const marketplaceContext = useContext(MarketplaceContext);

    const { error, data, loading } = useQuery<IAdvertisersQuery>(GET_ADVERTISERS, {
        variables: {
            marketId: marketplaceContext.marketId,
        },
        onCompleted: () => {
            if (data) {
                setResult(data.advertisers);
                setFilteredData(data.advertisers);
            }
        },
    });

    const queryChange = (event: string) => {
        {
            if (event.length == 0) {
                setFilteredData(result);
            } else {
                setFilteredData(result?.filter((x) => x.name.toLowerCase().includes(event.toLowerCase())));
            }
        }
    };

    if (error) {
        return <>Error</>;
    }

    if (loading) {
        return (
            <div className="loading light">
                <Spinner showText size="large" />
            </div>
        );
    }

    return (
        <Modal onToggle={close} open={props.open} closeButton={true}>
            <Modal.Header>
                {isPgDeals ? 'Select Advertiser to Complete Order Booking' : 'Confirm Order Booking'}
            </Modal.Header>
            <Modal.Body>
                {isPgDeals ? (
                    <>
                        <br />
                        <>
                            Please confirm that you would like to accept the terms of your order, {props.order.name},
                            and select the advertiser that will use this deal.
                        </>
                        <br />
                        <br />

                        <>
                            Once you have made your selection, you can <b>Book & Create</b> a new line item with this
                            deal now, or <b>Book</b> only and create a line item later from the My Deals page. Your deal
                            will not be booked until you select an advertiser and choose one of these Book options.
                        </>
                        <br />
                        <br />
                        <Typeahead
                            placeholder="Select Advertiser"
                            resultNameAccessor="name"
                            uniqueIdAccessor="id"
                            onQueryChange={(query: string) => queryChange(query)}
                            onSelectionChange={(item: Array<IAdvertiser>) => {
                                setItem(item);
                                if (item && item.length > 0) {
                                    setAdvertiser(item[0].id.toString());
                                }
                            }}
                            selected={item}
                            noResultsText="No match found"
                            results={filteredData}
                            onResetClick={() => {
                                queryChange('');
                            }}
                        ></Typeahead>
                    </>
                ) : (
                    <>
                        <br />
                        <>Please confirm that you would like to accept the terms of your order, {props.order.name}.</>
                        <br />
                        <br />
                        <>
                            You can <b>Book & Assign</b> this deal to line item(s) and package(s), or <b>Book</b> only
                            and assign the deal later from the My Deals page. Your deal will not be booked until you
                            choose one of these Book options.
                        </>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close}>Cancel</Button>

                {isPgDeals ? (
                    <>
                        <Button
                            onClick={() => props.onBookClick?.(props.order, 'none', advertiser)}
                            disabled={advertiser == undefined}
                        >
                            Book
                        </Button>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => props.onBookClick?.(props.order, 'create', advertiser)}
                            disabled={advertiser == undefined}
                        >
                            Book & Create
                        </Button>
                    </>
                ) : (
                    <>
                        <Button onClick={() => props.onBookClick?.(props.order, 'none', advertiser)}>Book</Button>
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => props.onBookClick?.(props.order, 'assignment', advertiser)}
                        >
                            Book & Assign
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

BookOrderModal.propTypes = {
    order: PropTypes.any.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onBookClick: PropTypes.func,
};

export default BookOrderModal;
