export enum AmobeeDealTypeEnum {
    UNRESERVED_FIXED = 'Unreserved Fixed',
    PRIVATE_AUCTION = 'Private Auction',
    PROGRAMMATIC_GUARENTEED = 'Programmatic Guaranteed',
}

export enum RtlDealTypeEnum {
    DIRECT_DEAL = 'Direct Deal',
    DIRECT_GAURANTEED_DEAL = 'Direct Guaranteed Deal',
    PRIVATE_AUCTION_2ND = '2nd Price Private Auction',
    PRIVATE_AUCTION_1ST = '1st Price Private Auction',
    PUBLIC_AUCTION_2ND = '2nd Price Public Auction',
    PUBLIC_AUCTION_1ST = '1st Price Public Auction',
    PROGRAMMATIC_GUARANTEED_DEAL = 'Programmatic Guaranteed Deal',
}

export enum AmobeeEnvironmentEnum {
    INTERACTIVE_TV = 'Interactive TV',
    DESKTOP_WEB = 'Desktop Web',
    MOBILE_WEB = 'Mobile Web',
    MOBILE_APP = 'Mobile App',
    CTV_APP = 'CTV App',
    AMOBEE_UNKNOWN = 'Amobee Unknown',
}

export enum NegotiationType {
    ALWAYS_ON = 'ALWAYS_ON',
    NEGOTIABLE = 'NEGOTIABLE',
}

export enum ProductSourceEnum {
    RTL = 'RTL',
    AMOBEE_PREFERRED = 'Amobee Preferred',
}

export enum AccordionFilterEnum {
    PUBLISHERS = 'Publishers',
    IAB_CATEGORY = 'IAB Category',
    CPM = 'CPM',
}

export enum AmobeeCreativeFormatEnum {
    DISPLAY = 'Display',
    VIDEO = 'Video',
    AUDIO = 'Audio',
    AMOBEE_UNKNOWN = 'Amobee Unknown',
}

export enum DashboardFilterEnum {
    FORMAT = 'Format',
    CREATIVE_TYPES = 'Creative Types',
    ENVIRONMENT = 'Environment',
    MINIMUM_BID_REQUEST_VOLUME = 'Minimum Bid request Volume',
    INVENTORY_SOURCE = 'Inventory Source',
    MIN = 'min',
    MAX = 'max',
}

export enum BidRequestValue {
    ZERO_VALUE = 0,
    UNDER_ONE_MIL = 999999,
    ONE_MIL = 1000000,
    TEN_MIL = 10000000,
    HUNDRED_MIL = 100000000,
    ONE_BIL = 1000000000,
    TEN_BIL = 10000000000,
    HUNDRED_BIL = 100000000000,
}

export enum FilterStateEnum {
    CLEAR = 'Clear',
    RESET = 'Reset',
    SELECTED = 'Selected',
    UNSELECTED = 'Unselected',
    APPLY = 'Apply',
    REMOVE_FILTERS = 'Remove All Filters',
    WARNING = 'Warning',
}
