import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonVariant } from '@amobee/component-library';
import './DashboardTileView.scss';
import { IProduct } from '../../interfaces/IProduct';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { AmobeeEnvironmentEnum, NegotiationType } from '../../constants/ProductFilterEnums';
import { DealsColumnHeadersEnum } from '../../constants/ColumnHeadersEnum';
import { Label } from '../../constants/LabelEnum';

const DashboardTileView: React.FunctionComponent<{
    data: IProduct;
    showRequest?: boolean;
    publisherValueCheck?: boolean;
    inventorySourceCheck?: boolean;
    onClick?(): void;
    onBookClick?(product: IProduct): void;
    onRequestClick?(product: IProduct): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);
    const showBookButton = props.data.negotiationType === NegotiationType.ALWAYS_ON;

    const button = !showBookButton ? (
        <Button
            variant={ButtonVariant.STATIC_BORDERLESS}
            onClick={() => {
                props.onRequestClick?.(props.data);
            }}
            disabled={marketplaceContext.account.isReadOnly}
        >
            {Label.REQUEST}
        </Button>
    ) : (
        <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
                props.onBookClick?.(props.data);
            }}
            title={props.data.isBooked ? Label.DEAL_IS_ALREADY_BOOKED : ''}
            disabled={marketplaceContext.account.isReadOnly || props.data.isBooked}
        >
            {Label.ADD_TO_MY_DEALS}
        </Button>
    );

    return (
        <div className="main-container">
            <div className="tile-container" onClick={props.onClick}>
                <img
                    className="card-header-image"
                    src={props.data.imageUrl ? props.data.imageUrl : '/images/no_pic.svg'}
                />

                <div className="content">
                    <div className="card-body-table">
                        <table width="92%">
                            <tbody>
                                <tr>
                                    <td width="26%">{DealsColumnHeadersEnum.DEAL_NAME}: </td>
                                    <td>{`${props.data.name}`}</td>
                                </tr>
                                <tr>
                                    <td>{DealsColumnHeadersEnum.FORMAT}: </td>
                                    <td>{`${props.data.creativeFormats}`}</td>
                                </tr>
                                <tr>
                                    <td>{DealsColumnHeadersEnum.ENVIRONMENT}: </td>
                                    <td>
                                        {props.data.environments
                                            ?.map(
                                                (env) =>
                                                    AmobeeEnvironmentEnum[
                                                        env as unknown as keyof typeof AmobeeEnvironmentEnum
                                                    ],
                                            )
                                            .join(', ') ?? ''}
                                    </td>
                                </tr>
                                <tr>
                                    {!props.inventorySourceCheck ? (
                                        <>
                                            <td>{DealsColumnHeadersEnum.INVENTORY_SOURCE}: </td>
                                            <td>
                                                {props.data.inventorySourceEnumValue
                                                    ? props.data.inventorySourceEnumValue
                                                    : Label.NONE}
                                            </td>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </tr>
                                <tr>
                                    <td>{DealsColumnHeadersEnum.RUN}: </td>
                                    <td>{props.data.tags?.map((tag) => tag.name).join(', ') ?? ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        {props.data.logoUrl && (
                            <img
                                src={props.data.logoUrl}
                                className="logo"
                                onError={(e) => (e.currentTarget.style.display = 'none')}
                            />
                        )}
                        <span className="footer-details">
                            <span>{`${props.data.currency.code} ${props.data.price}`}</span>
                            <span className="cpm"> CPM</span>
                            <span className="add-button">{button}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

DashboardTileView.propTypes = {
    data: PropTypes.any.isRequired,
    showRequest: PropTypes.bool,
    publisherValueCheck: PropTypes.bool,
    inventorySourceCheck: PropTypes.bool,
    onClick: PropTypes.func,
    onBookClick: PropTypes.func,
    onRequestClick: PropTypes.func,
};

export default DashboardTileView;
