import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Checkbox } from '@amobee/component-library';
import { IAccordionCheckListItem } from '../interfaces/IAccordionCheckListItem';
import { IAccordionCheckListItemPropTypes } from '../../../constants/propTypes/IAccordionCheckListItemPropTypes';

export const AccordionCheckList: React.FunctionComponent<{
    open?: boolean;
    title?: string;
    items: Array<IAccordionCheckListItem>;
    onItemSelection?(selectedItems: Array<number>): void;
}> = (props) => {
    const toggleSelect = (id: string | number) => {
        const newItemArray = props.items.slice();
        const loc = newItemArray.findIndex((item) => item.id === id);

        newItemArray[loc].selected = !(newItemArray[loc].selected ?? false);

        if (props.onItemSelection) props.onItemSelection(newItemArray.filter((x) => x.selected).map((x) => x.id));
    };

    return (
        <Accordion
            open={props.open ?? false}
            title={props.title ?? ''}
            summaryItems={props.items
                .filter((item) => item.selected === true)
                .map((item) => {
                    return { label: item.display };
                })}
        >
            {props.items.map((item) => {
                return (
                    <Checkbox
                        key={item.id}
                        label={item.display}
                        checked={item.selected ?? false}
                        onChange={() => {
                            toggleSelect(item.id);
                        }}
                    />
                );
            })}
        </Accordion>
    );
};

AccordionCheckList.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact(IAccordionCheckListItemPropTypes).isRequired).isRequired,
    onItemSelection: PropTypes.func,
};

export default AccordionCheckList;
