export enum Publishers {
    PEACOCK = 'Peacock',
    HBO_MAX = 'HBO Max',
    SPOTIFY = 'Spotify',
    PANDORA = 'Pandora',
    DISCOVERY_PLUS = 'Discovery+',
    VIACOMCBS = 'ViacomCBS',
    DIRECTV = 'DirecTV',
    SLING = 'Sling',
    PARAMOUNT = 'Paramount',
    NBCU = 'NBCU',
}
