import React from 'react';

import './App.scss';

import { UseOktaLogin } from './security/UseOktaLogin';
import { UsePortalLogin } from './security/UsePortalLogin';

const App: React.FunctionComponent = () => {
    const useOkta = process.env.REACT_APP_USE_OKTA === 'true';

    if (useOkta) {
        return <UseOktaLogin />;
    } else {
        return <UsePortalLogin />;
    }
};

export default App;
