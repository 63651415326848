import {
    BannerMessage,
    BannerMessageVariant,
    Button,
    ButtonVariant,
    Flyout,
    Icon,
    FlyoutWidthSize,
    InlineChipContainer,
    Popover,
    BasicMenu,
    Chip,
} from '@amobee/component-library';
import React, { useContext } from 'react';
import { IProduct } from '../../interfaces/IProduct';
import './ProductDetailsFlyout.scss';
import PropTypes from 'prop-types';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { format } from 'date-fns';
import { useQuery } from '@apollo/client';
import { GET_CURRENCIES, GET_MARKET } from '../../graphQL/queries';
import { ICurrenciesQuery } from '../../interfaces/queries/ICurrenciesQuery';
import { Label } from '../../constants/LabelEnum';
import {
    AmobeeCreativeFormatEnum,
    AmobeeDealTypeEnum,
    AmobeeEnvironmentEnum,
    NegotiationType,
} from '../../constants/ProductFilterEnums';
import { IProductPropTypes } from '../../constants/propTypes/IProductPropTypes';

const ProductDetailsFlyout: React.FunctionComponent<{
    open?: boolean;
    hidebackButtonfn?(): void;
    onClose?(): void;
    product: IProduct;
    errorText?: string;
    onBookClick?(product: IProduct): void;
    onRequestClick?(product: IProduct): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);
    const marketLoader = useQuery(GET_MARKET, {
        variables: {
            marketId: marketplaceContext.marketId,
        },
    });
    const currenciesResponse = useQuery<ICurrenciesQuery>(GET_CURRENCIES);

    const approxCurrency = (productPrice: number, productCurrencyCode: string) => {
        const marketCurrencyCode = marketLoader.data?.market.currencyCode;
        let convertedCurrency, productCurrencyExchangeRate, marketCurrencyExchangeRate;
        if (productCurrencyCode !== marketCurrencyCode) {
            currenciesResponse.data?.currencies.map((item) => {
                if (item.code === marketCurrencyCode) {
                    marketCurrencyExchangeRate = item?.exchangeRateUSD;
                }
                if (item.code === productCurrencyCode) {
                    productCurrencyExchangeRate = item?.exchangeRateUSD;
                }
            });

            if (productCurrencyExchangeRate && marketCurrencyExchangeRate) {
                if (marketCurrencyCode === Label.USD_LABEL) {
                    convertedCurrency = (productPrice / productCurrencyExchangeRate).toFixed(2);
                } else {
                    const convertToUSD = productPrice / productCurrencyExchangeRate;
                    convertedCurrency = (convertToUSD * marketCurrencyExchangeRate).toFixed(2);
                }
            }
        }

        return (
            <>
                {convertedCurrency ? (
                    <>
                        <> </> (≈ {convertedCurrency} {marketCurrencyCode}) <> </>
                        <Popover openOn="hover" renderTrigger={() => <Icon name="Info" />}>
                            Conversion rates fluctuate. The actual amount charged for the inventory is determined at
                            impression time.
                        </Popover>
                    </>
                ) : (
                    ''
                )}
            </>
        );
    };

    const renderBody = () => {
        const product = props.product;

        return (
            <div className="flyout">
                <img src={product.imageUrl ? product.imageUrl : '/images/no_pic.svg'} style={{ width: '100%' }} />
                <div className="title">{product.name}</div>
                <div className="subTitle">{product.description}</div>
                <hr />
                <div className="title">Product Details</div>
                <div className="label">Publisher</div>
                <div className="text">{product.publisherName}</div>
                {product && product.productSource === Label.AMOBEE_PREFERRED ? (
                    <>
                        <div className="label">Inventory Source</div>
                        <div className="text">
                            {product.inventorySourceEnumValue ? product.inventorySourceEnumValue : 'None'}
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className="label">Deal Type</div>
                <div className="text">
                    {product.amobeeDealTypes
                        ?.map((x) => AmobeeDealTypeEnum[x as unknown as keyof typeof AmobeeDealTypeEnum])
                        .join(', ') ?? ''}
                </div>
                <div className="label">Formats</div>
                <div className="text">
                    {product.creativeFormats
                        ?.map((x) => AmobeeCreativeFormatEnum[x as unknown as keyof typeof AmobeeCreativeFormatEnum])
                        .join(', ') ?? ''}
                </div>
                <div className="label">Environments</div>
                <div className="text">
                    {product.environments
                        ?.map((x) => AmobeeEnvironmentEnum[x as unknown as keyof typeof AmobeeEnvironmentEnum])
                        .join(', ') ?? ''}
                </div>
                {product && product.inventoryDescription && product.inventoryDescription.length ? (
                    <>
                        <div className="label">Inventory Description</div>
                        <div className="text">{product.inventoryDescription}</div>
                    </>
                ) : (
                    <></>
                )}
                {product.attachmentUrl && product.attachmentUrl.length ? (
                    <>
                        <div className="label">Additional Resources</div>
                        <div className="text ellipsis">
                            <a
                                href={product.attachmentUrl}
                                target="_blank"
                                rel="noreferrer"
                                title={product.attachmentUrl}
                            >
                                {product.attachmentUrl}
                            </a>
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className="label">Minimum CPM</div>
                <div className="text">
                    {`${product.price} ${product.currency.code}`}
                    {approxCurrency(product.price, product.currency.code)}
                </div>
                {product && product.productSource === Label.AMOBEE_PREFERRED ? (
                    <>
                        <div className="label">Bid Requests - Last 7 Days</div>
                        <div className="text">
                            {!product.bidRequestVolume
                                ? Label.NO_DATA
                                : product.bidRequestVolume.toLocaleString('en-US')}
                        </div>
                        <div className="label">In View Rate</div>
                        <div className="text">
                            {!product.inViewRate ? (
                                Label.ZERO_PERCENT
                            ) : (
                                <>{Math.round(product.inViewRate * 100)}&#37;</>
                            )}
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <div className="label">Flight Dates</div>
                <div className="text">
                    {product.deliveryStartDate.length
                        ? `${format(new Date(product.deliveryStartDate), 'dd MMM yyyy')} `
                        : '* '}
                    -
                    {product.deliveryEndDate.length
                        ? `${format(new Date(product.deliveryEndDate), 'dd MMM yyyy')}`
                        : ' *'}
                </div>
                {product.availabilityEndDate ? (
                    <>
                        <div className="label">Availability End Date</div>
                        <div className="text">{`${format(new Date(product.availabilityEndDate), 'dd MMM yyyy')}`}</div>
                    </>
                ) : (
                    <></>
                )}
                {product && product.productSource === Label.AMOBEE_PREFERRED ? (
                    <>
                        <div className="label">IAB Category</div>
                        <div className="text">{product.iabCategoryEnumId ? product.iabCategoryEnumId : 'None'}</div>
                    </>
                ) : (
                    <></>
                )}
                <div className="label">Tags</div>
                <div className="tags-list">
                    <InlineChipContainer
                        groups={[
                            {
                                chips:
                                    product.tags.map((chip) => {
                                        return { text: chip.name, closable: false };
                                    }) ?? [],
                            },
                        ]}
                    />

                    {props.product.tags.length > 1 ? (
                        <div className="tags-popover">
                            <Popover
                                openOn="hover"
                                renderTrigger={() => (
                                    <Chip closable={false} text={`${props.product.tags.length} Selections`}></Chip>
                                )}
                            >
                                <div>
                                    {props.product.tags.slice(0, props.product.tags.length).map((tag) => {
                                        return (
                                            <BasicMenu key={tag.id}>
                                                <BasicMenu.Item label={tag.name} />
                                            </BasicMenu>
                                        );
                                    })}
                                </div>
                            </Popover>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {product && product.productSource === Label.AMOBEE_PREFERRED ? (
                    <>
                        <div className="label">Ad Size</div>
                        <div className="text">{product.adSize ?? Label.NO_DATA}</div>
                    </>
                ) : (
                    <></>
                )}
                <hr />
            </div>
        );
    };

    const renderFooter = (): JSX.Element => {
        return (
            <>
                <div className="errorText">
                    {props.errorText ? (
                        <Popover
                            openOn="hover"
                            placement="top"
                            renderTrigger={() => (
                                <BannerMessage variant={BannerMessageVariant.ERROR}>{props.errorText}</BannerMessage>
                            )}
                        >
                            <div className="popover-body">
                                <div>
                                    <div className="dotred" />
                                </div>
                                <div>{props.errorText}</div>
                            </div>
                        </Popover>
                    ) : undefined}
                </div>
                <div className="buttonContainer">
                    {props.product.negotiationType == NegotiationType.ALWAYS_ON ? (
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => {
                                props.onBookClick?.(props.product);
                            }}
                            title={props.product.isBooked ? Label.DEAL_IS_ALREADY_BOOKED : ''}
                            disabled={marketplaceContext.account.isReadOnly || props.product.isBooked}
                        >
                            {props.product.productSource === Label.AMOBEE_PREFERRED
                                ? Label.ADD_TO_MY_DEALS
                                : Label.BOOK}
                        </Button>
                    ) : (
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            onClick={() => {
                                props.onRequestClick?.(props.product);
                                props.hidebackButtonfn?.();
                            }}
                            disabled={marketplaceContext.account.isReadOnly}
                        >
                            Request
                        </Button>
                    )}
                </div>
            </>
        );
    };

    return (
        <div className="product-details-flyout">
            <Flyout
                dimmer
                open={props.open ?? false}
                portalRoot="body"
                size={FlyoutWidthSize.small}
                onClose={props.onClose ? props.onClose : undefined}
            >
                <Flyout.Header title="Product Details" />
                <Flyout.Body>{renderBody()}</Flyout.Body>
                <Flyout.Footer>{renderFooter()}</Flyout.Footer>
            </Flyout>
        </div>
    );
};

ProductDetailsFlyout.propTypes = {
    open: PropTypes.bool,
    hidebackButtonfn: PropTypes.func,
    onClose: PropTypes.func,
    product: PropTypes.shape(IProductPropTypes).isRequired,
    errorText: PropTypes.string,
    onBookClick: PropTypes.func,
    onRequestClick: PropTypes.func,
};

export default ProductDetailsFlyout;
