import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    BannerMessageVariant,
    CallToAction,
    Pagination,
    CellType,
    Button,
    DropdownButton,
    IMenuItemProps,
} from '@amobee/component-library';
import { useQuery, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import { IProduct } from '../../interfaces/IProduct';
import { IDealLists } from '../../interfaces/IDealLists';
import { IColumns } from '../../interfaces/IColumns';
import { ICreateOrderMutation } from '../../interfaces/mutations/ICreateOrderMutation';
import DashboardTileView from '../DashboardTileView/DashboardTileView';
import { pageSize10, pageSize20 } from '../../constants/ProductsPagination';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {
    CREATE_ORDER_MUTATION,
    GET_SINGLE_INVENTORY_SOURCE_BY_STORE,
    GET_SINGLE_PUBLISHER_BY_STORE,
    GET_USER_PREFERENCE,
    CREATE_BULK_ORDER_MUTATION,
    GET_PUBLISHER_DEALS,
    ADD_DEAL_LIST_TO_MARKET,
} from '../../graphQL/queries';
import { useHistory } from 'react-router-dom';
import AlwaysOnModal from '../modals/AlwaysOnModal/AlwaysOnModal';
import ErrorModal from '../modals/ErrorModal/ErrorModal';
import ProductDetailsFlyout from '../ProductDetailsFlyout/ProductDetailsFlyout';
import ProductRequestFlyout from '../ProductRequestFlyout/ProductRequestFlyout';
import './DashboardItems.scss';
import { IEdge } from '../../interfaces/paging/IEdge';
import { Label } from '../../constants/LabelEnum';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { ISortType } from '../../constants/ProductSortEnum';
import { useQueryMultipleData } from '../../utils/hooks/useQueryMultiple';
import { pageNumber, pageSize50, pageSize25, pageSize100 } from '../../constants/OrdersPagination';
import { Row } from 'react-table';
import ExportCSV from '../ExportCSV/ExportCSV';
import DealsDatatable from '../DealsDataTable/DealsDatatable';
import CustomColumnConfig from '../CustomColumnConfig/CustomColumnConfig';
import DealListsDataTable from '../DealListsDataTable/DealListsDataTable';
import DealListsFlyout from '../DealListsFlyout/DealListsFlyout';
import DealListDashboardTileView from '../DealListsDashboardTileView/DealListDashboardTileView';
import { DashboardCardLoader } from '../Loader/Loader';
import { IStore } from '../../interfaces/IStore';
import { IConfigColumns, ICustomColumn } from '../../interfaces/ICustomColumnConfig';
import { IAddDealListToMarketMutation } from '../../interfaces/mutations/IAddDealListToMarketMutation';
import { ICellInfo, IRowSelection, ISelectedVisibleRows } from '../../interfaces/IRowSelection';
import { ISortStatePropTypes } from '../../constants/propTypes/ISortStatePropTypes';
import { IEnumType } from '../../interfaces/IEnumType';

const defaultSortByField = 'NAME';
const defaultSortOrder = 'DESCENDING';
const DashboardItems: React.FunctionComponent<{
    sendAfter: boolean;
    setSendAfter(sendAfter: boolean): void;
    filterCount: number;
    match?: string;
    dealNameMatch?: string;
    sortState: ISortType;
    tags?: Array<string>;
    publishers?: Array<string>;
    iabCategories?: Array<string>;
    categories?: Array<string>;
    inventorySource?: Array<string>;
    amobeeEnvironments?: Array<string>;
    amobeeCreativeFormats?: Array<string>;
    amobeeDealTypes?: Array<string>;
    rtlCreativeType?: Array<string>;
    currentPage?: number;
    rtlDealType?: Array<string>;
    priceRange?: Array<{ minPrice: number; maxPrice: number }>;
    bidRequestVolume?: Array<{ minBidRequestVolume: number; maxBidRequestVolume: number }>;
    negotiationTypes?: Array<string>;
    endCursor?: string;
    setEndCursor?(endCursor: string): void;
    onTagClick?(tag: string): void;
    onPublishersClick?(publisher: string): void;
    onIabCategoryClick?(iabCategory: string): void;
    onCategoryClick?(category: string): void;
    getFilterCount?: number;
    featured?: boolean;
    isList?: boolean;
    featuredView?: boolean;
    dealView?: boolean;
    tileView?: boolean;
    dealListsView?: boolean;
    getSingleInventorySource(data: boolean): void;
    getSinglePublisher(data: boolean): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);
    const account = marketplaceContext.account;
    const marketId = Number(marketplaceContext.marketId);
    let selectedRows: Array<number> = [];
    let internalIds: Array<number>;
    let names: Array<string>;

    const pageSize = props.featuredView ? pageSize10 : pageSize20;

    const [tableSelectedRows, setTableSelectedRows] = useState<Array<number>>([]);
    const [internalPublisherDealIds, setInternalPublisherDealIds] = useState<Array<string>>([]);
    const [dealListId, setDealListId] = useState<number>();
    const [dealListName, setDealListName] = useState<string>();
    const [selectedNames, setSelectedNames] = useState<Array<string>>();
    const [externalIds, setExternalIds] = useState<Array<string>>();
    const [bookedOrder, setBookedOrder] = useState<ICreateOrderMutation | undefined>(undefined);
    const [errorText, setErrorText] = useState<string | undefined>(undefined);
    const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(undefined);
    const [selectedDealList, setSelectedDealList] = useState<IDealLists | undefined>(undefined);
    const [showDetailFlyout, setShowDetailFlyout] = useState(false);
    const [showDealListsFlyout, setShowDealListFlyout] = useState(false);
    const [showProductRequest, setShowProductRequest] = useState(false);
    const [hidebackButton, setHidebackButton] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [productAdded] = useState<Array<number>>([]);
    const [emptyMessage, setEmptyMessage] = useState<string>(Label.NO_PRODUCTS_FILTERS);
    const [noProductsMessage, setNoProductsMessage] = useState(Label.NO_PRODUCTS_MESSAGE);
    const [isConfigEdit, setIsConfigEdit] = useState<boolean>(false);
    const [first, setFirst] = useState<number>(pageSize);
    const [orderName, setOrderName] = useState<string | string[]>();

    const dealsColumns = [
        {
            Header: 'Deal Name',
            accessor: 'name',
            id: 'name',
            minWidth: 300,
            cellType: CellType.LINK,
            onLinkClick: (row: Row) => {
                openProductDetails(row.original as IProduct);
            },
            isRowActionColumn: true,
            rowActionButtons: [
                {
                    buttonType: 'button',
                    iconName: 'Details',
                    titleText: 'Details',
                    onClick: (row: Row) => {
                        openProductDetails(row.original as IProduct);
                    },
                },
            ],
        },
        {
            Header: 'Deal Id',
            accessor: 'externalId',
            id: 'externalId',
            minWidth: 250,
        },
        {
            Header: 'Format',
            accessor: 'creativeFormats',
            id: 'creativeFormats',
            minWidth: 200,
            Cell: (cellInfo: ICellInfo) => {
                const prod = cellInfo.row.original;
                return prod.creativeFormats.toString().toLocaleLowerCase();
            },
        },
        {
            Header: 'Deal Type',
            accessor: 'amobeeDealTypes',
            id: 'amobeeDealTypes',
            minWidth: 200,
            Cell: (cellInfo: ICellInfo) => {
                const prod = cellInfo.row.original;
                return prod.amobeeDealTypes.toString().replace(/_/g, ' ').toLocaleLowerCase();
            },
        },
        {
            Header: 'Environment',
            accessor: 'environments',
            id: 'environments',
            minWidth: 200,
            Cell: (cellInfo: ICellInfo) => {
                const prod = cellInfo.row.original;
                return (
                    prod.environments
                        ?.map((env) => env.toString().replace(/_/g, ' '))
                        .join(', ')
                        .toLocaleLowerCase() ?? ''
                );
            },
        },
        {
            Header: 'Publisher',
            accessor: 'publisherName',
            id: 'publisherName',
            minWidth: 200,
        },
        {
            Header: 'Minimum CPM',
            accessor: 'price',
            id: 'price',
            minWidth: 200,
        },
        {
            Header: 'In View Rate',
            accessor: 'formattedInViewRate',
            id: 'formattedInViewRate',
            minWidth: 200,
        },
        {
            Header: 'Inventory Source',
            accessor: 'inventorySourceEnumValue',
            id: 'inventorySourceEnumValue',
            minWidth: 200,
        },
        {
            Header: 'Bid Requests (7 days)',
            accessor: 'bidRequestVolume',
            id: 'bidRequestVolume',
            minWidth: 200,
        },
        {
            Header: 'Availability End Date',
            accessor: 'availabilityEndDate',
            id: 'availabilityEndDate',
            minWidth: 200,
            Cell: (cellInfo: ICellInfo) => {
                const product = cellInfo.row.original;
                const date = !!product.availabilityEndDate ? new Date(product.availabilityEndDate) : null;
                return !!date ? format(date, 'dd MMM yyyy') : '';
            },
        },
        {
            Header: 'IAB Category',
            accessor: 'iabCategoryEnumId',
            id: 'iabCategoryEnumId',
            minWidth: 200,
        },
        {
            Header: 'Tags',
            accessor: 'tags',
            id: 'tags',
            minWidth: 200,
            Cell: (cellInfo: ICellInfo) => {
                const product = cellInfo.row.original;
                return product.tags.map((tag: IEnumType) => tag.name).join(', ');
            },
        },
    ];

    const dealListsColumns = [
        {
            Header: 'Deal List Name',
            accessor: 'dealListName',
            id: 'dealListName',
            cellType: CellType.LINK,
            isRowActionColumn: true,
            rowActionButtons: [
                {
                    buttonType: 'button',
                    iconName: 'Details',
                    titleText: 'Details',
                    onClick: (row: Row) => {
                        openDealListsFlyout(row.original as IDealLists);
                    },
                },
            ],
        },
        {
            Header: 'Deal Count',
            accessor: 'count',
            id: 'count',
        },
    ];

    const columns: IColumns[] = props.dealView && props.isList ? dealsColumns : dealListsColumns;

    const defaultMenuItems = [
        {
            label: 'Default',
            onClick: () => {
                setSelectedMenu('Default');
                setDisplayColumns(columns);
            },
        },
        {
            label: 'New custom column set',
            onClick: () => {
                setFlyoutOpen(true);
                setIsConfigEdit(false);
                setEditConfigName('');
                setConfigName('');
                setSelectedColumns([]);
            },
        },
    ];

    const [menuItems, setMenuItems] = useState<IMenuItemProps[]>(defaultMenuItems);

    const defaultPaginated = [
        {
            label: '25 rows',
            onClick: () => {
                setFirst(pageSize25);
            },
        },
        {
            label: '50 rows',
            onClick: () => {
                setFirst(pageSize50);
            },
        },
        {
            label: '100 rows',
            onClick: () => {
                setFirst(pageSize100);
            },
        },
    ];

    const [paginatedRows] = useState<Array<{ label: string; onClick: () => void }>>(defaultPaginated);
    const [displayColumns, setDisplayColumns] = useState<IColumns[]>(columns);
    const [isShowMessage, setIsShowMessage] = useState(false);
    const history = useHistory();
    const selectedStore = history.location.state as IStore;
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [isShowDealPopup, setIsShowDealPopup] = useState(false);
    const [isBookAndAssign, setIsBookAndAssign] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortName, setSortName] = useState<string>('');
    const [sortApplied, setSortApplied] = useState<boolean>(false);
    const [flyoutOpen, setFlyoutOpen] = useState<boolean>(false);
    const [selectedColumns, setSelectedColumns] = useState<IColumns[] | undefined>(undefined);
    const [configName, setConfigName] = useState<string | undefined>(undefined);
    const [editConfigName, setEditConfigName] = useState<string | undefined>(undefined);
    const [selectedMenu, setSelectedMenu] = useState(menuItems[0].label);
    const [featuredViewPagesize, setfeaturedViewPagesize] = useState(pageSize);

    const sortBy = useRef(defaultSortByField);
    const sortOrder = useRef(defaultSortOrder);
    const pageIndex = useRef(pageNumber);

    const dealDataRef = useRef<IProduct[]>([]);
    const dealListDataRef = useRef([]);

    const getDealsVariables = {
        marketId: marketId,
        match: props.match,

        sortBy: props?.sortState?.key != null && props.tileView ? props?.sortState?.key : sortBy?.current,
        sortOrder: props?.sortState?.value != null && props.tileView ? props?.sortState?.value : sortOrder?.current,

        first: first,
        after: props.sendAfter ? props.endCursor : undefined,
        storeId: (selectedStore?.value || selectedStore?.id) ?? 0,
        featured: true,
        page: props.sendAfter ? props.currentPage : undefined,
        filters: {
            inventorySource: props.inventorySource,
            amobeeEnvironments: props.amobeeEnvironments,
            amobeeCreativeFormats: props.amobeeCreativeFormats,
            amobeeDealTypes: props.amobeeDealTypes,
            rtlCreativeType: props.rtlCreativeType,
            rtlDealType: props.rtlDealType,
            priceRanges: props.priceRange?.map((range) => {
                return { minPrice: range.minPrice, maxPrice: range.maxPrice };
            }),
            bidRequstVolumeRange: props.bidRequestVolume?.length
                ? props.bidRequestVolume?.map((range) => {
                      return {
                          minBidRequestVolume: range.minBidRequestVolume,
                          maxBidRequestVolume: range.maxBidRequestVolume,
                      };
                  })
                : undefined,
            negotiationTypes: props.negotiationTypes,
            publishers: props.publishers,
            categories: props.categories,
        },
        isList: props.isList,
    };

    const getSortName = !props.tileView
        ? sortBy?.current + sortOrder?.current
        : props.sortState.key + props.sortState.value;
    if (sortName !== getSortName) {
        setSortName(getSortName);
        props.setSendAfter(false);
        if (!sortApplied) {
            setSortApplied(true);
        }
    }

    const getDealListsVariables = {
        marketId: marketId,
        pageNumber: currentPage,
        pageSize: pageSize,
        name: props.dealNameMatch,
        featured: true,
        sortBy: props?.sortState?.key != null ? props?.sortState?.key : sortBy?.current,
        sortOrder: props?.sortState?.value ?? sortOrder?.current,
        filters: {
            amobeeEnvironments: props.amobeeEnvironments,
            amobeeCreativeFormats: props.amobeeCreativeFormats,
        },
    };

    const [featuredDealsResponse, otherDealsResponse, featuredDealListsResponse, otherDealListsResponse] =
        useQueryMultipleData(
            getDealsVariables,
            getDealListsVariables,
            props.featuredView,
            props.dealView,
            props.dealListsView,
        );

    const featuredDealsData = featuredDealsResponse.data;
    const otherDealsData = otherDealsResponse.data;
    const isLoadingDeals = featuredDealsResponse?.loading || otherDealsResponse?.loading;

    const featuredDealListsData = featuredDealListsResponse.data;
    const otherDealListsData = otherDealListsResponse.data;
    const isLoadingDealLists = featuredDealListsResponse?.loading || otherDealListsResponse?.loading;

    useEffect(() => {
        props.featuredView && featuredDealListsData?.dealList?.edges?.length
            ? setfeaturedViewPagesize(pageSize10)
            : setfeaturedViewPagesize(pageSize20);
    }, []);

    const singlePublisher = useQuery(GET_SINGLE_PUBLISHER_BY_STORE, {
        variables: {
            marketId: marketId,
            storeId: selectedStore ? selectedStore?.value || selectedStore?.id : '0',
        },
    });
    const singleInventorySource = useQuery(GET_SINGLE_INVENTORY_SOURCE_BY_STORE, {
        variables: {
            marketId: marketId,
            storeId: selectedStore ? selectedStore?.value || selectedStore?.id : '0',
        },
    });

    const getPreference = useQuery(GET_USER_PREFERENCE, {
        variables: {
            externalId: account.id,
        },
        skip: account.id === '',
    });

    const publisherDealIds = selectedDealList?.dealIds;

    const getPublisherDeals = useQuery(GET_PUBLISHER_DEALS, {
        variables: { externalDealIds: publisherDealIds ? publisherDealIds : internalPublisherDealIds },
        skip: !selectedDealList,
    });

    const isPublisherDealsLoading = getPublisherDeals?.loading;

    const dropdownChangeEvent = (cc: ICustomColumn) => {
        setSelectedMenu(cc.name);
        setDisplayColumns(columns.filter((col: IColumns) => cc.columnsOrder.indexOf(col.accessor) > -1));
    };

    useEffect(() => {
        // useeffect for all variables
    }, [
        flyoutOpen,
        configName,
        selectedMenu,
        displayColumns,
        selectedColumns,
        isConfigEdit,
        editConfigName,
        menuItems,
        first,
    ]);

    const editConfig = (cc: ICustomColumn) => {
        setEditConfigName(cc.name);
        setIsConfigEdit(true);
        setFlyoutOpen(true);
        setConfigName(cc.name);
        setSelectedColumns(columns.filter((col: IColumns) => cc.columnsOrder.indexOf(col.accessor) > -1));
    };

    const updateDropdownMenu = (preferences: string, refetch = false) => {
        const configColumns: IConfigColumns = JSON.parse(preferences);

        const dealsMenuItemsArray = [];
        const dealListsMenuItemsArray = [];
        for (let index = 0; index < configColumns?.columns?.length; index++) {
            dealsMenuItemsArray.push(configColumns?.columns[index].deals);
            dealListsMenuItemsArray.push(configColumns?.columns[index].dealLists);
        }

        const dealsMenuItemsData: ICustomColumn[] = [];
        const dealListsMenuItemsData: ICustomColumn[] = [];
        dealsMenuItemsArray.forEach((ele) => {
            if (ele !== undefined) {
                dealsMenuItemsData.push(ele);
            }
        });
        dealListsMenuItemsArray.forEach((ele) => {
            if (ele !== undefined) {
                dealListsMenuItemsData.push(ele);
            }
        });
        if (props.dealView && props.isList) {
            if (menuItems?.length == dealsMenuItemsData?.length + 2 && !isConfigEdit) return menuItems;
        } else {
            if (menuItems?.length == dealListsMenuItemsData?.length + 2 && !isConfigEdit) return menuItems;
        }

        if (isConfigEdit) {
            menuItems.splice(2, menuItems.length);
        }

        if (refetch) {
            getPreference.refetch();
        }

        props.dealView && props.isList
            ? dealsMenuItemsData?.map((cc: ICustomColumn) => {
                  if (menuItems.filter((menu) => menu.label == cc.name).length <= 0) {
                      menuItems.push({
                          label: cc.name,
                          onClick: () => dropdownChangeEvent(cc),
                          rightElement: (
                              <Button
                                  className="edit-icon"
                                  icon="Edit"
                                  iconPosition="right"
                                  label=""
                                  onClick={() => editConfig(cc)}
                              />
                          ),
                      });
                  }
              })
            : dealListsMenuItemsData?.map((cc: ICustomColumn) => {
                  if (menuItems.filter((menu) => menu.label == cc.name).length <= 0) {
                      menuItems.push({
                          label: cc.name,
                          onClick: () => dropdownChangeEvent(cc),
                          rightElement: (
                              <Button
                                  className="edit-icon"
                                  icon="Edit"
                                  iconPosition="right"
                                  label=""
                                  onClick={() => editConfig(cc)}
                              />
                          ),
                      });
                  }
              });

        return menuItems;
    };

    const preferenceData = getPreference?.data;
    if (preferenceData?.getUserPreference && !isLoadingDeals) {
        updateDropdownMenu(preferenceData.getUserPreference);
    }

    const publisherValue = singlePublisher?.data?.singlePublisherByStore;
    const inventorySource = singleInventorySource?.data?.singleInventorySourceByStore;
    useEffect(() => {
        props.getSingleInventorySource(inventorySource);
        props.getSinglePublisher(publisherValue);
    }, [inventorySource, publisherValue]);

    const error = featuredDealsResponse?.error ?? otherDealsResponse?.error;

    useEffect(() => {
        props.setSendAfter(false);
        setCurrentPage(1);
        if (props.match !== '') {
            setIsShowMessage(false);
            setEmptyMessage("We couldn't find any matches for '" + props.match + "'. Please try different keywords.");
        } else {
            setIsShowMessage(false);
            setEmptyMessage(Label.NO_PRODUCTS_FILTERS);
        }
    }, [props.match, props.getFilterCount]);

    useEffect(() => {
        setCurrentPage(1);
        props.setSendAfter(false);
    }, [props.filterCount]);

    useEffect(() => {
        if (!featuredDealsData || !otherDealsData) {
            setIsLoading(false);
            setIsShowMessage(true);
            setNoProductsMessage(Label.NO_PRODUCTS_MESSAGE);
        }
    }, [marketId]);

    const [createOrder] = useMutation<ICreateOrderMutation>(CREATE_ORDER_MUTATION, {
        onError: (error) => {
            setIsShowDealPopup(false);
            setIsLoading(false);
            onError(error?.message);
        },
        onCompleted: (retVal) => {
            if (retVal.errors?.length ?? 0 > 0) {
                setIsLoading(false);
                onError(retVal.errors?.join('<br/>') ?? '');
            } else {
                const productAlreadyAdded = productAdded.filter((x) => x == selectedProduct?.id);
                if (orderName?.length) {
                    marketplaceContext.addBannerMessage?.(
                        `An order for ${orderName} has been created.`,
                        BannerMessageVariant.INFO,
                        'products',
                    );
                } else if (productAlreadyAdded.length <= 0) {
                    marketplaceContext.addBannerMessage?.(
                        `An order for ${selectedProduct?.name} has been created.`,
                        BannerMessageVariant.INFO,
                        'products',
                    );
                    productAdded.push(selectedProduct?.id as number);
                }
                featuredDealsResponse.refetch();
                featuredDealListsResponse.refetch();
                otherDealsResponse.refetch();
                setIsLoading(false);
                setBookedOrder(retVal);
                setShowDetailFlyout(false);
                setIsShowDealPopup(true);
            }
        },
    });

    const [createBulkOrder] = useMutation<ICreateOrderMutation>(CREATE_BULK_ORDER_MUTATION, {
        onError: (error) => {
            setIsShowDealPopup(false);
            setIsLoading(false);
            onError(error?.message);
        },
        onCompleted: (retVal) => {
            if (retVal.errors?.length ?? 0 > 0) {
                setIsLoading(false);
                onError(retVal.errors?.join('<br/>') ?? '');
            } else {
                if (orderName?.length) {
                    marketplaceContext.addBannerMessage?.(
                        `An order for ${orderName} has been created.`,
                        BannerMessageVariant.INFO,
                        'products',
                    );
                }
                featuredDealsResponse.refetch();
                featuredDealListsResponse.refetch();
                otherDealsResponse.refetch();
                setIsLoading(false);
                setBookedOrder(retVal);
                setShowDetailFlyout(false);
                setIsShowDealPopup(true);
            }
        },
    });

    const [addDeaListToMarket] = useMutation<IAddDealListToMarketMutation>(ADD_DEAL_LIST_TO_MARKET, {
        onError: (error) => {
            setIsShowDealPopup(false);
            setIsLoading(false);
            onError(error?.message);
        },
        onCompleted: (retVal) => {
            if (retVal.errors?.length ?? 0 > 0) {
                setIsLoading(false);
                onError(retVal.errors?.join('<br/>') ?? '');
            } else {
                if (orderName?.length) {
                    marketplaceContext.addBannerMessage?.(
                        `An order for ${orderName} has been created.`,
                        BannerMessageVariant.INFO,
                        'products',
                    );
                }
                otherDealListsResponse.refetch();
                setIsLoading(false);
                setBookedOrder(retVal);
                setShowDealListFlyout(false);
                setIsShowDealPopup(true);
            }
        },
    });

    const openProductDetails = (product: IProduct) => {
        setSelectedProduct(product);
        setShowDetailFlyout(true);
    };

    const openDealListsFlyout = (dealList: IDealLists) => {
        setSelectedDealList(dealList);
        setInternalPublisherDealIds(dealList.dealIds);
        setShowDealListFlyout(true);
    };

    const setPublisherDealIds = (data: Array<string>) => {
        setInternalPublisherDealIds(data);
    };

    const getDealListId = (id: number) => {
        setDealListId(id);
    };

    const getDealListName = (name: string) => {
        setDealListName(name);
    };

    const openProductRequest = (product: IProduct) => {
        setSelectedProduct(product);
        setShowProductRequest(true);
    };

    const onBookClick = (product: IProduct) => {
        if (product) setSelectedProduct(product);
        setIsShowPopup(true);
    };

    const onAddToDealListsClick = (dealList: IDealLists) => {
        if (dealList) setSelectedDealList(dealList);
        setIsShowPopup(true);
    };

    useEffect(() => {
        if (isBookAndAssign && !!bookedOrder) {
            window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/publisherDeal/assign?marketId=${marketId}&publisherDealId=${bookedOrder?.createOrder?.publisherDeal.id}`;
            setBookedOrder(undefined);
            setIsBookAndAssign(false);
        }
    }, [bookedOrder]);

    const onBookAndAssign = () => {
        setIsShowPopup(false);
        setIsLoading(true);
        createOrder({
            variables: {
                marketId: marketId,
                productId: selectedProduct?.externalId,
                productInternalId: selectedProduct?.id,
            },
        });
        setIsBookAndAssign(true);
    };

    const onBook = () => {
        setIsShowPopup(false);
        setIsLoading(true);
        createOrder({
            variables: {
                marketId: marketId,
                productId: selectedProduct?.externalId,
                productInternalId: selectedProduct?.id,
            },
        });
    };

    const onAddToDealLists = () => {
        setIsShowPopup(false);
        setIsLoading(true);
        addDeaListToMarket({
            variables: {
                marketId: marketId,
                dealListId: props.isList && dealListId ? dealListId : selectedDealList?.dealListId,
            },
        });
        setOrderName(selectedDealList?.dealListName);
    };

    const onError = (error: string) => {
        setErrorText(error);
    };

    const resetState = () => {
        setSelectedProduct(undefined);
        setErrorText(undefined);
        setShowProductRequest(false);
        setShowDetailFlyout(false);
        setHidebackButton(true);
        setShowDealListFlyout(false);
    };

    const onMyDealsClick = () => {
        window.location.href = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${marketId}/publisher-deal-list?invSrcId=105&mediaChannelId=2&searchString=${bookedOrder?.createOrder?.publisherDeal.id}`;

        setBookedOrder(undefined);
    };

    const onAssignClick = () => {
        const location = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/publisherDeal/assign?marketId=${marketId}&mediaChannelId=2&publisherDealId=${bookedOrder?.createOrder?.publisherDeal.id}`;

        window.open(location, '_blank', 'noopener,noreferrer');

        setBookedOrder(undefined);
    };

    const onMyDealListClick = () => {
        const url = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/market-dashboard/${marketId}/deal-list`;
        window.location.href = url;
        setBookedOrder(undefined);
    };

    const OnAssignDealList = () => {
        const dealListsId = bookedOrder?.addDeaListToMarket?.dealListDTO?.dealListId;
        const url = `${process.env.REACT_APP_CAMPAIGN}/webapp/#/dealList/assign?marketId=${marketId}&dealGroupId=${dealListsId}`;
        const location = url;
        window.open(location, '_blank', 'noopener,noreferrer');
        setBookedOrder(undefined);
    };

    const onDealOrdersClick = () => {
        history.push(`/${marketId}/orders`);
    };

    // eslint-disable-next-line
    const onProductRequest = (mutation: Promise<any>) => {
        setIsLoading(true);
        mutation.then((x) => {
            setIsLoading(false);
            if (x.data) {
                marketplaceContext.addBannerMessage?.(
                    <>
                        Your request for {selectedProduct?.name} has been submitted. You can track the status of your
                        request on the{' '}
                        <a onClick={onDealOrdersClick} href="#" style={{ textDecorationLine: 'underline' }}>
                            Deal Orders
                        </a>{' '}
                        page.{' '}
                        {selectedProduct?.creativeApprovalRequired
                            ? 'Please note that your request must be approved by the seller before you can submit creatives for approval.'
                            : ''}
                    </>,
                    BannerMessageVariant.INFO,
                    'products',
                );
                resetState();
            }
        });
    };

    const { width } = useWindowDimensions();

    const onPageChange = (p: number) => {
        const notNullScrollElement = window.document.getElementById('route-outlet');
        notNullScrollElement?.scrollTo(0, 0);
        props.setSendAfter(true);
        props.setEndCursor && props.setEndCursor(window.btoa((pageSize * (p - 1)).toString()));
        setCurrentPage(p);
    };

    if (error) {
        // setIsLoading(false);
        return (
            <div className="callToAction-Wrapper">
                <CallToAction title="Error" type="PageNotFound" />
            </div>
        );
    }

    const setHidebackButtonFn = () => {
        setHidebackButton(false);
    };

    const filterForUnbookedProducts = (row: ISelectedVisibleRows) => {
        return row.original.isBooked === false;
    };

    const onRowSelectionChange = (event: IRowSelection) => {
        internalIds = event.selectedVisibleRows.filter(filterForUnbookedProducts).map((row) => row.original.id);
        setExternalIds(
            event.selectedVisibleRows.filter(filterForUnbookedProducts).map((row) => row.original.externalId),
        );
        setDealListId(event?.selectedVisibleRows[0]?.original?.dealListId);

        names = event.selectedVisibleRows
            .filter(filterForUnbookedProducts)
            .map((row) => row?.original?.name || row.original?.dealListName);
        selectedRows = internalIds;
        setSelectedNames(names);
        setTableSelectedRows(selectedRows);

        if (selectedRows.length <= 0) {
            document.getElementById('add-to-my-deals')?.setAttribute('disabled', 'disabled');
            document.getElementById('add-to-my-deal-lists')?.setAttribute('disabled', 'disabled');
        } else {
            document.getElementById('add-to-my-deals')?.removeAttribute('disabled');
            document.getElementById('add-to-my-deal-lists')?.removeAttribute('disabled');
            if (selectedRows.length > 1) {
                document.getElementById('add-to-my-deal-lists')?.setAttribute('disabled', 'disabled');
            }
        }
        return;
    };

    const addToDeals = () => {
        if (tableSelectedRows.length == 0) {
            return;
        }
        setIsShowPopup(false);
        setIsLoading(true);

        if (tableSelectedRows.length == 1) {
            createOrder({
                variables: {
                    marketId: marketId,
                    productId: externalIds?.toString(),
                    productInternalId: tableSelectedRows[0],
                },
            });
        } else if (tableSelectedRows.length > 1) {
            createBulkOrder({
                variables: {
                    marketId: marketId,
                    productInternalId: tableSelectedRows,
                },
            });
        }
        setOrderName(selectedNames);
    };

    const addToDealLists = () => {
        if (tableSelectedRows.length == 0) {
            return;
        }
        setIsShowPopup(false);
        setIsLoading(true);
        if (tableSelectedRows.length == 1) {
            addDeaListToMarket({
                variables: {
                    marketId: marketId,
                    dealListId: props.isList && dealListId ? dealListId : selectedDealList?.dealListId,
                },
            });
        }
        setOrderName(selectedNames);
    };

    let totalCountForPagination = 0;

    if (props.featuredView && featuredDealsData && featuredDealListsData) {
        totalCountForPagination =
            featuredDealsData?.productsByStore?.totalCount + featuredDealListsData?.dealList?.totalCount;
    } else if (props.dealView && otherDealsData) {
        totalCountForPagination = otherDealsData?.productsByStore?.totalCount;
    } else if (props.dealListsView && otherDealListsData) {
        totalCountForPagination = otherDealListsData?.dealList?.totalCount;
    }

    const FeaturedDealsTileViewComponent = () => {
        return featuredDealsData?.productsByStore?.edges?.length ? (
            <>
                {featuredDealsData?.productsByStore?.edges?.map((edge: IEdge<IProduct>) => (
                    <DashboardTileView
                        publisherValueCheck={publisherValue}
                        inventorySourceCheck={inventorySource}
                        key={edge.node.id}
                        data={edge.node}
                        onClick={() => openProductDetails(edge.node)}
                        onBookClick={() => onBookClick(edge.node)}
                        onRequestClick={() => openProductRequest(edge.node)}
                    />
                ))}
            </>
        ) : (
            <></>
        );
    };

    const FeaturedDealListsTileViewComponent = () => {
        return featuredDealListsData?.dealList?.edges?.length ? (
            <>
                {featuredDealListsData?.dealList?.edges?.map((edge: IEdge<IDealLists>) => (
                    <DealListDashboardTileView
                        key={edge.node.id}
                        data={edge.node}
                        onClick={() => openDealListsFlyout(edge.node)}
                        onBookClick={() => onAddToDealListsClick(edge.node)}
                    />
                ))}
            </>
        ) : (
            <></>
        );
    };

    const DealsTileViewComponent = () => {
        return otherDealsData?.productsByStore?.edges.length ? (
            <>
                {otherDealsData?.productsByStore?.edges.map((edge: IEdge<IProduct>) => (
                    <DashboardTileView
                        publisherValueCheck={publisherValue}
                        inventorySourceCheck={inventorySource}
                        key={edge.node.id}
                        data={edge.node}
                        onClick={() => openProductDetails(edge.node)}
                        onBookClick={() => onBookClick(edge.node)}
                        onRequestClick={() => openProductRequest(edge.node)}
                    />
                ))}
            </>
        ) : (
            <></>
        );
    };

    const DealListsTileViewComponent = () => {
        return otherDealListsData?.dealList?.edges.length ? (
            <>
                {otherDealListsData?.dealList?.edges.map((edge: IEdge<IDealLists>) => (
                    <DealListDashboardTileView
                        key={edge.node.id}
                        data={edge.node}
                        onClick={() => openDealListsFlyout(edge.node)}
                        onBookClick={() => onAddToDealListsClick(edge.node)}
                    />
                ))}
            </>
        ) : (
            <></>
        );
    };

    const NoResultFoundComponent = () => {
        const hasFeaturedDeals = featuredDealsData && featuredDealsData?.productsByStore?.edges?.length > 0;
        const hasFeaturedDealLists = featuredDealListsData && featuredDealListsData?.dealList?.edges?.length > 0;
        const hasOtherDeals = otherDealsData && otherDealsData?.productsByStore?.edges?.length > 0;
        const hasOtherDealLists = otherDealListsData && otherDealListsData?.dealList?.edges?.length > 0;

        const shouldNotLoading = !isLoadingDeals && !isLoadingDealLists;

        const shouldShowNoResult =
            (props.featuredView && !hasFeaturedDeals && !hasFeaturedDealLists) ||
            (props.dealView && !hasOtherDeals) ||
            (props.dealListsView && !hasOtherDealLists);

        if (shouldNotLoading && shouldShowNoResult) {
            return (
                <div className="callToAction-Wrapper">
                    <CallToAction
                        description={isShowMessage ? noProductsMessage : emptyMessage}
                        title={isShowMessage ? Label.NO_PRODUCTS : Label.NO_RESULTS}
                        type={isShowMessage ? Label.TYPE_EMPTY_DATA : Label.EMPTY_SEARCH_RESULTS}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <div className="separator"></div>
            <div className="sub-label">
                {props.isList ? (
                    <>
                        {props.dealView ? (
                            <a onClick={addToDeals} id="add-to-my-deals">
                                {Label.ADD_TO_MY_DEALS}
                            </a>
                        ) : (
                            <a onClick={addToDealLists} id="add-to-my-deal-lists">
                                {Label.ADD_TO_MY_DEAL_LISTS}
                            </a>
                        )}

                        <ExportCSV
                            otherDealsResponse={otherDealsResponse}
                            otherDealListsResponse={otherDealListsResponse}
                            displayColumns={displayColumns}
                            dealListsColumns={dealListsColumns}
                            isList={props.isList}
                            dealView={props.dealView}
                        />
                        <DropdownButton
                            menuItems={menuItems}
                            triggerElement={
                                <Button
                                    icon="ColumnSettings"
                                    iconPosition="right"
                                    label={selectedMenu as string}
                                    ultraCompact
                                />
                            }
                        ></DropdownButton>
                        <DropdownButton
                            menuItems={paginatedRows}
                            triggerElement={<Button icon="Overflow" ultraCompact />}
                        ></DropdownButton>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {flyoutOpen && props.isList ? (
                <>
                    <CustomColumnConfig
                        columns={columns}
                        flyoutOpen={flyoutOpen}
                        isConfigEdit={isConfigEdit}
                        configName={configName}
                        selectedColumns={selectedColumns}
                        editConfigName={editConfigName}
                        menuItems={menuItems}
                        preferenceData={preferenceData}
                        setFlyoutOpen={setFlyoutOpen}
                        setErrorText={setErrorText}
                        setDisplayColumns={setDisplayColumns}
                        setSelectedMenu={setSelectedMenu}
                        setIsLoading={setIsLoading}
                        setConfigName={setConfigName}
                        setSelectedColumns={setSelectedColumns}
                        setMenuItems={setMenuItems}
                        updateDropdownMenu={updateDropdownMenu}
                        isList={props.isList}
                        dealView={props.dealView}
                    />
                </>
            ) : (
                <></>
            )}

            {props.dealView && props.isList ? (
                <>
                    <DealsDatatable
                        otherDealsResponse={otherDealsResponse}
                        displayColumns={displayColumns}
                        sortOrder={sortOrder}
                        sortBy={sortBy}
                        isLoadingDeals={isLoadingDeals}
                        dealDataRef={dealDataRef}
                        pageIndex={pageIndex}
                        setSendAfter={props.setSendAfter}
                        setCurrentPage={setCurrentPage}
                        setEndCursor={props.setEndCursor}
                        onRowSelectionChange={onRowSelectionChange}
                    />
                </>
            ) : props.dealListsView && props.isList ? (
                <>
                    <DealListsDataTable
                        otherDealListsResponse={otherDealListsResponse}
                        setPublisherDealIds={setPublisherDealIds}
                        getDealListId={getDealListId}
                        getDealListName={getDealListName}
                        displayColumns={displayColumns}
                        isLoadingDealLists={isLoadingDealLists}
                        dealListDataRef={dealListDataRef}
                        pageIndex={pageIndex}
                        sortOrder={sortOrder}
                        sortBy={sortBy}
                        onRowSelectionChange={onRowSelectionChange}
                    />
                </>
            ) : isLoadingDeals || isLoadingDealLists ? (
                <DashboardCardLoader />
            ) : (
                <div className="custom-dashboard-container">
                    <>
                        {props.featuredView && (
                            <>
                                <FeaturedDealsTileViewComponent />
                                <FeaturedDealListsTileViewComponent />
                            </>
                        )}

                        {props.dealView && <DealsTileViewComponent />}

                        {props.dealListsView && <DealListsTileViewComponent />}

                        {selectedStore && <NoResultFoundComponent />}

                        {selectedStore ? (
                            <div
                                style={{
                                    gridColumnStart: `span ${width > 1550 ? 15 : width > 1300 ? 12 : 9}`,
                                    gridRowStart: 'span 2',
                                    width: `100%`,
                                }}
                            >
                                <Pagination
                                    totalCount={totalCountForPagination}
                                    pageSize={props.featuredView ? featuredViewPagesize : pageSize20}
                                    currentPage={currentPage}
                                    siblingCount={1}
                                    onPageChange={(p: number) => onPageChange(p)}
                                    style={{ padding: '15px 0 0 0', marginLeft: '-10px' }}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                </div>
            )}

            <AlwaysOnModal
                open={isShowPopup}
                productName={selectedProduct?.name}
                dealListName={selectedDealList?.dealListName || dealListName}
                selectedDealList={selectedDealList}
                mutationResponse={bookedOrder}
                onClose={() => {
                    setBookedOrder(undefined);
                    setIsShowPopup(false);
                    setIsShowDealPopup(false);
                }}
                onAssignClick={onAssignClick}
                OnAssignDealList={OnAssignDealList}
                onMyDealsClick={onMyDealsClick}
                onMyDealListClick={onMyDealListClick}
                onBookAndAssign={onBookAndAssign}
                onBook={onBook}
                onAddToDealLists={onAddToDealLists}
                dealPopupFlag={isShowDealPopup}
                isList={props.isList}
                featuredView={props.featuredView}
                dealView={props.dealView}
                dealListsView={props.dealListsView}
                tileView={props.tileView}
                isLoading={isLoading}
            />
            <ErrorModal
                open={!!errorText && !showDetailFlyout && !showProductRequest}
                title="Request Failed"
                error={errorText}
                onClose={() => resetState()}
            />
            {selectedProduct ? (
                <>
                    <ProductDetailsFlyout
                        open={showDetailFlyout}
                        onClose={() => {
                            resetState();
                        }}
                        product={selectedProduct}
                        onBookClick={onBookClick}
                        onRequestClick={() => setShowProductRequest(true)}
                        errorText={errorText}
                        hidebackButtonfn={setHidebackButtonFn}
                    />

                    <ProductRequestFlyout
                        open={showProductRequest}
                        onClose={() => {
                            resetState();
                        }}
                        product={selectedProduct}
                        onProductRequest={onProductRequest}
                        onGoBackClick={() => {
                            setShowProductRequest(false);
                            setShowDetailFlyout(true);
                        }}
                        hidebackButton={hidebackButton}
                    />
                </>
            ) : undefined}
            {props.featuredView || props.dealListsView ? (
                <>
                    <DealListsFlyout
                        open={showDealListsFlyout}
                        onClose={() => {
                            resetState();
                        }}
                        onAddToDealListsClick={onAddToDealListsClick}
                        setShowDealListFlyout={setShowDealListFlyout}
                        selectedDealList={selectedDealList}
                        getPublisherDeals={getPublisherDeals}
                        dealListsView={props.dealListsView}
                        isList={props.isList}
                        isPublisherDealsLoading={isPublisherDealsLoading}
                    />
                </>
            ) : undefined}
        </>
    );
};

DashboardItems.propTypes = {
    sendAfter: PropTypes.bool.isRequired,
    setSendAfter: PropTypes.func.isRequired,
    filterCount: PropTypes.number.isRequired,
    match: PropTypes.string,
    dealNameMatch: PropTypes.string,
    tags: PropTypes.array,
    publishers: PropTypes.array,
    iabCategories: PropTypes.array,
    categories: PropTypes.array,
    sortState: PropTypes.shape(ISortStatePropTypes).isRequired,
    inventorySource: PropTypes.array,
    amobeeEnvironments: PropTypes.array,
    amobeeCreativeFormats: PropTypes.array,
    amobeeDealTypes: PropTypes.array,
    rtlCreativeType: PropTypes.array,
    rtlDealType: PropTypes.array,
    priceRange: PropTypes.array,
    bidRequestVolume: PropTypes.array,
    negotiationTypes: PropTypes.array,
    endCursor: PropTypes.string,
    setEndCursor: PropTypes.func,
    onTagClick: PropTypes.func,
    onPublishersClick: PropTypes.func,
    onIabCategoryClick: PropTypes.func,
    onCategoryClick: PropTypes.func,
    getFilterCount: PropTypes.number,
    currentPage: PropTypes.number,
    featured: PropTypes.bool,
    isList: PropTypes.bool,
    featuredView: PropTypes.bool,
    dealView: PropTypes.bool,
    tileView: PropTypes.bool,
    dealListsView: PropTypes.bool,
    getSingleInventorySource: PropTypes.func.isRequired,
    getSinglePublisher: PropTypes.func.isRequired,
};

export default DashboardItems;
