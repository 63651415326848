export interface ILanguage {
    id: number;
    name: string;
    code: string;
}

export class LanguageEnum {
    public static Afrikaans: ILanguage = { id: 0, name: 'Afrikaans', code: 'af' };
    public static Albanian: ILanguage = { id: 1, name: 'Albanian', code: 'sq' };
    public static Arabic: ILanguage = { id: 2, name: 'Arabic', code: 'ar' };
    public static Armenian: ILanguage = { id: 3, name: 'Armenian', code: 'hy' };
    public static BahasaIndonesia: ILanguage = { id: 4, name: 'Bahasa (Indonesia)', code: 'id' };
    public static BahasaMalaysia: ILanguage = { id: 5, name: 'Bahasa (Malaysia)', code: 'ms' };
    public static Bengali: ILanguage = { id: 6, name: 'Bengali', code: 'bn' };
    public static Bulgarian: ILanguage = { id: 7, name: 'Bulgarian', code: 'bg' };
    public static Croatian: ILanguage = { id: 8, name: 'Croatian', code: 'hr' };
    public static Czech: ILanguage = { id: 9, name: 'Czech', code: 'cs' };
    public static Danish: ILanguage = { id: 10, name: 'Danish', code: 'da' };
    public static Dutch: ILanguage = { id: 11, name: 'Dutch', code: 'nl' };
    public static English: ILanguage = { id: 12, name: 'English', code: 'en' };
    public static Estonian: ILanguage = { id: 13, name: 'Estonian', code: 'et' };
    public static Finnish: ILanguage = { id: 14, name: 'Finnish', code: 'fi' };
    public static French: ILanguage = { id: 15, name: 'French', code: 'fr' };
    public static German: ILanguage = { id: 16, name: 'German', code: 'de' };
    public static Greek: ILanguage = { id: 17, name: 'Greek', code: 'el' };
    public static Hebrew: ILanguage = { id: 18, name: 'Hebrew', code: 'he' };
    public static Hindi: ILanguage = { id: 19, name: 'Hindi', code: 'hi' };
    public static Hungarian: ILanguage = { id: 20, name: 'Hungarian', code: 'hu' };
    public static Icelandic: ILanguage = { id: 21, name: 'Icelandic', code: 'is' };
    public static Italian: ILanguage = { id: 22, name: 'Italian', code: 'it' };
    public static Japanese: ILanguage = { id: 23, name: 'Japanese', code: 'ja' };
    public static Khmer: ILanguage = { id: 24, name: 'Khmer', code: 'km' };
    public static Korean: ILanguage = { id: 25, name: 'Korean', code: 'ko' };
    public static Lao: ILanguage = { id: 26, name: 'Lao', code: 'lo' };
    public static Latvian: ILanguage = { id: 27, name: 'Latvian', code: 'lv' };
    public static Lithuanian: ILanguage = { id: 28, name: 'Lithuanian', code: 'lt' };
    public static Mongolian: ILanguage = { id: 29, name: 'Mongolian', code: 'mn' };
    public static Norwegian: ILanguage = { id: 30, name: 'Norwegian', code: 'no' };
    public static Persian: ILanguage = { id: 31, name: 'Persian', code: 'fa' };
    public static Polish: ILanguage = { id: 32, name: 'Polish', code: 'pl' };
    public static Portuguese: ILanguage = { id: 33, name: 'Portuguese', code: 'pt' };
    public static Romanian: ILanguage = { id: 34, name: 'Romanian', code: 'ro' };
    public static Russian: ILanguage = { id: 35, name: 'Russian', code: 'ru' };
    public static Serbian: ILanguage = { id: 36, name: 'Serbian', code: 'sr' };
    public static Slovak: ILanguage = { id: 37, name: 'Slovak', code: 'sk' };
    public static Somali: ILanguage = { id: 38, name: 'Somali', code: 'so' };
    public static ChineseSimplified: ILanguage = { id: 39, name: 'Chinese (Simplified)', code: 'zh-CN' };
    public static Spanish: ILanguage = { id: 40, name: 'Spanish', code: 'es' };
    public static Swahili: ILanguage = { id: 41, name: 'Swahili', code: 'sw' };
    public static Swedish: ILanguage = { id: 42, name: 'Swedish', code: 'sv' };
    public static Tagalog: ILanguage = { id: 43, name: 'Tagalog', code: 'tl' };
    public static Thai: ILanguage = { id: 44, name: 'Thai', code: 'th' };
    public static ChineseTraditional: ILanguage = { id: 45, name: 'Chinese (Traditional)', code: 'zh-TW' };
    public static Turkish: ILanguage = { id: 46, name: 'Turkish', code: 'tr' };
    public static Ukrainian: ILanguage = { id: 47, name: 'Ukrainian', code: 'uk' };
    public static Urdu: ILanguage = { id: 48, name: 'Urdu', code: 'ur' };
    public static Vietnamese: ILanguage = { id: 49, name: 'Vietnamese', code: 'vi' };
}

export const Languages: Array<ILanguage> = Object.values(LanguageEnum);
