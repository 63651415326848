import { IAccordionCheckListByStore } from '../components/common/interfaces/IAccordionCheckListByStore';
import { AmobeeCreativeFormatEnum } from './ProductFilterEnums';

export class AmobeeCreativeType {
    public static readonly DISPLAY: IAccordionCheckListByStore = {
        display: AmobeeCreativeFormatEnum.DISPLAY,
        enumFormat: 'DISPLAY',
        selected: false,
    };
    public static readonly VIDEO: IAccordionCheckListByStore = {
        display: AmobeeCreativeFormatEnum.VIDEO,
        enumFormat: 'VIDEO',
        selected: false,
    };
    public static readonly AUDIO: IAccordionCheckListByStore = {
        display: AmobeeCreativeFormatEnum.AUDIO,
        enumFormat: 'AUDIO',
        selected: false,
    };
}
