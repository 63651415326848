import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonVariant } from '@amobee/component-library';
import '../DashboardTileView/DashboardTileView.scss';
import { MarketplaceContext } from '../MarketplaceContext/MarketplaceContext';
import { IDealLists } from '../../interfaces/IDealLists';
import { DealListsColumnHeadersEnum } from '../../constants/ColumnHeadersEnum';
import { Label } from '../../constants/LabelEnum';

const DealListDashboardTileView: React.FunctionComponent<{
    data: IDealLists;
    onClick?(): void;
    onBookClick?(deal: IDealLists): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);

    const button = (
        <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
                props.onBookClick?.(props.data);
            }}
            title={props.data.isBooked ? Label.DEALLIST_IS_ALREADY_BOOKED : ''}
            disabled={marketplaceContext.account.isReadOnly || props.data.isBooked}
        >
            {Label.ADD_TO_MY_DEAL_LISTS}
        </Button>
    );

    return (
        <div className="main-container">
            <div className="tile-container" onClick={props.onClick}>
                <img
                    className="card-header-image"
                    src={props.data.cardUrl ? props.data.cardUrl : '/images/no_pic.svg'}
                />

                <div className="content">
                    <div className="card-body-table">
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="22%">{DealListsColumnHeadersEnum.DEAL_LIST_NAME}: </td>
                                    <td>{props.data.dealListName} </td>
                                </tr>
                                <tr>
                                    <td>{DealListsColumnHeadersEnum.DEAL_COUNT}: </td>
                                    <td>{props.data.count}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        <span className="footer-details">
                            <span className="add-button">{button}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

DealListDashboardTileView.propTypes = {
    data: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    onBookClick: PropTypes.func,
};

export default DealListDashboardTileView;
