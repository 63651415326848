import {
    BannerMessageVariant,
    Button,
    ButtonVariant,
    Icon,
    Input,
    InputVariant,
    Navbar,
    NavbarCenter,
    NavbarRight,
    ProductSelector,
} from '@amobee/component-library';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IMarket } from '../../interfaces/IMarket';
import { useQueryMultiple } from '../../utils/hooks/useQueryMultiple';

import './MarketplaceFramework.scss';
import { IMarketsQuery } from '../../interfaces/queries/IMarketsQuery';
import logo from '../../assets/logo_2.gif';
import {
    DefaultMarketplaceContext,
    IBannerQueue,
    MarketplaceContext,
    MarketplacePage,
} from '../MarketplaceContext/MarketplaceContext';
import { v5 as uuid } from 'uuid';
import { ApolloContextProvider } from '../ApolloContextProvider/ApolloContextProvider';
import { OktaAuth } from '@okta/okta-auth-js';

import PropTypes from 'prop-types';
import { Routes } from '../../constants/Routes';
import { StorefrontFramework } from '../StorefrontFramework/StorefrontFramework';
import Dropdown from 'react-bootstrap/Dropdown';
import getSymbolFromCurrency from 'currency-symbol-map';
import { GET_STORES } from '../../graphQL/queries';
import { IStoreQuery } from '../../interfaces/queries/IStoreQuery';
import { useQuery } from '@apollo/client';
import { Label } from '../../constants/LabelEnum';
import { IStore } from '../../interfaces/IStore';

export const MarketplaceApolloFramework: React.FunctionComponent<{ oktaAuth?: OktaAuth }> = (props) => {
    return (
        <ApolloContextProvider oktaAuth={props.oktaAuth}>
            <MarketplaceFramework></MarketplaceFramework>
        </ApolloContextProvider>
    );
};

MarketplaceApolloFramework.propTypes = {
    oktaAuth: PropTypes.any,
};

export const MarketplaceFramework: React.FunctionComponent = () => {
    const [isProductSelectorOpen, setProductSelectorOpen] = useState<boolean>(false);
    const [pendoInitialized, setPendoInitialized] = useState<boolean>(false);
    const [selectedMarket, setSelectedMarket] = useState<IMarket>({});
    const [currencySymbol, setcurrencySymbol] = useState<string | undefined>('');
    const history = useHistory();
    const marketIdFromUrl = location.pathname.match(/\/([^/]+)\//);
    const pageName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    let marketId: string | null = window.sessionStorage.getItem('marketId');

    if (!marketId) {
        marketId = '1';
    }

    let marketIdUrlToNum;
    {
        marketIdFromUrl ? (marketIdUrlToNum = parseInt(marketIdFromUrl[1])) : '';
    }

    const storeData = useQuery<IStoreQuery>(GET_STORES, {
        variables: {
            marketId: marketIdUrlToNum,
        },
    });

    const amobeePreferredStore = storeData?.data?.stores?.filter((s) => s.name === Label.AMOBEE_PREFERRED).pop();
    const otherStore = storeData?.data?.stores?.filter((s) => s.name !== Label.AMOBEE_PREFERRED).pop();

    const selectedStore = history.location.state as IStore;

    let storeId;
    if (amobeePreferredStore) {
        storeId = amobeePreferredStore?.id;
    } else if (otherStore) {
        storeId = otherStore.id;
    } else {
        storeId = selectedStore?.value || selectedStore?.id;
    }

    if (amobeePreferredStore || otherStore) {
        {
            // if page name entered in the url is dashboard
            if (pageName === 'dashboard' || pageName === '') {
                if (marketIdFromUrl) {
                    const marketIdFromUrlToNumber = parseInt(marketIdFromUrl[1]);
                    if (amobeePreferredStore) {
                        history.push(`/${marketIdFromUrlToNumber}/dashboard/${storeId}`, amobeePreferredStore);
                    } else {
                        history.push(`/${marketIdFromUrlToNumber}/dashboard/${storeId}`, otherStore);
                    }
                } else {
                    history.push(`/${marketId}/dashboard`);
                }
            }
        }
    }

    const response = useQueryMultiple();
    const data = response[0].data as IMarketsQuery;
    const loading = response[0].loading;
    const accountResponse = response[1];

    const findSelectedMarketId = (marketId: number) => {
        let TURN = data.markets.find((market) => market.id === marketId);
        if (!TURN) {
            TURN = data.markets[0];
        }
        setSelectedMarket(TURN);
        if (TURN?.currencyCode) {
            setcurrencySymbol(getSymbolFromCurrency(TURN?.currencyCode));
        }
    };

    useEffect(() => {
        if (data?.markets) {
            if (marketIdFromUrl) {
                const marketIdFromUrlToNumber = parseInt(marketIdFromUrl[1]);
                findSelectedMarketId(marketIdFromUrlToNumber);
            } else {
                findSelectedMarketId(1);
            }
        }
    }, [JSON.stringify(data)]);

    const [bannerQueue, setBannerQueue] = useState<Array<IBannerQueue>>([]);

    const removeBannerMessage = (id: string) => {
        const retVal = bannerQueue.slice();

        const index = bannerQueue.findIndex((x) => x.id === id);

        retVal.splice(index, 1);

        setBannerQueue(retVal);
    };

    const addBannerMessage = (message: string | JSX.Element, variant: BannerMessageVariant, page: MarketplacePage) => {
        const val = bannerQueue.slice();
        val.push({
            id: uuid(message.toString(), process.env.REACT_APP_UUID ?? ''),
            message: message,
            variant: variant,
            page: page,
        });

        setBannerQueue(val);
    };

    const handleClick = () => {
        setProductSelectorOpen(!isProductSelectorOpen);
    };

    if (loading)
        return (
            <div className="loading">
                <img src={logo} className="logoImage" />
            </div>
        );

    if (accountResponse.data && !pendoInitialized) {
        if (process.env.REACT_APP_USE_PENDO === 'true') {
            const account = accountResponse.data.account;

            /* eslint-disable-next-line @typescript-eslint/no-explicit-any  */
            (window as any).pendo.initialize({
                visitor: {
                    id: account.email,
                    name: account.firstName + account.lastName,
                    email: account.email,
                    role: 'visitor',
                },
            });

            setPendoInitialized(true);
        }
    }

    return (
        <MarketplaceContext.Provider
            value={{
                account: accountResponse.data?.account ?? DefaultMarketplaceContext.account,
                bannerQueue: bannerQueue,
                addBannerMessage: addBannerMessage,
                removeBannerMessage: removeBannerMessage,
                marketId: selectedMarket.id ?? DefaultMarketplaceContext.marketId,
                currencyCode: currencySymbol ?? '$',
            }}
        >
            <Navbar
                logoLink={`${process.env.REACT_APP_CAMPAIGN}/webapp/#/campaigns/last-visited`}
                menuButtonEl={
                    <Button variant={ButtonVariant.STATIC_BORDERLESS} onClick={handleClick}>
                        <Icon name="Menu" />
                    </Button>
                }
                productName={process.env.REACT_APP_PRODUCT_TITLE}
            >
                <NavbarCenter>
                    <div>
                        <Input
                            compact
                            leftElement={<Icon name="Search" />}
                            placeholder="Search..."
                            variant={InputVariant.BORDERLESS}
                        />
                    </div>
                </NavbarCenter>
                <NavbarRight>
                    <Button variant={ButtonVariant.STATIC_BORDERLESS}>
                        <Icon name="Favorite" />
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle variant={ButtonVariant.STATIC_BORDERLESS}>
                            <Icon name="User" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    history.push(Routes.LOGOUT_PATH);
                                }}
                                href="#"
                            >
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant={ButtonVariant.STATIC_BORDERLESS}>
                        <Icon name="Settings" />
                    </Button>
                    <Button variant={ButtonVariant.STATIC_BORDERLESS}>
                        <Icon name="Help" />
                    </Button>
                </NavbarRight>
            </Navbar>
            <StorefrontFramework
                data={data}
                selectedMarket={selectedMarket}
                selectedStore={selectedStore}
            ></StorefrontFramework>
            <div onClick={handleClick}>
                <ProductSelector
                    baseURL={process.env.REACT_APP_CAMPAIGN as string}
                    open={isProductSelectorOpen}
                ></ProductSelector>
            </div>
        </MarketplaceContext.Provider>
    );
};
