export enum Label {
    ABOVE_TEN_BIL_LABEL = '> 10B',
    ACCEPTING_DEAL = 'Accepting Deal',
    ADD_TO_MY_DEALS = 'Add to My Deals',
    ADD_TO_MY_DEAL_LISTS = 'Add to My Deal lists',
    AMOBEE_PREFERRED = 'AMOBEE_PREFERRED',
    AMOBEE_PREFERRED_STORE = 'Amobee Preferred Store',
    ASSIGN = 'Assign',
    BID_REQUEST_VOLUME = 'Bid Request',
    BOOK = 'Book',
    CANCEL = 'Cancel',
    CONFIRM_BOOKING = 'Confirm Product Booking',
    CATEGORY_FILTER_TITLE = 'Category',
    CPM_WARNING_TEXT = 'Invalid. Max value cannot be lower than Min value.',
    DEAL_BOOKED = 'Deal Booked',
    DEAL_LIST_BOOKED = 'Deal List Booked',
    DEAL_IS_ALREADY_BOOKED = 'Deal is already booked',
    DEALLIST_IS_ALREADY_BOOKED = 'Deal list is already booked',
    DEAL_LISTS = 'Deal Lists',
    DEALS = 'Deals',
    DEAL_LIST = 'Deal List',
    DEAL = 'Deal',
    EMPTY_SEARCH_RESULTS = 'EmptySearchResults',
    EXPORT_CSV = 'EXPORT CSV',
    HUNDRED_MIL_LABEL = '100M',
    IS_BOOKED = ' is booked, you can find it in',
    MY_DEALS = 'My Deals',
    MY_DEAL_LIST = 'My Deal lists',
    MY_DEAL_INFO_TEXT = 'You can immediately assign deals to a campaigns, should you wish. Or you can do that later from the My Deals screen.',
    MY_DEAL_LIST_INFO_TEXT = 'You can immediately assign deal list to a campaigns, should you wish. Or you can do that later from the My Deal list screen.',
    NO_DATA = 'No Data',
    NO_DATA_FOUND_MSG = 'There was no data found',
    NO_DEALS = 'No Deals Found',
    NO_PRODUCTS = 'No Products Available',
    NO_PRODUCTS_FILTERS = "We couldn't find a match. Please try different keywords or filters.",
    NO_PRODUCTS_MESSAGE = 'No products are available in the marketplace. Please try again later',
    NO_RESULTS = 'No Results Found',
    NONE = 'None',
    ONE_BIL_LABEL = '1B',
    REQUEST = 'Request',
    TAG_FILTER_TITLE = 'Tags',
    TEN_BIL_LABEL = '10B',
    TEN_MIL_LABEL = '10M',
    TYPE_EMPTY_DATA = 'EmptyData',
    TYPE_EMPTY_TABLE = 'EmptyTable',
    UNDER_ONE_MIL_LABEL = '< 1M',
    USD_LABEL = 'USD ($)',
    WAS_BOOKED = ' was booked, and can be found in',
    ZERO_PERCENT = '0%',
}
