import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './AccordionCheckListByStore.scss';
import {
    Accordion,
    Checkbox,
    Typeahead,
    Input,
    InputVariant,
    BannerMessage,
    BannerMessageVariant,
} from '@amobee/component-library';
import { IAccordionCheckListByStore } from '../interfaces/IAccordionCheckListByStore';
import { AccordionFilterEnum, DashboardFilterEnum, FilterStateEnum } from '../../../constants/ProductFilterEnums';
import { Label } from '../../../constants/LabelEnum';
import { MarketplaceContext } from '../../MarketplaceContext/MarketplaceContext';
import { IAccordionCheckListByStorePropTypes } from '../../../constants/propTypes/IAccordionCheckListByStorePropTypes';
import { SetSelectionMapStateType } from '../../../constants/MapStateType';

export const AccordionCheckListByStore: React.FunctionComponent<{
    open?: boolean;
    title?: string;
    clearSelectionState?: string;
    setClearSelectionState?(state: string): void;
    setSelectionMap?(item: SetSelectionMapStateType): void;
    items: Array<IAccordionCheckListByStore>;
    publisherItems?: Array<IAccordionCheckListByStore>;
    categoryItems?: Array<IAccordionCheckListByStore>;
    onItemSelection?(selectedItems: Array<string>, label?: string): void;
    fixedItemsValue: Array<IAccordionCheckListByStore>;
    searchString?: string;
    setSearchText?(text: string): void;
}> = (props) => {
    const marketplaceContext = useContext(MarketplaceContext);
    const [items, setItems] = useState<Array<IAccordionCheckListByStore>>(props.items);
    const [publisherItems, setPublisherItems] = useState<Array<IAccordionCheckListByStore> | undefined>(
        props.publisherItems,
    );
    const [categoryItems, setCategoryItems] = useState<Array<IAccordionCheckListByStore> | undefined>(
        props.categoryItems,
    );
    const [fixedItems, setfixedItems] = useState<Array<IAccordionCheckListByStore>>(props.fixedItemsValue);
    const [selectedPublishers, setSelectedPublishers] = useState<Array<IAccordionCheckListByStore>>([]);
    const [selectedCategory, setSelectedCategory] = useState<Array<IAccordionCheckListByStore>>([]);

    const minPrice = window.sessionStorage.getItem('minPrice');
    const maxPrice = window.sessionStorage.getItem('maxPrice');

    const [cpmMinInitialValue, setMinCpmInitialValue] = useState<string>(minPrice ? minPrice : '');
    const [cpmMaxInitialValue, setMaxCpmInitialValue] = useState<string>(maxPrice ? maxPrice : '');

    const updateSelectionMap = (selected?: string | Array<IAccordionCheckListByStore>, label?: string) => {
        const selectedItems = items.find((item) => item.selected == true) || selected?.length;
        if (selectedItems) {
            props.setSelectionMap?.(
                (selectedItem: Map<string | undefined, string>) =>
                    new Map(selectedItem.set(label ? label : props.title, FilterStateEnum.SELECTED)),
            );
        } else {
            props.setSelectionMap?.(
                (selectedItem: Map<string | undefined, string>) =>
                    new Map(selectedItem.set(label ? label : props.title, FilterStateEnum.UNSELECTED)),
            );
        }
    };

    const toggleSelect = (label: string) => {
        let newItemArray = items.slice();
        const loc = newItemArray.findIndex((item) => item.enumFormat === label || item.display === label);
        if (props.title === Label.BID_REQUEST_VOLUME) {
            newItemArray = newItemArray.map((i, index) => {
                return index !== loc ? { display: i.display, enumFormat: i.enumFormat, selected: false } : i;
            });
        }
        newItemArray[loc].selected = !(newItemArray[loc].selected ?? false);
        if (props.onItemSelection) {
            props.onItemSelection(newItemArray.filter((x) => x.selected).map((x) => x.enumFormat ?? x.display));
        }
        updateSelectionMap();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, label: string) => {
        const value = event.target.value;
        const { MIN, MAX } = DashboardFilterEnum;

        if (label === MIN) {
            setMinCpmInitialValue(value);
            window.sessionStorage.setItem('minPrice', value);
        } else if (label === MAX) {
            setMaxCpmInitialValue(value);
            window.sessionStorage.setItem('maxPrice', value);
        }

        if (props.onItemSelection) {
            props.onItemSelection([value], label);
        }

        updateSelectionMap(value, label);
    };

    const getSummaryItems = () => {
        if (props.title !== AccordionFilterEnum.CPM) {
            return items.filter((item) => item.selected).map((item) => ({ label: item.display }));
        } else {
            if (cpmMinInitialValue && !cpmMaxInitialValue) {
                return [{ label: `Min: ${cpmMinInitialValue}` }];
            } else if (cpmMaxInitialValue && !cpmMinInitialValue) {
                return [{ label: `Max: ${cpmMaxInitialValue}` }];
            } else if (cpmMaxInitialValue && cpmMinInitialValue) {
                return [{ label: `Min: ${cpmMinInitialValue}, Max: ${cpmMaxInitialValue}` }];
            }
        }
    };

    const onCategorySelection = (selected: Array<IAccordionCheckListByStore>) => {
        if (props.onItemSelection) {
            props.onItemSelection([
                ...selected.map((category) => {
                    return category.display;
                }),
            ]);
        }
        updateSelectionMap(selected);
    };

    const onPublishersSelection = (selected: Array<IAccordionCheckListByStore>) => {
        if (props.onItemSelection) {
            props.onItemSelection([
                ...selected.map((publisher) => {
                    return publisher.display;
                }),
            ]);
        }
        updateSelectionMap(selected);
    };

    function checkFilterValue(item: string): boolean {
        let valuePresent = false;
        fixedItems.flatMap((k) => {
            if (k.display === item) {
                valuePresent = true;
            }
        });
        return valuePresent ? false : true;
    }

    useEffect(() => {
        setItems(props.items);
        const { CATEGORY_FILTER_TITLE } = Label;
        const { PUBLISHERS } = AccordionFilterEnum;
        const selectedItems = props.title === CATEGORY_FILTER_TITLE ? selectedCategory : selectedPublishers;
        selectedItems.forEach((item) => {
            if (item.selected && !props.items.some((value) => value.display === item.display)) {
                props.items.push(item);
            }
        });
        if (props.title === CATEGORY_FILTER_TITLE) {
            setSelectedCategory(props.items.filter((cat) => cat.selected));
        } else if (props.title === PUBLISHERS) {
            setSelectedPublishers(props.items.filter((publisher) => publisher.selected));
        }

        if (props.clearSelectionState == FilterStateEnum.CLEAR) {
            items.forEach((item) => {
                item.selected = false;
            });
            setSelectedCategory([]);
            onCategorySelection([]);
            setSelectedPublishers([]);
            onPublishersSelection([]);
            setItems(items);
            props.setClearSelectionState?.(FilterStateEnum.RESET);
            props.setSelectionMap?.(
                (item: Map<string | undefined, string>) => new Map(item.set(props.title, FilterStateEnum.UNSELECTED)),
            );
        }

        if (props.clearSelectionState == undefined || props.clearSelectionState == FilterStateEnum.CLEAR) {
            window.sessionStorage.removeItem('minPrice');
            window.sessionStorage.removeItem('maxPrice');
            setMinCpmInitialValue('');
            setMaxCpmInitialValue('');
        }

        setfixedItems(props.fixedItemsValue);
    }, [props.items]);

    const minCPM = Number(minPrice);
    const maxCPM = Number(maxPrice);

    const isBothCPMValues = minCPM && maxCPM;
    const isCPMWarning = isBothCPMValues && minCPM > maxCPM ? true : false;

    if (isCPMWarning) {
        props.setClearSelectionState?.(FilterStateEnum.WARNING);
    }

    return (
        <Accordion open={props.open ?? true} title={props.title ?? ''} summaryItems={getSummaryItems()}>
            {props.title === AccordionFilterEnum.CPM ? (
                <>
                    <div className="cpmInput">
                        <span>Min</span>
                        <label className="min">{marketplaceContext.currencyCode}</label>
                        <Input
                            type="number"
                            min="0.00"
                            step="1.00"
                            prefix="$"
                            fluid={false}
                            variant={InputVariant.DEFAULT}
                            onChange={(e) => handleChange(e, 'min')}
                            value={cpmMinInitialValue}
                        />
                        <span>Max</span>
                        <label className="max">{marketplaceContext.currencyCode}</label>
                        <Input
                            type="number"
                            fluid={false}
                            variant={InputVariant.DEFAULT}
                            onChange={(e) => handleChange(e, 'max')}
                            value={cpmMaxInitialValue}
                        />
                    </div>
                    {isCPMWarning ? (
                        <>
                            <BannerMessage variant={BannerMessageVariant.WARNING}>
                                {Label.CPM_WARNING_TEXT}
                            </BannerMessage>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            ) : props.title === Label.CATEGORY_FILTER_TITLE ? (
                <Typeahead
                    variant={InputVariant.DEFAULT}
                    allowMultiple
                    resultNameAccessor="display"
                    uniqueIdAccessor="enumFormat"
                    onQueryChange={(query) => {
                        if (props.setSearchText) props.setSearchText(query);
                        props.searchString &&
                            props.categoryItems &&
                            setCategoryItems(
                                props.categoryItems.filter(
                                    (cat) => cat.display.toLowerCase().includes(query.toLowerCase()) || cat.selected,
                                ),
                            );
                    }}
                    results={categoryItems}
                    onSelectionChange={(selected: Array<IAccordionCheckListByStore>) => {
                        setSelectedCategory(selected);
                        onCategorySelection(selected);
                    }}
                    selected={selectedCategory}
                />
            ) : props.title === AccordionFilterEnum.PUBLISHERS ? (
                <Typeahead
                    variant={InputVariant.DEFAULT}
                    allowMultiple
                    resultNameAccessor="display"
                    uniqueIdAccessor="enumFormat"
                    onQueryChange={(query) => {
                        if (props.setSearchText) props.setSearchText(query);
                        props.searchString &&
                            props.publisherItems &&
                            setPublisherItems(
                                props.publisherItems.filter(
                                    (pub) => pub.display.toLowerCase().includes(query.toLowerCase()) || pub.selected,
                                ),
                            );
                    }}
                    results={publisherItems}
                    onSelectionChange={(selected: Array<IAccordionCheckListByStore>) => {
                        setSelectedPublishers(selected);
                        onPublishersSelection(selected);
                    }}
                    selected={selectedPublishers}
                />
            ) : (
                items.map((item, index) => {
                    return (
                        <Checkbox
                            key={index}
                            label={item.display}
                            checked={item.selected ?? false}
                            onChange={() => {
                                toggleSelect(item.enumFormat ?? item.display);
                            }}
                            disabled={checkFilterValue(item.display)}
                        />
                    );
                })
            )}
        </Accordion>
    );
};

AccordionCheckListByStore.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.exact(IAccordionCheckListByStorePropTypes).isRequired).isRequired,
    onItemSelection: PropTypes.func,
    searchString: PropTypes.string,
    setSearchText: PropTypes.func,
    fixedItemsValue: PropTypes.arrayOf(PropTypes.exact(IAccordionCheckListByStorePropTypes).isRequired).isRequired,
    publisherItems: PropTypes.arrayOf(PropTypes.exact(IAccordionCheckListByStorePropTypes).isRequired),
    categoryItems: PropTypes.arrayOf(PropTypes.exact(IAccordionCheckListByStorePropTypes).isRequired),
};

export default AccordionCheckListByStore;
