import React, { useRef } from 'react';
import { DataTable, IFetchDataParams } from '@amobee/component-library';
import { pageSize20 } from '../../constants/ProductsPagination';
import { ProductSortEnum } from '../../constants/ProductSortEnum';
import PropTypes from 'prop-types';
import { pageCount } from '../../constants/OrdersPagination';
import { IColumns } from '../../interfaces/IColumns';
import { NetworkStatus, OperationVariables, QueryResult } from '@apollo/client';
import { IProductsQuery } from '../../interfaces/queries/IProductsQuery';
import { IDeal, IDealDataRef } from '../../interfaces/IDeal';
import { Label } from '../../constants/LabelEnum';
import { ColumnHeaderFieldClickedEnum } from '../../constants/ColumnHeadersEnum';
import { IRowSelection } from '../../interfaces/IRowSelection';

const DealsDatatable: React.FunctionComponent<{
    otherDealsResponse: QueryResult<IProductsQuery, OperationVariables>;
    displayColumns: IColumns[];
    sortOrder: React.MutableRefObject<string>;
    sortBy: React.MutableRefObject<string>;
    isLoadingDeals: boolean;
    dealDataRef: IDealDataRef;
    pageIndex: React.MutableRefObject<number>;
    setSendAfter(sendAfter: boolean): void;
    setEndCursor?(endCursor: string): void;
    setCurrentPage(currentPage: number): void;
    onRowSelectionChange(event: IRowSelection): void;
}> = (props) => {
    const totalRowsRef = useRef<number>(0);
    const pageCountRef = useRef<number>(pageCount);
    const pageIndex = props.pageIndex;
    const dealDataRef = props.dealDataRef;
    const sortBy = props.sortBy;
    const sortOrder = props.sortOrder;

    const otherDealsData = props.otherDealsResponse?.data;
    const refetchOtherDeals = props.otherDealsResponse?.refetch;
    const networkStatus = props.otherDealsResponse?.networkStatus;

    const netStatusRef = useRef<NetworkStatus | null>(null);

    if (networkStatus !== NetworkStatus.ready) {
        netStatusRef.current = networkStatus;
    }

    if (otherDealsData && networkStatus === NetworkStatus.ready) {
        if (netStatusRef.current !== NetworkStatus.ready) {
            netStatusRef.current = networkStatus;
            const result = otherDealsData?.productsByStore?.edges?.map((otherDeal: IDeal) => otherDeal.node);

            result.forEach((object) => {
                const inViewRate = object.inViewRate;
                const formattedRate = Math.round(inViewRate * 100) + '%';
                object.formattedInViewRate = formattedRate;
            });

            pageCountRef.current = Math.ceil(otherDealsData?.productsByStore?.totalCount / pageSize20);
            totalRowsRef.current = otherDealsData?.productsByStore?.totalCount;

            if (result && pageIndex.current === 1) {
                dealDataRef.current = [...result];
            } else {
                if (result) {
                    dealDataRef.current = dealDataRef.current?.concat(result);
                }
            }
        }
    }

    const fetchActionOnTable = (params: IFetchDataParams) => {
        const fieldClicked = sortByField(params);
        const newSortOrder =
            params.sortBy && params.sortBy[0] && params.sortBy[0].desc
                ? ProductSortEnum.DESCENDING
                : ProductSortEnum.ASCENDING;

        const isSortIconClicked = checkIfSortIconIsClicked(fieldClicked, sortBy.current);
        const isPageIndexZero = params.pageIndex === 0;
        const isSortOrderChanged = sortOrder.current !== newSortOrder;

        if (isPageIndexZero && fieldClicked && (isSortIconClicked || isSortOrderChanged)) {
            sortOrder.current = newSortOrder;
            switch (fieldClicked) {
                case ColumnHeaderFieldClickedEnum.DEAL_NAME:
                    sortBy.current = ProductSortEnum.NAME;
                    break;
                case ColumnHeaderFieldClickedEnum.DEAL_ID:
                    sortBy.current = ProductSortEnum.EXTERNAL_ID;
                    break;
                case ColumnHeaderFieldClickedEnum.FORMAT:
                    sortBy.current = ProductSortEnum.FORMAT;
                    break;
                case ColumnHeaderFieldClickedEnum.DEAL_TYPE:
                    sortBy.current = ProductSortEnum.DEAL_TYPE;
                    break;
                case ColumnHeaderFieldClickedEnum.ENVIRONMENT:
                    sortBy.current = ProductSortEnum.ENVIRONMENT;
                    break;
                case ColumnHeaderFieldClickedEnum.PUBLISHER:
                    sortBy.current = ProductSortEnum.PUBLISHER;
                    break;
                case ColumnHeaderFieldClickedEnum.MINIMUM_CPM:
                    sortBy.current = ProductSortEnum.PRICE;
                    break;
                case ColumnHeaderFieldClickedEnum.IN_VIEW_RATE:
                    sortBy.current = ProductSortEnum.IN_VIEW_RATE;
                    break;
                case ColumnHeaderFieldClickedEnum.INVENTORY_SOURCE:
                    sortBy.current = ProductSortEnum.INVENTORY_SOURCE;
                    break;
                case ColumnHeaderFieldClickedEnum.BID_RQUESTS:
                    sortBy.current = ProductSortEnum.BID_REQUEST_VOLUME;
                    break;
                case ColumnHeaderFieldClickedEnum.AVAILABILITY_END_DATE:
                    sortBy.current = ProductSortEnum.AVAILABILITY_END_DATE;
                    break;
                case ColumnHeaderFieldClickedEnum.IAB_CATEGORY:
                    sortBy.current = ProductSortEnum.IAB_CATEGORY_ENUM;
                    break;
                case ColumnHeaderFieldClickedEnum.TAGS:
                    sortBy.current = ProductSortEnum.TAGS;
                    break;
                default:
                    break;
            }
            if (sortBy.current) {
                pageIndex.current = 1;
                refetchOtherDeals({ sortBy: sortBy.current, sortOrder: sortOrder.current });
            }
        } else if (params.pageIndex != 0 && checkIfPageIndexChanged(params.pageIndex + 1, pageIndex.current)) {
            pageIndex.current = params.pageIndex + 1;
            props.setSendAfter(true);
            props.setEndCursor && props.setEndCursor(window.btoa((pageSize20 * (pageIndex.current - 1)).toString()));
            props.setCurrentPage(pageIndex.current);
            refetchOtherDeals({ sortBy: sortBy.current, sortOrder: sortOrder.current });
        }
    };

    const sortByField = (params: IFetchDataParams): string => {
        const sortByString = params?.sortBy?.map((elem) => {
            if (elem.desc) return elem.id;
            else return elem.id;
        });
        if (sortByString) {
            return sortByString[0];
        }
        return '';
    };

    const checkIfSortIconIsClicked = (fieldClicked: string, current: string): boolean => {
        return fieldClicked.toLowerCase() === current.toLowerCase() ? false : true;
    };

    const checkIfPageIndexChanged = (pageindexDataTable: number, pageIndexQuery: number): boolean => {
        return pageindexDataTable === pageIndexQuery ? false : true;
    };

    return (
        <DataTable
            columns={props.displayColumns}
            data={dealDataRef.current?.length > 0 ? dealDataRef?.current : []}
            uniqueIdAccessor="id"
            totalRows={totalRowsRef.current}
            pageCount={pageCountRef.current}
            pageSize={pageSize20}
            isPaginationEnabled={true}
            onFetchData={(params) => fetchActionOnTable(params)}
            isSelectionEnabled={true}
            defaultSort={{
                desc: true,
                id: 'name',
            }}
            onRowSelectionChange={($event) => props.onRowSelectionChange($event as unknown as IRowSelection)}
            callToActionProps={{
                description: Label.NO_DATA_FOUND_MSG,
                title: Label.NO_DEALS,
                type: Label.TYPE_EMPTY_TABLE,
            }}
            showCallToAction={true}
            disableSortRemove={true}
            loading={props.isLoadingDeals}
        />
    );
};

DealsDatatable.propTypes = {
    displayColumns: PropTypes.any,
    sortOrder: PropTypes.any,
    sortBy: PropTypes.any,
    isLoadingDeals: PropTypes.bool.isRequired,
    dealDataRef: PropTypes.shape({ current: PropTypes.array.isRequired }).isRequired,
    pageIndex: PropTypes.any,
    setSendAfter: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    setEndCursor: PropTypes.func,
    onRowSelectionChange: PropTypes.func.isRequired,
};

export default DealsDatatable;
