export type ISortType = {
    key: string;
    value: string;
    label?: string;
};

export enum ProductSortEnum {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
    UPDATED_DATE = 'UPDATED_DATE',
    NAME = 'NAME',
    PRICE = 'PRICE',
    BID_REQUEST_VOLUME = 'BID_REQUEST_VOLUME',
    IN_VIEW_RATE = 'IN_VIEW_RATE',
    EXTERNAL_ID = 'EXTERNAL_ID',
    FORMAT = 'FORMAT',
    PUBLISHER = 'PUBLISHER',
    DEAL_TYPE = 'DEAL_TYPE',
    ENVIRONMENT = 'ENVIRONMENT',
    INVENTORY_SOURCE = 'INVENTORY_SOURCE',
    AVAILABILITY_END_DATE = 'AVAILABILITY_END_DATE',
    TAGS = 'TAGS',
    IAB_CATEGORY_ENUM = 'IAB_CATEGORY_ENUM',
}

export enum SortOptionLabelEnum {
    NEWEST = 'Newest',
    NAME_A_TO_Z = 'Name A to Z',
    NAME_Z_TO_A = 'Name Z to A',
    MIN_CPM_HIGH_TO_LOW = 'Minimum CPM High to Low',
    MIN_CPM_LOW_TO_HIGH = 'Minimum CPM Low to High',
    BID_REQ_VOL_HIGH_TO_LOW = 'Bid request volume High to Low',
    BID_REQ_VOL_LOW_TO_HIGH = 'Bid request volume Low to High',
    IN_VIEW_RATE_HIGH_TO_LOW = 'In-view Rate High to Low',
    IN_VIEW_RATE_LOW_TO_HIGH = 'In-view Rate Low to High',
}
