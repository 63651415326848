import { IEnumType } from '../interfaces/IEnumType';
import { IAccordionCheckListByStore } from '../components/common/interfaces/IAccordionCheckListByStore';
import { AmobeeDealTypeEnum } from './ProductFilterEnums';
export class AmobeeDealType {
    public static readonly UNRESERVED_FIXED: IEnumType = { id: 0, name: 'Unreserved Fixed' };
    public static readonly PRIVATE_AUTION: IEnumType = { id: 1, name: 'Private Auction' };
    public static readonly PROGRAMATTIC_GUARANTEED: IEnumType = { id: 2, name: 'Programmatic Guaranteed' };
}

export interface IDealEnumType extends IEnumType {
    mappedAmobeeValue?: IEnumType;
}

export class SmartclipDealTypes {
    public static readonly DIRECT_DEAL: IDealEnumType = {
        id: 1,
        name: 'Direct Deal',
        mappedAmobeeValue: AmobeeDealType.UNRESERVED_FIXED,
    };
    public static readonly DIRECT_GUARANTEED_DEAL: IDealEnumType = {
        id: 2,
        name: 'Direct Guaranteed Deal',
        mappedAmobeeValue: AmobeeDealType.UNRESERVED_FIXED,
    };
    public static readonly SECOND_PRICE_PRIVATE_AUCTION: IDealEnumType = {
        id: 3,
        name: '2nd Price Private Auction',
        mappedAmobeeValue: AmobeeDealType.PRIVATE_AUTION,
    };
    public static readonly FIRST_PRICE_PRIVATE_AUCTION: IDealEnumType = {
        id: 4,
        name: '1st Price Private Auction',
        mappedAmobeeValue: AmobeeDealType.PRIVATE_AUTION,
    };
    public static readonly SECOND_PRICE_PUBLIC_AUCTION: IDealEnumType = {
        id: 5,
        name: '2nd Price Public Auction',
        mappedAmobeeValue: AmobeeDealType.PRIVATE_AUTION,
    };
    public static readonly FIRST_PRICE_PUBLIC_AUCTION: IDealEnumType = {
        id: 6,
        name: '1st Price Public Auction',
        mappedAmobeeValue: AmobeeDealType.PRIVATE_AUTION,
    };
    public static readonly PROGRAMMATIC_GUARANTEED_DEAL: IDealEnumType = {
        id: 7,
        name: 'Programmatic Guaranteed Deal',
        mappedAmobeeValue: AmobeeDealType.PROGRAMATTIC_GUARANTEED,
    };
}
export class AmobeeDealTypes {
    public static readonly UNRESERVED_FIXED: IAccordionCheckListByStore = {
        display: AmobeeDealTypeEnum.UNRESERVED_FIXED,
        enumFormat: 'UNRESERVED_FIXED',
        selected: false,
    };
    public static readonly PRIVATE_AUCTION: IAccordionCheckListByStore = {
        display: AmobeeDealTypeEnum.PRIVATE_AUCTION,
        enumFormat: 'PRIVATE_AUCTION',
        selected: false,
    };
    public static readonly PROGRAMMATIC_GUARENTEED: IAccordionCheckListByStore = {
        display: AmobeeDealTypeEnum.PROGRAMMATIC_GUARENTEED,
        enumFormat: 'PROGRAMMATIC_GUARENTEED',
        selected: false,
    };
}
