import React from 'react';

export const UniversalLogout: React.FunctionComponent = () => {
    localStorage.removeItem('accessToken');
    // Notify parent iframe that this has successfully loaded
    const SUCCESSFULLY_LOADED = '1';
    const sourceURL = document.referrer;
    if (sourceURL && window.parent) {
        window.parent.postMessage(SUCCESSFULLY_LOADED, sourceURL);
    } else {
        console.error('Something was missing... Source URL:', sourceURL, 'window.parent:', window.parent);
    }
    return <div>Logged out</div>;
};
