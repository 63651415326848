import React from 'react';
import { Icon } from '@amobee/component-library';
import { useCSVDownloader } from 'react-papaparse';
import PropTypes from 'prop-types';
import { DealDetailsColumnEnum } from '../../constants/DealDetailsColumnEnum';
import { IGetPublisherDeals } from '../../interfaces/IPublisherDealsResponse';
import { IDealLists } from '../../interfaces/IDealLists';
import { IDealListObj } from '../../interfaces/IExportCSV';
import { Label } from '../../constants/LabelEnum';

const ExportCSVDealListsFlyout: React.FunctionComponent<{
    publisherDealsData?: IGetPublisherDeals[];
    selectedDealList?: IDealLists;
}> = (props) => {
    const { CSVDownloader, Type } = useCSVDownloader();

    const publisherDealsData = props.publisherDealsData;
    const selectedDealList = props.selectedDealList;

    const exportCSV = () => {
        const csvDealsDetails: IDealListObj[] = [];

        const dealDetailsColumns = Object.values(DealDetailsColumnEnum);

        publisherDealsData?.forEach((edge: IGetPublisherDeals) => {
            const dealDetailsObj: IDealListObj = {};
            dealDetailsColumns.forEach((key: DealDetailsColumnEnum) => {
                switch (key) {
                    case DealDetailsColumnEnum.NAME:
                        dealDetailsObj[key] = edge.dealName;
                        break;
                    case DealDetailsColumnEnum.STATUS:
                        dealDetailsObj[key] = edge.workflowStatus;
                        break;
                    case DealDetailsColumnEnum.DEAL_ID:
                        dealDetailsObj[key] = edge.dealId;
                        break;
                    case DealDetailsColumnEnum.FLOOR_CPM:
                        dealDetailsObj[key] = edge.price;
                        break;
                    case DealDetailsColumnEnum.BID_REQUEST_VOLUME:
                        dealDetailsObj[key] = edge.bidRequestVolume ?? 0;
                        break;
                    case DealDetailsColumnEnum.SSP:
                        dealDetailsObj[key] = edge.inventorySourceId;
                        break;
                    case DealDetailsColumnEnum.PUBLISHER:
                        dealDetailsObj[key] = edge.publisherName;
                        break;
                    case DealDetailsColumnEnum.DEAL_TYPE:
                        dealDetailsObj[key] = edge.dealType;
                        break;
                    case DealDetailsColumnEnum.FORMAT:
                        dealDetailsObj[key] = edge.mediaFormat;
                        break;
                    case DealDetailsColumnEnum.AD_SIZE:
                        dealDetailsObj[key] = edge.adSize ?? 0;
                        break;
                }
            });
            csvDealsDetails.push(dealDetailsObj);
        });

        return csvDealsDetails;
    };

    return (
        <CSVDownloader
            type={Type.Button}
            filename={`DealDetails_${selectedDealList?.dealListName}`}
            bom={true}
            data={() => exportCSV()}
            className="export-icon"
        >
            <Icon name="Export" size={16} /> {Label.EXPORT_CSV}
        </CSVDownloader>
    );
};

ExportCSVDealListsFlyout.propTypes = {
    publisherDealsData: PropTypes.array,
    selectedDealList: PropTypes.shape({
        id: PropTypes.number.isRequired,
        dealListId: PropTypes.number.isRequired,
        dealListName: PropTypes.string.isRequired,
        cardUrl: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        dealIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        isFeatured: PropTypes.bool.isRequired,
    }),
};

export default ExportCSVDealListsFlyout;
